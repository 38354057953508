import styled from 'styled-components'

export const PolicieContainer = styled.div`
    .form > div:not(:last-child) {
        margin-bottom: 1.5rem;
    }
    .form {
        &--grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1.5rem;
        }
        &--grid-3 {
            display: grid;
            grid-template-columns: 3fr 1fr;
            grid-gap: 1.5rem;
        }
    }
    .title {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        .add-new-policy {
            border: none;
            background-color: transparent;
            font-size: 1.5rem;
            color: var(--color-primary);
            padding: 0;
            margin: 0;
            cursor: pointer;
        }
    }
`

export const Accordion = styled.div`
    .accordion-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1rem;
        border: none;
        border-bottom: 1px solid #ccc;
        background-color: #fff;
    }
    .hide-content {
        visibility: hidden;
        height: 0px;
    }
    .show-content {
        padding: 0 1rem;
        visibility: visible;
        height: 100%;
        transition: all ease 0.5s;
    }
`
