/* eslint-disable prettier/prettier */
import { GeneralReport } from '../../interfaces/Reports'

export const generalReportColumns: GeneralReport = {
    recordLocator: 'Localizador', // 6 DIGTS RLOC - LOCALIZADOR *
    status: 'Status', // CONFIRMED/CANCELED - STATUS *
    tripHumanNumber: 'Número da viagem', // NOT YET
    itemHumanNumber: 'Número do item', // NOT YET
    traveler: 'Viajante', // PAX NAME - VIAJANTE *
    creatorId: 'Solicitante', // creatorId - SOLICITANTE
    lastStageApprover: 'Aprovador', // approver last stage - APROVADOR
    insidePolicy: 'Dentro da política', // SIM/NAO - DENTRO DA POLÍTICA
    productType: 'Produto', // AÉREO/HOTEL/CARRO - PRODUTO *
    // isNational: 'Nacional', // SIM NAO // NACIONAL
    location: 'Destino', // cidade/estado/pais - DESTINO *
    supplier: 'Fornecedor', // NOME CIA ou nome hotel ou nome locadora - FORNECEDOR *
    // additionals: 'Adicionais', // SIM NAO - ADICIONAIS
    fareAmount: 'Tarifa', // R$ 200 - TARIFA
    taxAmount: 'Taxa', // R$ 20 - TAXA
    additionalsAmount: 'Valores adicionais', // R$ 20 - VALOR ADICIONAIS
    totalAmount: 'Total', // R$ 240 - TOTAL
    // cheapestPriceFareOffered: 'Menor tarifa oferecida', // NOT YET
    paymentMethod: 'Forma de pagamento', // credit card | invoice ... - FORMA DE PAGAMENTO
    cardIdentifier: 'Identificador do pagamento', // MC + LAST FOUR 1111 - IDENTIFICADOR PGTO
    daysBeforeRequest: 'Antecedência', // ANTECEDÊNCIA
    createdAt: 'Data da criação', // DATA CRIAÇÃO
    issuedAt: 'Data da emissão', // DATA EMISSÃO *
    startDate: 'Data de início', // INÍCIO
    endDate: 'Data de término', // TÉRMINO
    observations: 'Observações', // OBSERVAÇÃO
}

export const flightReportColumns = {
    traveler: 'Viajante', // PAX NAME - VIAJANTE *
    creatorId: 'Solicitante', // creatorId - SOLICITANTE
    lastStageApprover: 'Aprovador', // approver last stage - APROVADOR
    status: 'Status', // CONFIRMED/CANCELED - STATUS
    insidePolicy: 'Dentro da política', // SIM/NAO - DENTRO DA POLÍTICA
    recordLocator: 'Localizador', // localizador *
    ticketNumber: 'Bilhete', // bilhete *
    seller: 'Vendedor', // companhia que vendeu *
    fareAmount: 'Valor da tarifa', // fareAmount
    taxAmount: 'Taxa', // taxAmount
    travelFeeAmount: 'Taxa de viagem', // travelFeeAmount
    appliedDiscount: 'Desconto aplicado', // appliedDiscount
    totalAmount: 'Total', // totalAmount
    duration: 'Duração', // duration
    journeys: 'Jornadas', // sao/nvt/lala *
    carriers: 'Transportadoras', // AD/LA/LA/AD
    flightNumbers: 'Número dos voos', // 1022/1032/2023
    departureAirport: 'Aeroporto de partida', // departureAirport
    departureCity: 'Cidade de partida', // departureCity
    departureDatetime: 'Data e hora de partida', // departureDatetime
    arrivalAirport: 'Aeroporto de chegada', // arrivalAirport
    arrivalCity: 'Cidade de chegada', // arrivalCity
    arrivalDatetime: 'Data e hora de chegada', // arrivalDatetime
    fareBasis: 'Base da tarifa', // fareBasis
    brandedFare: 'Tarifa de marca', // brandedFare
    // ancillaries: 'Serviços adicionais', // ancillaries
    ancillariesAmount: 'Valor dos serviços adicionais', // ancillariesAmount
    paymentMethod: 'Forma de pagamento', // paymentMethod
    cardIdentifier: 'Identificador do pagamento', // MC + LAST FOUR 1111 - IDENTIFICADOR PGTO
    daysBeforeRequest: 'Dias antes da solicitação', // daysBeforeRequest
    createdAt: 'Data da criação', // createdAt
    issuedAt: 'Data da emissão', // data emissão *
    startDate: 'Data de início da viagem', // data início viagem *
    endDate: 'Data de término', // endDate
}

export const hotelReportColumns = {
    traveler: 'Viajante', // PAX NAME - VIAJANTE *
    creatorId: 'Solicitante', // creatorId - SOLICITANTE
    lastStageApprover: 'Aprovador', // approver last stage - APROVADOR
    status: 'Status', // CONFIRMED/CANCELED - STATUS
    insidePolicy: 'Dentro da política', // SIM/NAO - DENTRO DA POLÍTICA
    managementInfo1Option: 'Projeto', // OPTION DE PROJETO - PROJETO
    managementInfoNOption: 'Campo gerencial', // OPTION DE Campo gerencial - CENTRO DE CUSTO
    paymentMethod: 'Forma de pagamento', // credit card | invoice ... - FORMA DE PAGAMENTO
    cardIdentifier: 'Identificador do pagamento', // MC + LAST FOUR 1111 - IDENTIFICADOR PGTO
    recordLocator: 'Localizador', // localizador *
    label: 'Etiqueta', // label
    description: 'Descrição', // description
    country: 'País', // País
    state: 'Estado', // estado *
    city: 'Cidade', // cidade *
    roomLabel: 'Etiqueta do quarto', // roomLabel
    // roomHasBreakfast: 'Quarto com café da manhã', // roomHasBreakfast
    roomDescription: 'Descrição do quarto', // roomDescription
    fareAmount: 'Valor da tarifa', // fareAmount
    taxAmount: 'Taxa', // taxAmount
    totalAmount: 'Total', // totalAmount
    lastDayToCancelForFree: 'Último dia para cancelamento gratuito', // lastDayToCancelForFree
    checkinDatetime: 'Data e hora de check-in', // data check-in *
    checkoutDatetime: 'Data e hora de check-out', // data check-out *
    daysBeforeRequest: 'Dias antes da solicitação', // daysBeforeRequest
    createdAt: 'Data da criação', // createdAt
    issuedAt: 'Data de emissão', // emissao*
    startDate: 'Data de início', // data início
    endDate: 'Data de término', // endDate
}

export const carReportColumns = {
    traveler: 'Viajante', // PAX NAME - VIAJANTE *
    creatorId: 'Solicitante', // creatorId - SOLICITANTE
    lastStageApprover: 'Aprovador', // approver last stage - APROVADOR
    status: 'Status', // CONFIRMED/CANCELED - STATUS
    insidePolicy: 'Dentro da política', // SIM/NAO - DENTRO DA POLÍTICA
    managementInfo1Option: 'Projeto', // OPTION DE PROJETO - PROJETO
    managementInfoNOption: 'Campo gerencial', // OPTION DE Campo gerencial - CENTRO DE CUSTO
    paymentMethod: 'Forma de pagamento', // credit card | invoice ... - FORMA DE PAGAMENTO
    cardIdentifier: 'Identificador do pagamento', // MC + LAST FOUR 1111 - IDENTIFICADOR PGTO
    recordLocator: 'Localizador', // localizador *
    pickupDateTime: 'Data e hora de retirada', // pickupDateTime *
    pickupLocation: 'Local de retirada', // cidade/estado/pais - DESTINO *
    returnDateTime: 'Data e hora de devolução', // returnDateTime *
    returnLocation: 'Local de devolução', // returnLocation
    carRentalLabel: 'Etiqueta do aluguel de carro', // carRentalLabel
    categoryLabel: 'Etiqueta da categoria', // categoryLabel
    supplier: 'Fornecedor', // supplier
    fareAmount: 'Valor da tarifa', // fareAmount
    taxAmount: 'Taxa', // taxAmount
    // additionals: 'Adicionais', // ar/gps
    additionalsAmount: 'Valor dos adicionais', // ar/gps
    totalAmount: 'Total', // totalAmount
    // coverages: 'Coberturas', // coverages
    coveragesAmount: 'Valor das coberturas', // coveragesAmount
    daysBeforeRequest: 'Dias antes da solicitação', // daysBeforeRequest
    createdAt: 'Data da criação', // createdAt
    issuedAt: 'Data de emissão', // issuedAt *
    startDate: 'Data de início', // startDate
    endDate: 'Data de término', // endDate
}
