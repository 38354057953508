import styled from 'styled-components'

export const HomeContainer = styled.div`
    width: 100%;
    .tabs-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5rem 0;
    }
    .container {
        margin: 5rem auto 0 auto;
        max-width: 110rem;
        .grid-chart-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: stretch;
            grid-gap: 2rem;
        }
    }
    margin-bottom: 6rem;
    .section-title {
        margin-top: 3rem;
    }
`

export const InformationContainer = styled.div`
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    padding: 3rem;
    margin-top: 2.5rem;
    .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
    }
    .title {
        display: flex;
        align-items: center;
        svg {
            width: 2rem;
            height: 2rem;
            margin-right: 2rem;
        }
        margin-bottom: 5rem;
    }
    .main-indicators {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        .main-routes {
            .main-title {
                margin-bottom: 3rem;
            }
        }
        .main-companies {
            .main-title {
                margin-bottom: 3rem;
            }
        }
    }
    .divider {
        height: 2px;
        width: 100%;
        background-color: #dedede;
        margin: 2.7rem 0;
    }
`

export const ChartContainer = styled.div`
    .expenses-max-height {
        max-height: 40rem;
    }
    margin: 3rem auto 0 auto;
    background-color: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    padding: 3rem;
    width: 100%;

    .title {
        display: flex;
        align-items: center;
        svg {
            width: 2rem;
            height: 2rem;
            margin-right: 2rem;
        }
        margin-bottom: 5rem;
        .shield-img {
            margin-right: 2rem;
        }
    }
    .general-view {
        display: grid;
        grid-template-columns: 50rem 1fr;
        grid-gap: 7rem;
        height: 30rem;
        .bar-chart {
            height: 100%;
            width: 100%;
        }
        .indicators {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 3rem;
        }
    }
    .charts {
        margin-top: 3rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 3rem;
    }

    .trip-metrics {
        .trips-indicators {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 5rem;
        }
        .expenses-indicators {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 5rem;
            margin-bottom: 3rem;
        }
    }
    .fown-chart {
        margin-top: 7.7rem;
        display: flex;
        align-items: center;
        .main-indicators {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-left: 3rem;
            grid-gap: 3rem;
            .main-routes {
                .main-title {
                    margin-bottom: 3rem;
                }
            }
            .main-companies {
                .main-title {
                    margin-bottom: 3rem;
                }
            }
        }
    }
    .expenses-flow-chart {
        margin-top: 4rem;
        display: flex;
        align-items: flex-start;
        .main-indicators {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-left: 3rem;
            grid-gap: 3rem;
            .main-routes {
                .main-title {
                    margin-bottom: 3rem;
                }
            }
            .main-companies {
                .main-title {
                    margin-bottom: 3rem;
                }
            }
        }
    }
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        .doughnuts-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 4rem;
        }
        .left-side {
            .policy-title {
                margin: 3.7rem 0 5rem 0;
            }
        }
        .right-side {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }
    }
    .indicators {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3rem;
    }
`
