import React from 'react'
import { LabelValueContainer } from './styled'

interface Props {
    size: 'NORMAL' | 'LARGE'
    title: string
    value: string
    description?: string
    color?: 'DARK' | 'RED'
}

export function LabelValue({
    size,
    title,
    value,
    description,
    color = 'DARK',
}: Props) {
    return (
        <LabelValueContainer>
            <h2 className="f16-500-gray">{title}</h2>
            <h1
                className={`f${
                    size === 'LARGE' ? '28' : '24'
                }-700-dark margin ${color}`}
            >
                {value}
            </h1>
            {description && <p className="f16-500-gray">{description}</p>}
        </LabelValueContainer>
    )
}
