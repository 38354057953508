import styled from 'styled-components'

export const SingleReportDataContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 2fr 2fr 3fr 2fr 3fr;
    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        padding: 2rem 0;
        &:not(:last-child) {
            border-right: 2px solid #c8cacd;
        }
    }
    .center {
        text-align: center;
    }
    .start {
        text-align: left;
    }
    &:nth-child(2n + 2) {
        background: #fff;
    }
`
