/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { MdEdit } from 'react-icons/md'
import { FaTrash } from 'react-icons/fa'
import { v4 } from 'uuid'
import { PaymentMethodsAndLoyaltiesContainer, PaymentOrLoyalty } from './styles'
import { Button } from '../../../components/Button'
import { User } from '../../../entities/user'
import { usePayment } from '../../../hooks/use-payment'
import { getImage } from '../../../utils/imagesHandler/imagesHandler'
import { chooseImagePaymentMethod } from '../../../utils/paymentFunctions'

interface Props {
    member: User
}

export function PaymentMethodsAndLoyalties({ member }: Props) {
    // hooks
    const { getPaymentMethods, paymentMethods } = usePayment()
    // state
    const [addNewPaymentMethod, setAddNewPaymentMethod] = useState(false)
    const [addNewLoyalty, setAddNewLoyalty] = useState(false)
    const [busy, setBusy] = useState(false)

    function closeAddNewPaymentMethod() {
        setAddNewPaymentMethod(false)
    }

    function closeAddNewLoyalty() {
        setAddNewLoyalty(false)
    }

    // function chooseImageLoyalty(type?: string) {
    //     if (type === 'LatamPass') {
    //         return LatamPass
    //     }
    //     if (type === 'Smiles') {
    //         return Smiles
    //     }
    //     return TudoAzul
    // }

    useEffect(() => {
        ;(async () => {
            await getPaymentMethods()
        })()
    }, [])

    return (
        <PaymentMethodsAndLoyaltiesContainer>
            <div className="payment-methods">
                <div className="payment-methods-header">
                    <div className="payment-methods-header--title">
                        <h1 className="f18-700-dark">
                            Formas de pagamento cadastradas
                        </h1>
                        <p className="f16-500-gray">
                            Formas de pagamento que você pode usar em suas
                            viagens.
                        </p>
                    </div>
                </div>
                <div className="payment-methods-form">
                    {paymentMethods.map((paymentMethod) => {
                        return (
                            <PaymentOrLoyalty key={v4()}>
                                <div className="payment-loyalty--logo">
                                    <img
                                        src={chooseImagePaymentMethod(
                                            paymentMethod.creditCard?.cardBrand
                                        )}
                                        alt=""
                                    />
                                </div>
                                <div className="payment-loyalty--label">
                                    <h1 className="f16-700-dark">{`${paymentMethod.label} . final ${paymentMethod.creditCard?.lastFourDigits}`}</h1>
                                </div>
                                <div className="payment-loyalty--description">
                                    <p className="f16-500-gray">
                                        {paymentMethod.companyInfo
                                            ? 'Cartão corporativo'
                                            : 'Cartão pessoal'}
                                    </p>
                                </div>
                                <div className="payment-loyalty--edit">
                                    <button className="edit-btn" type="button">
                                        <MdEdit />
                                        <h3 className="f16-500-gray">Editar</h3>
                                    </button>
                                </div>
                                <div className="payment-loyalty--remove">
                                    <button
                                        className="remove-btn"
                                        type="button"
                                        onClick={async () => {
                                            // await deletePaymentMethod(
                                            //     paymentMethod.id
                                            // )
                                        }}
                                    >
                                        <FaTrash />
                                        <h3 className="f16-500-gray">
                                            Remover
                                        </h3>
                                    </button>
                                </div>
                            </PaymentOrLoyalty>
                        )
                    })}
                    <div className="payment-methods-form--add-btn">
                        <Button
                            text="Adicionar forma de pagamento"
                            icon={AiOutlinePlusCircle}
                            color="PRIMARY"
                            buttonType="TEXT"
                            onClick={() => setAddNewPaymentMethod(true)}
                        />
                    </div>
                </div>
            </div>
            <div className="payment-methods">
                <div className="payment-methods-header">
                    <div className="payment-methods-header--title">
                        <h1 className="f18-700-dark">Planos de fidelidade</h1>
                        <p className="f16-500-gray">
                            Acumule pontos em suas viagens corporativas e
                            particulares.
                        </p>
                    </div>
                    <div className="blank" />
                </div>
                <div className="payment-methods-form">
                    {member.customerLoyalty &&
                        member.customerLoyalty.map((loyalty) => {
                            return (
                                <PaymentOrLoyalty key={v4()}>
                                    <div className="payment-loyalty--logo">
                                        <img
                                            src={getImage(loyalty.label)}
                                            alt=""
                                        />
                                    </div>
                                    <div className="payment-loyalty--label--center">
                                        <h1 className="f16-700-dark">
                                            {loyalty.iataCode}
                                        </h1>
                                    </div>
                                    <div className="payment-loyalty--description">
                                        <p className="f16-500-gray">
                                            {loyalty.membershipId}
                                        </p>
                                    </div>
                                    <div className="payment-loyalty--edit">
                                        <button
                                            className="edit-btn"
                                            type="button"
                                        >
                                            <MdEdit />
                                            <h3 className="f16-500-gray">
                                                Editar
                                            </h3>
                                        </button>
                                    </div>
                                    <div className="payment-loyalty--remove">
                                        <button
                                            className="remove-btn"
                                            type="button"
                                            // onClick={async () => {
                                            //     await deleteLoyalty(index)
                                            // }}
                                        >
                                            <FaTrash />
                                            <h3 className="f16-500-gray">
                                                Remover
                                            </h3>
                                        </button>
                                    </div>
                                </PaymentOrLoyalty>
                            )
                        })}
                    <div className="payment-methods-form--add-btn">
                        <Button
                            text="Adicionar um plano fidelidade"
                            icon={AiOutlinePlusCircle}
                            color="PRIMARY"
                            buttonType="TEXT"
                            onClick={() => setAddNewLoyalty(true)}
                        />
                    </div>
                </div>
            </div>
        </PaymentMethodsAndLoyaltiesContainer>
    )
}

{
    /* <CreatePaymentMethod
                isOpen={addNewPaymentMethod}
                onRequestClose={closeAddNewPaymentMethod}
            />
            <AddLoyalty
                isOpen={addNewLoyalty}
                onRequestClose={closeAddNewLoyalty}
            /> */
}
