import g3 from '../../assets/providers/g3.png'
import la from '../../assets/providers/la.png'
import ad from '../../assets/providers/ad.png'
import latamPass from '../../assets/providers/latam-pass.png'
import smiles from '../../assets/providers/smiles.png'
import tudoAzul from '../../assets/providers/tudo-azul.png'

export function getImage(label: string) {
    switch (label) {
        case 'G3':
            return g3
        case 'LA':
            return la
        case 'AD':
            return ad
        case 'LATAM_PASS':
            return latamPass
        case 'Smiles':
            return smiles
        case 'Tudo Azul':
            return tudoAzul
        default:
            break
    }
    return ''
}
