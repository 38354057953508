/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import ModalContainer from 'react-modal'
import { IoMdSearch } from 'react-icons/io'
import { BsFillXCircleFill, BsFillPlusCircleFill } from 'react-icons/bs'
import { v4 } from 'uuid'
import { toast } from 'react-toastify'
import { MdList } from 'react-icons/md'
import { AddTravalersInTripContainer, Traveler } from './styles'
import { Avatar } from '../../components/Avatar'
import { SearchInput } from '../../components/SearchInput'
import { Button } from '../../components/Button'
import { User } from '../../entities/user'
import { useCompany } from '../../hooks/use-company'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    type?: 'DEFAULT' | 'MY_APPROVERS' | 'TRAVELERS_LIST'
    usersAdded: {
        id: string
        label: string
        url?: string | undefined
    }[]
}

export function ListMembers({
    isOpen,
    onRequestClose,
    usersAdded,
    type = 'DEFAULT',
}: Props) {
    const [searchTraveler, setSearchTraveler] = useState('')

    function labels() {
        if (type === 'MY_APPROVERS') {
            return ['Pesquisar por usuários', 'APROVADORES', 'Meus aprovadores']
        }
        if (type === 'TRAVELERS_LIST') {
            return [
                'Pesquisar por usuários',
                'LISTA DE VIAJANTES',
                'Lista de viajantes',
            ]
        }
        return [
            'Adicionar passageiros',
            'VIAJANTES ADICIONADOS',
            'Passageiros adicionados',
        ]
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button
                className="close"
                type="button"
                onClick={() => onRequestClose()}
            >
                X
            </button>
            <AddTravalersInTripContainer>
                <div className="modal-header">
                    <div className="modal-header-title">
                        <MdList />
                        <h1 className="f22-700-dark">{labels()[2]}</h1>
                    </div>
                </div>
                <div className="modal-content">
                    <SearchInput
                        label="Pesquisar"
                        name="search-traveler"
                        icon={IoMdSearch}
                        value={searchTraveler}
                        onChange={(e) => setSearchTraveler(e.target.value)}
                    />
                    {!searchTraveler ? (
                        <>
                            <div className="added-travelers">
                                <h1 className="f14-700-secondary-gray added-travelers--title">
                                    {labels()[1]}
                                </h1>
                                {usersAdded.map((element) => {
                                    return (
                                        <Traveler key={v4()}>
                                            <div className="traveler-info">
                                                <div className="traveler-info--logo">
                                                    <Avatar
                                                        label={element.label}
                                                        img={
                                                            element.url
                                                                ? element.url
                                                                : undefined
                                                        }
                                                    />
                                                </div>
                                                <div className="traveler-info--name">
                                                    <p className="f16-500-gray">
                                                        {element.label}
                                                    </p>
                                                </div>
                                            </div>
                                        </Traveler>
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <div className="add-travalers">
                            <h1 className="f14-700-secondary-gray add-travelers--title">
                                RESULTADOS
                            </h1>
                            {usersAdded.map((element) => {
                                if (
                                    element.label
                                        .toLowerCase()
                                        .includes(searchTraveler.toLowerCase())
                                ) {
                                    return (
                                        <Traveler key={v4()}>
                                            <div className="traveler-info">
                                                <div className="traveler-info--logo">
                                                    <Avatar
                                                        label={element.label}
                                                        img={
                                                            element.url
                                                                ? element.url
                                                                : undefined
                                                        }
                                                    />
                                                </div>
                                                <div className="traveler-info--name">
                                                    <p className="f16-500-gray">
                                                        {element.label}
                                                    </p>
                                                </div>
                                            </div>
                                        </Traveler>
                                    )
                                }
                                return null
                            })}
                        </div>
                    )}
                </div>
                {type === 'DEFAULT' && (
                    <div className="save-btn">
                        <Button
                            buttonType="FILLED"
                            text="Salvar"
                            color="PRIMARY"
                            type="button"
                            // onClick={() => addedListHandler()}
                        />
                    </div>
                )}
            </AddTravalersInTripContainer>
        </ModalContainer>
    )
}
