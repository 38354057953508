import styled from 'styled-components'

export const DoughnutChartContainer = styled.div`
    height: 9rem;
    display: flex;
    align-items: center;
    .chart {
        height: 10rem;
        width: 10rem;
        position: relative;
        .label {
            height: 5rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -40%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            svg {
                width: 2rem;
                height: 2rem;
                color: #000;
            }
        }
    }
    .info {
        margin-left: 1.5rem;
        .label {
            margin-bottom: 1rem;
        }
    }
`
