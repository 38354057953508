import styled from 'styled-components'

export const LabelValueContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .RED {
        color: #db614e;
    }
    .margin {
        margin: 0.8rem 0;
    }
`
