import { AdvanceMoneyEvaluation } from '../../interfaces/advanced-money'
import { ExpenseEvaluation } from '../../interfaces/expenses'

export function expenseOutOfPolicyHandler(
    travelerClearanceEvaluation: ExpenseEvaluation | undefined
): { isOutOfPolicy: boolean; messages: string[] } {
    const messages: string[] = []
    if (travelerClearanceEvaluation === undefined)
        return { isOutOfPolicy: false, messages }
    if (
        travelerClearanceEvaluation.expense.length === 0 &&
        travelerClearanceEvaluation.expenseCategory.length === 0
    ) {
        return { isOutOfPolicy: false, messages }
    }

    if (travelerClearanceEvaluation.expense.length > 0) {
        travelerClearanceEvaluation.expense.forEach((item) => {
            messages.push(item.message)
        })
    }
    if (travelerClearanceEvaluation.expenseCategory.length > 0) {
        travelerClearanceEvaluation.expenseCategory.forEach((item) => {
            messages.push(item.message)
        })
    }

    return { isOutOfPolicy: true, messages }
}

export function advanceMoneyOutOfPolicyHandler(
    travelerClearanceEvaluation?: AdvanceMoneyEvaluation
) {
    const messages: string[] = []

    if (travelerClearanceEvaluation === undefined)
        return { isOutOfPolicy: false, messages }
    if (travelerClearanceEvaluation.advanceMoney.length === 0) {
        return { isOutOfPolicy: false, messages }
    }

    if (travelerClearanceEvaluation.advanceMoney.length > 0) {
        travelerClearanceEvaluation.advanceMoney.forEach((item) => {
            messages.push(item.message)
        })
    }
    return { isOutOfPolicy: true, messages }
}
