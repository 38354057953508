/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import { FiDollarSign } from 'react-icons/fi'
import { MdContacts } from 'react-icons/md'
import { v4 } from 'uuid'
import { FaListAlt, FaRegListAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { InnerHeader } from '../../../../components/InnerHeader'
import { SimpleInput } from '../../../../components/SimpleInput'
import { TitleDescription } from '../../../../components/TitleDescription'
import { useCompany } from '../../../../hooks/use-company'
import { SinglePolicieContainer } from '../../../Politics/styles'
import { Contact, ContactInfoContainer } from './styles'
import { GeneralSubPageContent } from '../styles'

export function ExpenseInfo() {
    // hooks
    const { company, editPricePerKilometer } = useCompany()
    const [expenseMoney, setExpenseMoney] = useState('')

    useEffect(() => {
        setExpenseMoney(
            company?.pricePerKilometer
                ? company?.pricePerKilometer.toFixed(2)
                : ''
        )
    }, [])

    return (
        <GeneralSubPageContent>
            <ContactInfoContainer>
                <InnerHeader
                    btnLabel="Salvar"
                    title="Despesas"
                    callback={() => {
                        if (!Number(expenseMoney)) {
                            toast.warning('O valor inserido está inválido')
                        }
                        if (Number(expenseMoney) < 0) {
                            toast.warning('O valor inserido está inválido')
                        } else {
                            editPricePerKilometer(Number(expenseMoney))
                        }
                    }}
                    icon={FiDollarSign}
                />
                <Contact>
                    <div className="content">
                        <SinglePolicieContainer>
                            <TitleDescription
                                title="Valor reembolso quilometragem"
                                description="Defina o valor à ser reembolsado por quilometro quando uma despesa de quilometragem for aprovada."
                            />
                            <div className="inputs">
                                <SimpleInput
                                    label=""
                                    type="number"
                                    name="expenseMoney"
                                    id="123"
                                    isPricing
                                    value={expenseMoney}
                                    onChange={(e) =>
                                        setExpenseMoney(e.target.value)
                                    }
                                />
                            </div>
                        </SinglePolicieContainer>
                    </div>
                </Contact>
            </ContactInfoContainer>
        </GeneralSubPageContent>
    )
}
