/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'
import { toast } from 'react-toastify'
import { EditMemberDto } from '../../../dtos/edit-member'
import { User } from '../../../entities/user'
import { SystemClearance } from '../../../enums/system-clearance.enum'
import { useAuth } from '../../../hooks/use-auth'
import { useCompany } from '../../../hooks/use-company'
import { CompanyInfo } from '../../../interfaces/company-info'
import { api } from '../../../service/api'

interface Props {
    children: ReactNode
    id: string
}

interface EditMemberContextProps {
    busy: boolean

    travelerClearance: string
    travelerClearanceOptions: {
        label: string
        value: string
    }[]

    systemClearance: string
    systemClearanceOptions: {
        label: string
        value: string
    }[]

    membersManagementInfo: {
        id: string
        label: string
        options: {
            id: string
            label: string
        }[]
    }[]

    approvesForManagementInfo: {
        id: string
        label: string
        options: {
            id: string
            label: string
        }[]
    }[]

    requestsForManagementInfo: {
        id: string
        label: string
        options: {
            id: string
            label: string
        }[]
    }[]

    approvers: {
        level: number
        users: {
            id: string
            label: string
            url?: string
        }[]
    }[]

    approvesFor: {
        id: string
        label: string
        url?: string
    }[]

    approvalLevel?: number
    approvalLevelOptions: {
        label: string
        value: number
    }[]

    requestsFor: {
        id: string
        label: string
        url?: string
    }[]
    isMemberActive?: boolean
    isMaster?: boolean

    startEditing: boolean
    editMemberHandler(): Promise<any>
    travelerClearanceChanger(s: string): void
    systemClearanceChanger(s: string): void
    membersManagementInfoChanger(
        element: {
            id: string
            label: string
            options: {
                id: string
                label: string
            }[]
        },
        type: 'ADD' | 'REMOVE',
        action: 'MEMBER' | 'APPROVES_FOR' | 'REQUESTS_FOR'
    ): any
    memberOptionChanger(
        managementId: string,
        option: {
            id: string
            label: string
        },
        type: 'ADD' | 'REMOVE',
        action: 'MEMBER' | 'APPROVES_FOR' | 'REQUESTS_FOR'
    ): void
    membersToAddChanger(
        element: {
            id: string
            label: string
            url?: string
        },
        type: 'ADD' | 'REMOVE',
        action: 'APPROVES_FOR' | 'REQUESTS_FOR'
    ): void
    changeApprovalLevel(lvl: number): void
    travelerIsActiveChanger(bool: boolean): void
    changeIsMaster(): void
    notifyUser(userId: string): Promise<any>
}

const EditMemberContenxt = createContext({} as EditMemberContextProps)

export const EditMemberProvider = ({ children, id }: Props) => {
    const { token } = useAuth()
    const { editMember } = useCompany()
    const { company, member, getMember, companyManagementInfos } = useCompany()

    const [busy, setBusy] = useState(false)
    const [startEditing, setStartEditing] = useState(false)

    // admin can edit travelerClearance
    const [travelerClearance, setTravelerClearance] = useState<string>(
        member?.companyInfo[0].travelerClearanceId || 'none'
    )
    const [isMemberActive, setIsMemberActive] = useState<boolean | undefined>(
        member?.companyInfo[0].userIsActive
    )
    const travelerClearanceOptions: {
        label: string
        value: string
    }[] = company!.travelerClearances.map((t) => {
        return { value: t.id, label: t.label }
    })

    function travelerClearanceChanger(s: string) {
        setTravelerClearance(s)
        if (!startEditing) setStartEditing(true)
    }

    function travelerIsActiveChanger(bool: boolean) {
        setIsMemberActive(bool)
        if (!startEditing) setStartEditing(true)
    }

    // admin can edit systemClearance
    const [systemClearance, setSystemClearance] = useState<string>(
        member?.companyInfo[0].systemClearance || 'a'
    )
    const systemClearanceOptions: {
        label: string
        value: string
    }[] = [
        { label: 'Administrador', value: 'ADMIN' },
        { label: 'Viajante', value: 'TRAVELER' },
    ]

    function systemClearanceChanger(s: string) {
        setSystemClearance(s)
        if (!startEditing) setStartEditing(true)
    }

    // admin can edit managementInfos
    const [membersManagementInfo, setMembersManagementInfo] = useState<
        {
            id: string
            label: string
            options: {
                id: string
                label: string
            }[]
        }[]
    >([])

    // admin can list approvers
    const [approvers, setApprovers] = useState<
        {
            level: number
            users: {
                id: string
                label: string
                url?: string
            }[]
        }[]
    >([])

    async function getMemberApprovers(memberId: string) {
        try {
            const response = await api.get<any[]>(
                `companies/${company!.id}/members/${memberId}/approvers`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            setApprovers(
                response.data.map((d) => {
                    return {
                        level: d.level,
                        users: d.users.map((u: any) => {
                            return {
                                id: u.iu,
                                label: `${u.firstName} ${u.lastName}`,
                                url: u.photo?.url,
                            }
                        }),
                    }
                })
            )
            return response.data
        } catch (error) {
            return undefined
        }
    }

    // admin can edit approves for
    const [approvesFor, setApprovesFor] = useState<
        {
            id: string
            label: string
            url?: string
        }[]
    >([])

    const [approvesForManagementInfo, setApprovesForManagementInfo] = useState<
        {
            id: string
            label: string
            options: {
                id: string
                label: string
            }[]
        }[]
    >([])

    const [requestsForManagementInfo, setRequestsForManagementInfo] = useState<
        {
            id: string
            label: string
            options: {
                id: string
                label: string
            }[]
        }[]
    >([])

    function approvesForMembers(
        approvalsForData: {
            id: string
            label: string
            url?: string | undefined
        }[],
        memberData: User
    ): {
        id: string
        label: string
        url?: string | undefined
    }[] {
        const userApprovesFor =
            memberData?.companyInfo[0].permissions.approvesFor.usersIds
        return approvalsForData.filter((r) => userApprovesFor!.includes(r.id))
    }

    async function getMemberApprovesFor(memberData: User) {
        try {
            const response = await api.get<User[]>(
                `companies/${company!.id}/members/${
                    memberData.id
                }/approves-for`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )

            const approvesForUserIds = approvesForMembers(
                response.data.map((d) => {
                    return {
                        id: d.id,
                        label: `${d.firstName} ${d.lastName}`,
                        url: d.photo?.url,
                    }
                }),
                memberData
            )

            setApprovesFor(approvesForUserIds)
            return response.data
        } catch (error) {
            return undefined
        }
    }

    // admin can edit approval level
    const [approvalLevel, setApprovalLevel] = useState<number>()
    const approvalLevelOptions: {
        label: string
        value: number
    }[] = new Array(company?.maxApprovalLevel || 1).fill(1).map((_, i) => {
        return {
            label: `Nivel ${i + 1}`,
            value: i + 1,
        }
    })

    function changeApprovalLevel(lvl: number) {
        setApprovalLevel(lvl)
        if (!startEditing) setStartEditing(true)
    }

    // admin can edit Master
    const [isMaster, setIsMaster] = useState<boolean>()

    function changeIsMaster() {
        setIsMaster((prev) => !prev)
        if (!startEditing) setStartEditing(true)
    }

    // admin can edit requests for
    const [requestsFor, setRequestsFor] = useState<
        {
            id: string
            label: string
            url?: string
        }[]
    >([])

    function requestsForMembers(
        requestsForData: {
            id: string
            label: string
            url?: string | undefined
        }[],
        memberData: User
    ): {
        id: string
        label: string
        url?: string | undefined
    }[] {
        const userRequestsFor =
            memberData.companyInfo[0].permissions.requestsFor.usersIds
        return requestsForData.filter((r) => userRequestsFor!.includes(r.id))
    }

    async function getMemberRequestsFor(memberData: User) {
        try {
            const response = await api.get<User[]>(
                `companies/${company!.id}/members/${
                    memberData.id
                }/requests-for`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            const requestsForUserIds = requestsForMembers(
                response.data.map((d) => {
                    return {
                        id: d.id,
                        label: `${d.firstName} ${d.lastName}`,
                        url: d.photo?.url,
                    }
                }),
                memberData
            )
            setRequestsFor([...requestsForUserIds])
            return response.data
        } catch (error) {
            return undefined
        }
    }

    function membersToAddChanger(
        element: {
            id: string
            label: string
            url?: string
        },
        type: 'ADD' | 'REMOVE',
        action: 'APPROVES_FOR' | 'REQUESTS_FOR'
    ) {
        if (!startEditing) setStartEditing(true)
        if (type === 'ADD') {
            if (action === 'APPROVES_FOR') {
                setApprovesFor((state) => [...state, element])
            }
            if (action === 'REQUESTS_FOR') {
                setRequestsFor((state) => [...state, element])
            }
        }
        if (type === 'REMOVE') {
            if (action === 'APPROVES_FOR') {
                setApprovesFor((prevState) => {
                    const state = prevState.filter(
                        (prev) => prev.id !== element.id
                    )
                    return state
                })
            }
            if (action === 'REQUESTS_FOR') {
                setRequestsFor((prevState) => {
                    const state = prevState.filter(
                        (prev) => prev.id !== element.id
                    )
                    return state
                })
            }
        }
    }

    function membersManagementInfoChanger(
        element: {
            id: string
            label: string
            options: {
                id: string
                label: string
            }[]
        },
        type: 'ADD' | 'REMOVE',
        action: 'MEMBER' | 'APPROVES_FOR' | 'REQUESTS_FOR'
    ) {
        if (!startEditing) setStartEditing(true)
        if (type === 'ADD') {
            if (action === 'MEMBER') {
                setMembersManagementInfo((prevState) => [...prevState, element])
            }
            if (action === 'APPROVES_FOR') {
                setApprovesForManagementInfo((prevState) => [
                    ...prevState,
                    element,
                ])
            }
            if (action === 'REQUESTS_FOR') {
                setRequestsForManagementInfo((prevState) => [
                    ...prevState,
                    element,
                ])
            }
        }
        if (type === 'REMOVE') {
            if (action === 'MEMBER') {
                setMembersManagementInfo((prevState) => {
                    const state = prevState.filter(
                        (prev) => prev.id !== element.id
                    )
                    return state
                })
            }
            if (action === 'APPROVES_FOR') {
                setApprovesForManagementInfo((prevState) => {
                    const state = prevState.filter(
                        (prev) => prev.id !== element.id
                    )
                    return state
                })
            }
            if (action === 'REQUESTS_FOR') {
                setRequestsForManagementInfo((prevState) => {
                    const state = prevState.filter(
                        (prev) => prev.id !== element.id
                    )
                    return state
                })
            }
        }
    }

    function memberOptionChanger(
        managementId: string,
        option: {
            id: string
            label: string
        },
        type: 'ADD' | 'REMOVE',
        action: 'MEMBER' | 'APPROVES_FOR' | 'REQUESTS_FOR'
    ) {
        if (!startEditing) setStartEditing(true)
        if (type === 'ADD') {
            if (action === 'MEMBER') {
                setMembersManagementInfo((prevState) => {
                    const index = prevState.findIndex(
                        (p) => p.id === managementId
                    )
                    if (index !== undefined && index !== -1) {
                        prevState[index].options = [
                            ...prevState[index].options,
                            option,
                        ]
                    } else {
                        prevState.push({
                            id: managementId,
                            label:
                                companyManagementInfos.find(
                                    (mId) => mId.id === managementId
                                )?.label || '',
                            options: [option],
                        })
                    }
                    return [...prevState]
                })
            }
            if (action === 'APPROVES_FOR') {
                setApprovesForManagementInfo((prevState) => {
                    const index = prevState.findIndex(
                        (p) => p.id === managementId
                    )
                    if (index !== undefined && index !== -1) {
                        prevState[index].options = [
                            ...prevState[index].options,
                            option,
                        ]
                    } else {
                        prevState.push({
                            id: managementId,
                            label:
                                companyManagementInfos.find(
                                    (mId) => mId.id === managementId
                                )?.label || '',
                            options: [option],
                        })
                    }
                    return [...prevState]
                })
            }
            if (action === 'REQUESTS_FOR') {
                setRequestsForManagementInfo((prevState) => {
                    const index = prevState.findIndex(
                        (p) => p.id === managementId
                    )
                    if (index !== undefined && index !== -1) {
                        prevState[index].options = [
                            ...prevState[index].options,
                            option,
                        ]
                    } else {
                        prevState.push({
                            id: managementId,
                            label:
                                companyManagementInfos.find(
                                    (mId) => mId.id === managementId
                                )?.label || '',
                            options: [option],
                        })
                    }
                    return [...prevState]
                })
            }
        }
        if (type === 'REMOVE') {
            if (action === 'MEMBER') {
                setMembersManagementInfo((prevState) => {
                    const index = prevState.findIndex(
                        (p) => p.id === managementId
                    )
                    if (index !== undefined && index !== -1) {
                        prevState[index].options = prevState[
                            index
                        ].options.filter((prev) => prev.id !== option.id)
                    }
                    return [...prevState]
                })
            }
            if (action === 'APPROVES_FOR') {
                setApprovesForManagementInfo((prevState) => {
                    const index = prevState.findIndex(
                        (p) => p.id === managementId
                    )
                    if (index !== undefined) {
                        prevState[index].options = prevState[
                            index
                        ].options.filter((prev) => prev.id !== option.id)
                    }
                    return [...prevState]
                })
            }
            if (action === 'REQUESTS_FOR') {
                setRequestsForManagementInfo((prevState) => {
                    const index = prevState.findIndex(
                        (p) => p.id === managementId
                    )
                    if (index !== undefined) {
                        prevState[index].options = prevState[
                            index
                        ].options.filter((prev) => prev.id !== option.id)
                    }
                    return [...prevState]
                })
            }
        }
    }

    function returnFullMemberManagementInfo(
        memberData: CompanyInfo['managementInfo'],
        type: 'MEMBER' | 'APPROVES_FOR' | 'REQUESTS_FOR'
    ) {
        if (company) {
            const data: {
                id: string
                label: string
                options: {
                    id: string
                    label: string
                }[]
            }[] = []
            if (memberData) {
                memberData.forEach((management) => {
                    data.push({
                        id:
                            company.managementInfo.find(
                                (m) => m.id === management.id
                            )?.id || '',
                        label:
                            company.managementInfo.find(
                                (m) => m.id === management.id
                            )?.label || '',
                        options: management.options.map((opt) => {
                            return {
                                id:
                                    company.managementInfo
                                        .find((m) => m.id === management.id)
                                        ?.options.find((o) => o.id === opt)
                                        ?.id || '',
                                label:
                                    company.managementInfo
                                        .find((m) => m.id === management.id)
                                        ?.options.find((o) => o.id === opt)
                                        ?.label || '',
                            }
                        }),
                    })
                    if (type === 'MEMBER') {
                        setMembersManagementInfo([...data])
                    }
                    if (type === 'APPROVES_FOR') {
                        setApprovesForManagementInfo([...data])
                    }
                    if (type === 'REQUESTS_FOR') {
                        setRequestsForManagementInfo([...data])
                    }
                })
            }
        }
    }

    useEffect(() => {
        ;(async () => {
            setBusy(true)
            const response = await getMember(id)
            if (response) {
                returnFullMemberManagementInfo(
                    response.companyInfo[0].managementInfo,
                    'MEMBER'
                )
                returnFullMemberManagementInfo(
                    response.companyInfo[0].permissions.approvesFor
                        .managementInfo as unknown as CompanyInfo['managementInfo'],
                    'APPROVES_FOR'
                )
                returnFullMemberManagementInfo(
                    response.companyInfo[0].permissions.requestsFor
                        .managementInfo as unknown as CompanyInfo['managementInfo'],
                    'REQUESTS_FOR'
                )
                setTravelerClearance(
                    response.companyInfo[0].travelerClearanceId
                )
                setSystemClearance(response.companyInfo[0].systemClearance)
                setApprovalLevel(
                    response.companyInfo[0].permissions.approvesFor.level
                )
                setIsMaster(
                    response.companyInfo[0].permissions.approvesFor.isMaster ||
                        false
                )
                setIsMemberActive(response.companyInfo[0].userIsActive || false)
            }
            await getMemberApprovers(id)
            await getMemberApprovesFor(response!)
            await getMemberRequestsFor(response!)
            setBusy(false)
        })()
    }, [])

    async function editMemberHandler() {
        let editMemberData: EditMemberDto = {
            managementInfo: membersManagementInfo.map((management) => {
                return {
                    id: management.id,
                    options: management.options.map((opt) => opt.id),
                }
            }),
            userIsActive: isMemberActive || false,
            systemClearance: systemClearance as SystemClearance,
            travelerClearanceId: travelerClearance,
            permissions: {
                approvesFor: {
                    level: approvalLevel || 0,
                    isMaster,
                    usersIds: approvesFor.map((approve) => approve.id),
                    managementInfo: approvesForManagementInfo.map(
                        (management) => {
                            return {
                                id: management.id,
                                options: management.options.map(
                                    (opt) => opt.id
                                ),
                            }
                        }
                    ),
                },
                requestsFor: {
                    managementInfo: requestsForManagementInfo.map(
                        (management) => {
                            return {
                                id: management.id,
                                options: management.options.map(
                                    (opt) => opt.id
                                ),
                            }
                        }
                    ),
                    usersIds: requestsFor.map((request) => request.id),
                },
            },
        }
        editMemberData = {
            ...editMemberData,
            managementInfo: editMemberData.managementInfo?.filter(
                (management) => management.options.length !== 0
            ),
        }
        const response = await editMember(editMemberData)
        return response
    }

    async function notifyUser(userId: string) {
        try {
            await api.post(
                `/users/${userId}/push-notification`,
                {},
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )

            return true
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return false
        }
    }

    return (
        <EditMemberContenxt.Provider
            value={{
                busy,
                membersManagementInfo,
                requestsFor,
                approvesFor,
                approvers,
                approvalLevel,
                approvalLevelOptions,
                systemClearance,
                systemClearanceOptions,
                travelerClearanceOptions,
                travelerClearance,
                isMaster,
                editMemberHandler,
                systemClearanceChanger,
                travelerClearanceChanger,
                membersManagementInfoChanger,
                memberOptionChanger,
                changeApprovalLevel,
                changeIsMaster,
                isMemberActive,
                travelerIsActiveChanger,
                approvesForManagementInfo,
                requestsForManagementInfo,
                membersToAddChanger,
                startEditing,
                notifyUser,
            }}
        >
            {children}
        </EditMemberContenxt.Provider>
    )
}

export const useEditMember = (): EditMemberContextProps => {
    const context = useContext(EditMemberContenxt)
    return context
}
