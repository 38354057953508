import styled from 'styled-components'

export const AdvanceMoneyContainer = styled.div`
    .title {
        margin-bottom: 5rem;
    }
    .tabs {
        display: flex;
        border-bottom: 1px solid #c8cacd;
        margin-bottom: 5rem;
    }
    .filters {
        display: flex;
        align-items: center;
        gap: 2rem;
        margin-bottom: 2rem;
        .normal-label {
            margin-bottom: 1rem;
        }
    }
    .grid {
        display: grid;
        gap: 1.2rem;
        margin-bottom: 3rem;
    }
    .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`
