/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react'
import { FiDollarSign } from 'react-icons/fi'
import {
    MdApartment,
    MdContacts,
    MdCorporateFare,
    MdGavel,
} from 'react-icons/md'
import { SideMenu } from '../../../components/SideMenu'
import { ApprovalsInfo } from './ApprovalsInfo '
import { CompanyInfo } from './CompanyInfo'
import { ContactInfo } from './ContactInfo'
import { ExpenseInfo } from './ExpenseInfo'
import { OfficeInfo } from './OfficeInfo'
import { GeneralSubPageContainer } from './styles'
import { ExpenseCategory } from './ExpenseCategory'

export function GeneralSubPage() {
    // state
    const [selectedSideMenu, setSelectedSideMenu] = useState('COMPANY_INFO')

    function changeSubMenu(type: string) {
        setSelectedSideMenu(type)
    }

    return (
        <GeneralSubPageContainer>
            <SideMenu
                changeTab={changeSubMenu}
                title="Configurações Gerais"
                value={selectedSideMenu}
                sideMenus={[
                    {
                        id: 'COMPANY_INFO',
                        label: 'Informações da empresa',
                        icon: MdApartment,
                    },
                    {
                        id: 'OFFICE',
                        label: 'Escritórios',
                        icon: MdCorporateFare,
                    },
                    {
                        id: 'CONTACT',
                        label: 'Contatos',
                        icon: MdContacts,
                    },
                    {
                        id: 'EXPENSE',
                        label: 'Despesas',
                        icon: FiDollarSign,
                    },
                    {
                        id: 'APPROVALS',
                        label: 'Aprovações',
                        icon: MdGavel,
                    },
                ]}
            />
            {selectedSideMenu === 'COMPANY_INFO' && <CompanyInfo />}
            {selectedSideMenu === 'OFFICE' && <OfficeInfo />}
            {selectedSideMenu === 'CONTACT' && <ContactInfo />}
            {selectedSideMenu === 'EXPENSE' && (
                <div>
                    <ExpenseInfo />
                    <ExpenseCategory />
                </div>
            )}
            {selectedSideMenu === 'APPROVALS' && <ApprovalsInfo />}
        </GeneralSubPageContainer>
    )
}
