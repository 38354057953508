/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import ModalContainer from 'react-modal'
import { ExpensesModalContainer } from './styles'
import { AdvanceMoney } from '../../interfaces/advanced-money'
import { priceFormatter } from '../../utils/expensnes/priceFormatter'
import { ExpenseHistoryAction } from '../../enums/expense-history.enum'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    advanceMoney: AdvanceMoney
}

export default function UseHistoryModal({
    isOpen,
    onRequestClose,
    advanceMoney,
}: Props) {
    function historyItems(): {
        label: string
        value: string
    }[] {
        const data: { label: string; value: string }[] = []
        advanceMoney.expenseHistory.forEach((item) => {
            data.push({
                label: `#${item.expense.numberIdentifier}`,
                value: `- R$${priceFormatter(item.amount)}`,
            })
        })
        advanceMoney.history.forEach((item) => {
            if (item.action === ExpenseHistoryAction.RETURNED) {
                data.push({
                    label: 'Retornado',
                    value: `- R$${priceFormatter(item.amount)}`,
                })
            }
        })
        return data
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-history"
        >
            <ExpensesModalContainer>
                <div className="content-block">
                    <p className="f16-500-gray italic">Histórico de uso</p>
                    <div className=" section">
                        <div className="label-value">
                            <p className="f16-500-gray">{`Valor inicial: `}</p>
                            <p className="f16-700-dark">{`R$${priceFormatter(
                                advanceMoney.amount
                            )}`}</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="label-value">
                            <p className="f16-500-gray">
                                Identificador da despesa
                            </p>
                            <p className="f16-500-gray">Valor utilizado</p>
                        </div>
                        {historyItems().length === 0 && (
                            <div className="label-value">
                                <p className="f16-500-gray">
                                    Adiantamento não foi utilizado em nenhuma
                                    despesa
                                </p>
                            </div>
                        )}
                        {historyItems().map((item, index) => {
                            return (
                                <div className="label-value" key={index}>
                                    <p className="f16-500-gray">
                                        {`${item.label}`}
                                    </p>
                                    <p className="f16-700-dark">{item.value}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className=" section">
                        <div className="label-value">
                            <p className="f16-500-gray">
                                {`Saldo residual do adiantamento: `}
                            </p>
                            <p className="f16-700-dark">{`R$${priceFormatter(
                                advanceMoney.currentAmount
                            )}`}</p>
                        </div>
                    </div>
                </div>
            </ExpensesModalContainer>
        </ModalContainer>
    )
}
