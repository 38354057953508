import React, { useState } from 'react'
import { Button } from '../../components/Button'
import { SelectInput } from '../../components/SelectInput'
import { useCompany } from '../../hooks/use-company'
import { OptionsToAdd } from '../OptionsToAdd'
import { useEditMember } from './hook/useEditMember'
import { ControlButton } from '../../components/ControlButton'

export function CorporateInfos() {
    const [openAddManagement, setOpenAddManagement] = useState<
        string | undefined
    >()

    const {
        travelerClearance,
        travelerClearanceChanger,
        travelerClearanceOptions,
        membersManagementInfo,
        travelerIsActiveChanger,
        isMemberActive,
    } = useEditMember()

    const { member, companyManagementInfos } = useCompany()

    function returnLinkedLabel(managementInfoId: string) {
        const fullManagementInfoInUser = membersManagementInfo.find(
            (mId) => mId.id === managementInfoId
        )
        if (fullManagementInfoInUser) {
            if (fullManagementInfoInUser.options.length === 1) {
                return `${fullManagementInfoInUser.options.map(
                    (opt) => opt.label
                )}`
            }
            if (fullManagementInfoInUser.options.length === 2) {
                return `${fullManagementInfoInUser.options
                    .map((opt) => opt.label)
                    .join(', ')}`
            }
            if (fullManagementInfoInUser.options.length > 2) {
                return `${fullManagementInfoInUser.options[0].label}, ${
                    fullManagementInfoInUser.options[2].label
                }... +${fullManagementInfoInUser.options.length - 2}`
            }
        }
        return `Nenhum ${
            companyManagementInfos.find((mId) => mId.id === managementInfoId)
                ?.label
        }...`
    }

    return (
        <div className="corporative-infos">
            <div className="company-info-header">
                <div className="company-info-header--title">
                    <h1 className="f18-700-dark">
                        Informações corporativas de {member?.firstName}{' '}
                        {member?.lastName}
                    </h1>
                </div>
            </div>
            <div className="row" style={{ alignItems: 'center' }}>
                <h1>Usuário ativo?</h1>
                <div className="control-button">
                    <ControlButton
                        type="SWITCH"
                        id="isActive"
                        isPressed={isMemberActive || false}
                        callback={() => {
                            travelerIsActiveChanger(!isMemberActive)
                        }}
                    />
                </div>
            </div>
            <div className="row" style={{ alignItems: 'center' }}>
                <h1>Perfil de viajante</h1>
                <SelectInput
                    name="travelerClearance"
                    value={{
                        label: travelerClearanceOptions.find(
                            (a) => a.value === travelerClearance
                        )?.label,
                        value: travelerClearance,
                    }}
                    options={travelerClearanceOptions}
                    onChange={(e) => {
                        if (e) {
                            travelerClearanceChanger(e.value)
                        }
                    }}
                />
            </div>

            {companyManagementInfos.map((m) => {
                return (
                    <div className="management-info" key={m.id}>
                        <div className="row-button">
                            <div className="title-values">
                                <h1 className="reference-title">{m.label}</h1>
                                <h1 className="f16-500-gray">
                                    {returnLinkedLabel(m.id)}
                                </h1>
                            </div>
                            <Button
                                color="PRIMARY"
                                buttonType="TEXT"
                                text="Editar"
                                onClick={() => setOpenAddManagement(m.id)}
                            />
                        </div>
                    </div>
                )
            })}

            {openAddManagement && (
                <OptionsToAdd
                    isOpen={!!openAddManagement}
                    onRequestClose={() => setOpenAddManagement(undefined)}
                    managementId={openAddManagement}
                    addedOptions={
                        membersManagementInfo.find(
                            (m) => m.id === openAddManagement
                        )?.options
                    }
                    action="MEMBER"
                />
            )}
        </div>
    )
}
