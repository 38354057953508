import styled from 'styled-components'

export const ExpensesModalContainer = styled.div`
    max-height: 80vh;
    overflow-y: auto;
    width: 100%;
    .header {
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 3.6rem 2.1rem;
    }
    .btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        margin-top: 1rem;
        button {
            width: 15rem;
        }
    }
`
