/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { MdBarChart, MdDirectionsCar, MdFlight, MdHotel } from 'react-icons/md'
import { AiOutlineDollarCircle } from 'react-icons/ai'
import { Skeleton } from '@material-ui/lab'
import { FaDollarSign } from 'react-icons/fa'
import { GiMeal } from 'react-icons/gi'
import { BrokenPoliciesReason } from '../../components/BrokenPoliciesReason'
import { DoughnutChart } from '../../components/DoughnutChart'
import { FlownChart } from '../../components/FlownChart'
import { Header } from '../../components/Header'
import { LabelValue } from '../../components/LabelValue'
import { LostEconomies } from '../../components/LostEconomies'
import { OrderedCard } from '../../components/OrderedCard'
import { OutOfPolicyTable } from '../../components/OutOfPolicyTable'
import { ChartContainer, HomeContainer, InformationContainer } from './styles'
import shield from '../../assets/ShieldOff.png'
import { BarChart } from '../../components/BarChart'
import { DateFilter } from '../../components/DateFilter'
import { useDashboard } from '../../hooks/useDashboard'
import { ExpensesComplianceTable } from '../../components/ExpensesComplianceTable.ts'
import { ExpensesFlowChart } from '../../components/ExpensesFlowChart'
import { ExpensesBrokenPoliciesReason } from '../../components/ExpensesBrokenPoliciesReason'
import { PillTabs } from '../../components/PillTabs'

export function HomePage() {
    const {
        getDashboardData,
        tripMetrics,
        spendingMetrics,
        compliance,
        dateFilter,
        expensesCompliance,
        expensesMetrics,
    } = useDashboard()
    const [selectedTab, setSelectedTab] = useState('GENERAL')

    const tabs = [
        { label: 'Geral', id: 'GENERAL' },
        { label: 'Viagens', id: 'TRIPS' },
        { label: 'Despesas', id: 'EXPENSES' },
    ]

    function setBoards() {
        if (selectedTab === 'GENERAL') return ['spendingMetrics']
        if (selectedTab === 'TRIPS') return ['tripMetrics', 'compliance']
        if (selectedTab === 'EXPENSES')
            return ['expensesCompliance', 'expensesMetrics']
        return []
    }

    useEffect(() => {
        ;(async () => {
            await getDashboardData(setBoards())
        })()
    }, [dateFilter, selectedTab])

    function spendingPercentage(num: number) {
        if (spendingMetrics) {
            const {
                flightSpendings,
                hotelSpendings,
                carSpendings,
                foodSpendings,
                expenseSpendings: { amount },
            } = spendingMetrics
            const divider =
                (flightSpendings +
                    hotelSpendings +
                    carSpendings +
                    foodSpendings +
                    amount) /
                100
            return Math.round(num / divider)
        }
        return 0
    }

    function expensesComplianceAmount() {
        return (
            expensesCompliance!.response.policyBreachesAmount!
                .maxAmountPerDayBreach +
            expensesCompliance!.response.policyBreachesAmount
                .numberOfItemsBreach +
            expensesCompliance!.response.policyBreachesAmount
                .singleItemAmountBreach
        )
    }

    function expensesComplianceCount() {
        return (
            expensesCompliance!.response.policyBreachesCount!
                .maxAmountPerDayBreach +
            expensesCompliance!.response.policyBreachesCount
                .numberOfItemsBreach +
            expensesCompliance!.response.policyBreachesCount
                .singleItemAmountBreach
        )
    }

    return (
        <HomeContainer>
            <Header header="DASHBOARD" />
            <div className="tabs-container">
                <PillTabs
                    pillTabs={tabs}
                    value={selectedTab}
                    theme="LIGHT"
                    changeTab={(id: 'GENERAL' | 'TRIPS' | 'EXPENSES') =>
                        setSelectedTab(id)
                    }
                />
            </div>
            <div className="container">
                <div className="filter">
                    <DateFilter />
                </div>

                {selectedTab === 'GENERAL' && (
                    <>
                        {spendingMetrics ? (
                            <ChartContainer>
                                <div className="title">
                                    <MdBarChart />
                                    <h1 className="f22-700-dark">
                                        Visão Geral dos gastos
                                    </h1>
                                </div>
                                <div className="general-view">
                                    <div className="bar-chart">
                                        <BarChart
                                            tripSpendings={
                                                spendingMetrics.tripSpendings
                                                    .graphic
                                            }
                                            expenseSpendings={
                                                spendingMetrics.expenseSpendings
                                                    .graphic
                                            }
                                        />
                                    </div>
                                    <div className="indicators">
                                        <LabelValue
                                            size="LARGE"
                                            value={`R$ ${
                                                spendingMetrics?.tripSpendings.amount.toLocaleString(
                                                    'pt-br',
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                ) || 0
                                            }`}
                                            title="Gastos com viagens"
                                        />
                                        <LabelValue
                                            size="LARGE"
                                            value={`R$ ${
                                                spendingMetrics?.expenseSpendings.amount.toLocaleString(
                                                    'pt-br',
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                ) || 0
                                            }`}
                                            title="Gastos em despesas"
                                            color="DARK"
                                        />
                                        <LabelValue
                                            size="NORMAL"
                                            value={spendingMetrics?.numberOfTrips.toFixed(
                                                0
                                            )}
                                            title="Viagens"
                                        />
                                        <LabelValue
                                            size="NORMAL"
                                            value={spendingMetrics?.numberOfExpenses.toFixed(
                                                0
                                            )}
                                            title="Despesas"
                                            color="DARK"
                                        />
                                        <LabelValue
                                            size="NORMAL"
                                            value={spendingMetrics?.reservations.toFixed(
                                                0
                                            )}
                                            title="Reservas"
                                        />
                                    </div>
                                </div>
                                <div className="charts">
                                    <DoughnutChart
                                        icon={MdFlight}
                                        label="Gastos com voos"
                                        value={`R$ ${
                                            spendingMetrics?.flightSpendings.toLocaleString(
                                                'pt-br',
                                                {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            ) || 0
                                        }`}
                                        percentage={spendingPercentage(
                                            spendingMetrics.flightSpendings
                                        )}
                                    />
                                    <DoughnutChart
                                        icon={MdHotel}
                                        label="Gastos com hospedagem"
                                        value={`R$ ${
                                            spendingMetrics?.hotelSpendings.toLocaleString(
                                                'pt-br',
                                                {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            ) || 0
                                        }`}
                                        percentage={spendingPercentage(
                                            spendingMetrics.hotelSpendings
                                        )}
                                    />
                                    <DoughnutChart
                                        icon={MdDirectionsCar}
                                        label="Gastos com locações"
                                        value={`R$ ${
                                            spendingMetrics?.carSpendings.toLocaleString(
                                                'pt-br',
                                                {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            ) || 0
                                        }`}
                                        percentage={spendingPercentage(
                                            spendingMetrics.carSpendings
                                        )}
                                    />
                                    {/* <DoughnutChart
                                icon={GiMeal}
                                label="Gastos com alimentação"
                                value={`R$ ${
                                    spendingMetrics?.foodSpendings.toLocaleString(
                                        'pt-br',
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    ) || 0
                                }`}
                                percentage={spendingPercentage(
                                    spendingMetrics.foodSpendings
                                )}
                            />
                            <DoughnutChart
                                icon={AiOutlineDollarCircle}
                                label="Gastos com outras despesas"
                                value={`R$ ${
                                    spendingMetrics?.otherSpendings.toLocaleString(
                                        'pt-br',
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    ) || 0
                                }`}
                                percentage={spendingPercentage(
                                    spendingMetrics.otherSpendings
                                )}
                            /> */}
                                    <DoughnutChart
                                        icon={AiOutlineDollarCircle}
                                        label="Gastos com despesas"
                                        value={`R$ ${
                                            spendingMetrics?.expenseSpendings.amount.toLocaleString(
                                                'pt-br',
                                                {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            ) || 0
                                        }`}
                                        percentage={spendingPercentage(
                                            spendingMetrics?.expenseSpendings
                                                .amount
                                        )}
                                    />
                                </div>
                            </ChartContainer>
                        ) : (
                            <Skeleton width="100%" height="30rem" />
                        )}
                    </>
                )}

                {selectedTab === 'TRIPS' && (
                    <>
                        <div className="section-title">
                            <h1 className="f24-700-dark">
                                Métricas das viagens
                            </h1>
                        </div>
                        {tripMetrics ? (
                            <>
                                <ChartContainer>
                                    <div className="title">
                                        <MdFlight />
                                        <h1 className="f22-700-dark">
                                            Passagens Aéreas
                                        </h1>
                                    </div>
                                    <div className="trip-metrics">
                                        <div className="trips-indicators">
                                            <LabelValue
                                                size="LARGE"
                                                value={`R$ ${
                                                    tripMetrics?.flight.averagePrice.toLocaleString(
                                                        'pt-br',
                                                        {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) || 0
                                                }`}
                                                title="Preço médio"
                                                description="Preço médio pago em passagem aérea."
                                            />
                                            <LabelValue
                                                size="LARGE"
                                                value={`${tripMetrics.flight.averageDaysInAdvance.toFixed(
                                                    0
                                                )} dias`}
                                                title="Antecedência média"
                                                description="Qnt. de dias antes do voo em que as passagens são compradas."
                                            />
                                            <LabelValue
                                                size="LARGE"
                                                value={`${tripMetrics.flight.numberOfFlights.toFixed(
                                                    0
                                                )}`}
                                                title="Quantidade de voos"
                                                description="Nº total de voos emitidos no periodo."
                                            />
                                            {/* <LabelValue
                                        size="LARGE"
                                        value="9%??"
                                        title="Econômia obtida"
                                        description="Economia na compra de passagens aéreas."
                                    /> */}
                                        </div>
                                    </div>
                                    <div className="fown-chart">
                                        <FlownChart
                                            economic={
                                                tripMetrics.flight.categories
                                                    .basic
                                            }
                                            promotional={
                                                tripMetrics.flight.categories
                                                    .promotional
                                            }
                                            plus={
                                                tripMetrics.flight.categories
                                                    .plus
                                            }
                                            top={
                                                tripMetrics.flight.categories
                                                    .top
                                            }
                                        />
                                        <div className="main-indicators">
                                            <div className="main-companies">
                                                <div className="main-title">
                                                    <h1 className="f18-700-dark">
                                                        Principais companhias
                                                    </h1>
                                                    <p className="f14-500-gray">
                                                        Total gasto com a cia.
                                                    </p>
                                                </div>
                                                {tripMetrics.flight.topAirlines.map(
                                                    (airline, index) => {
                                                        if (index >= 5)
                                                            return null
                                                        return (
                                                            <OrderedCard
                                                                key={
                                                                    airline.label
                                                                }
                                                                index={
                                                                    index + 1
                                                                }
                                                                title={
                                                                    airline.label
                                                                }
                                                                subTitle={airline.spendings.toFixed(
                                                                    2
                                                                )}
                                                            />
                                                        )
                                                    }
                                                )}
                                            </div>

                                            <div className="main-routes">
                                                <div className="main-title">
                                                    <h1 className="f18-700-dark">
                                                        Principais rotas
                                                    </h1>
                                                    <p className="f14-500-gray">
                                                        Tarifa média na rota.
                                                    </p>
                                                </div>
                                                {tripMetrics.flight.topJourneys.map(
                                                    (journey, index) => {
                                                        if (index >= 5)
                                                            return null
                                                        return (
                                                            <OrderedCard
                                                                key={
                                                                    journey.label
                                                                }
                                                                index={
                                                                    index + 1
                                                                }
                                                                title={
                                                                    journey.label
                                                                }
                                                                subTitle={journey.spendings.toFixed(
                                                                    2
                                                                )}
                                                            />
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </ChartContainer>
                                <div className="grid-chart-container">
                                    <InformationContainer>
                                        <div className="title">
                                            <MdHotel />
                                            <h1 className="f22-700-dark">
                                                Hospedagem
                                            </h1>
                                        </div>
                                        <div className="row">
                                            <LabelValue
                                                size="LARGE"
                                                value={`R$ ${
                                                    tripMetrics?.hotel.averageDailyPrice.toLocaleString(
                                                        'pt-br',
                                                        {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) || 0
                                                }`}
                                                title="Preço médio"
                                                description="Preço médio pago por diária."
                                            />
                                            <LabelValue
                                                size="LARGE"
                                                value={tripMetrics.hotel.combinesWithFlights.toFixed(
                                                    0
                                                )}
                                                title="Viagens casadas"
                                                description="Qnt. de viagens que incluem tanto hospedagem como voo."
                                            />
                                        </div>
                                        <div className="divider" />
                                        <div className="main-indicators">
                                            <div className="main-companies">
                                                <div className="main-title">
                                                    <h1 className="f18-700-dark">
                                                        Principais hotéis
                                                    </h1>
                                                    <p className="f14-500-gray">
                                                        Quantidade de diárias no
                                                        hotel.
                                                    </p>
                                                </div>
                                                {tripMetrics.hotel.topHotels.map(
                                                    (hotel, index) => {
                                                        if (index >= 5)
                                                            return null
                                                        return (
                                                            <OrderedCard
                                                                isCurrency={
                                                                    false
                                                                }
                                                                key={
                                                                    hotel.label
                                                                }
                                                                index={
                                                                    index + 1
                                                                }
                                                                title={
                                                                    hotel.label
                                                                }
                                                                subTitle={hotel.spendings.toFixed(
                                                                    2
                                                                )}
                                                            />
                                                        )
                                                    }
                                                )}
                                            </div>

                                            <div className="main-routes">
                                                <div className="main-title">
                                                    <h1 className="f18-700-dark">
                                                        Principais destinos
                                                    </h1>
                                                    <p className="f14-500-gray">
                                                        Preço médio da diária na
                                                        cidade.
                                                    </p>
                                                </div>
                                                {tripMetrics.hotel.topDestinations.map(
                                                    (destination, index) => {
                                                        if (index >= 5)
                                                            return null
                                                        return (
                                                            <OrderedCard
                                                                key={
                                                                    destination.label
                                                                }
                                                                index={
                                                                    index + 1
                                                                }
                                                                title={
                                                                    destination.label
                                                                }
                                                                subTitle={destination.spendings.toFixed(
                                                                    2
                                                                )}
                                                            />
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </InformationContainer>
                                    <InformationContainer>
                                        <div className="title">
                                            <MdDirectionsCar />
                                            <h1 className="f22-700-dark">
                                                Locação de carro
                                            </h1>
                                        </div>
                                        <div className="row">
                                            <LabelValue
                                                size="LARGE"
                                                value={`R$ ${
                                                    tripMetrics?.car.averageDailyPrice.toLocaleString(
                                                        'pt-br',
                                                        {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) || 0
                                                }`}
                                                title="Preço médio da diária"
                                                description="Preço médio pago por aluguel."
                                            />
                                            <LabelValue
                                                size="LARGE"
                                                value={`${tripMetrics.car.averageSpan.toFixed(
                                                    0
                                                )} dia`}
                                                title="Período médio"
                                                description="Tempo médio de duração das locações."
                                            />
                                        </div>
                                        <div className="divider" />
                                        <div className="main-indicators">
                                            <div className="main-companies">
                                                <div className="main-title">
                                                    <h1 className="f18-700-dark">
                                                        Principais locadoras
                                                    </h1>
                                                    <p className="f14-500-gray">
                                                        Quantidade gasta.
                                                    </p>
                                                </div>
                                                {tripMetrics.car.topRentals.map(
                                                    (hotel, index) => {
                                                        if (index >= 5)
                                                            return null
                                                        return (
                                                            <OrderedCard
                                                                isCurrency
                                                                key={
                                                                    hotel.label
                                                                }
                                                                index={
                                                                    index + 1
                                                                }
                                                                title={
                                                                    hotel.label
                                                                }
                                                                subTitle={hotel.spendings.toFixed(
                                                                    2
                                                                )}
                                                            />
                                                        )
                                                    }
                                                )}
                                            </div>

                                            <div className="main-routes">
                                                <div className="main-title">
                                                    <h1 className="f18-700-dark">
                                                        Principais destinos
                                                    </h1>
                                                    <p className="f14-500-gray">
                                                        Quantidade gasta na
                                                        cidade.
                                                    </p>
                                                </div>
                                                {tripMetrics.car.topDestinations.map(
                                                    (destination, index) => {
                                                        if (index >= 5)
                                                            return null
                                                        return (
                                                            <OrderedCard
                                                                key={
                                                                    destination.label
                                                                }
                                                                index={
                                                                    index + 1
                                                                }
                                                                title={
                                                                    destination.label
                                                                }
                                                                subTitle={destination.spendings.toFixed(
                                                                    2
                                                                )}
                                                            />
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </InformationContainer>
                                </div>
                            </>
                        ) : (
                            <Skeleton width="100%" height="30rem" />
                        )}
                        <div className="section-title">
                            <h1 className="f24-700-dark">Compliance</h1>
                        </div>
                        {compliance ? (
                            <ChartContainer>
                                <div className="title">
                                    <img
                                        className="shield-img"
                                        src={shield}
                                        alt="shield"
                                    />
                                    <h1 className="f22-700-dark">Viagens</h1>
                                </div>
                                <div className="grid-container">
                                    <div className="left-side">
                                        <LostEconomies
                                            amount={`R$ ${
                                                compliance.lostEconomy.toLocaleString(
                                                    'pt-br',
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                ) || 0
                                            }`}
                                        />
                                        <div className="policy-title">
                                            <h1 className="f18-700-dark">
                                                Quebra de política por item
                                            </h1>
                                            <p className="f14-500-gray">
                                                Percentual de quebra de política
                                                por item.
                                            </p>
                                        </div>
                                        <div className="doughnuts-grid">
                                            <DoughnutChart
                                                icon={MdFlight}
                                                label="Passagens Aéreas"
                                                value={`${compliance.policyBreachesByItem.flights.percentage.toFixed(
                                                    2
                                                )}%`}
                                                percentage={
                                                    compliance
                                                        .policyBreachesByItem
                                                        .flights.percentage
                                                }
                                                description={`${compliance.policyBreachesByItem.flights.count} reservas`}
                                            />
                                            <DoughnutChart
                                                icon={MdHotel}
                                                label="Hospedagens"
                                                value={`${compliance.policyBreachesByItem.hotels.percentage.toFixed(
                                                    2
                                                )}%`}
                                                percentage={
                                                    compliance
                                                        .policyBreachesByItem
                                                        .hotels.percentage
                                                }
                                                description={`${compliance.policyBreachesByItem.hotels.count} reservas`}
                                            />
                                            <DoughnutChart
                                                icon={MdDirectionsCar}
                                                label="Locações"
                                                value={`${compliance.policyBreachesByItem.cars.percentage.toFixed(
                                                    2
                                                )}%`}
                                                percentage={
                                                    compliance
                                                        .policyBreachesByItem
                                                        .cars.percentage
                                                }
                                                description={`${compliance.policyBreachesByItem.cars.count} reservas`}
                                            />
                                            {/* <DoughnutChart
                                        icon={GiMeal}
                                        label="Alimentação"
                                        value={`${compliance.policyBreachesByItem.food.percentage.toFixed(
                                            2
                                        )}%`}
                                        percentage={
                                            compliance.policyBreachesByItem.food
                                                .percentage
                                        }
                                        description={`${compliance.policyBreachesByItem.food.count} despesas`}
                                    />
                                    <DoughnutChart
                                        icon={AiOutlineDollarCircle}
                                        label="Outras despesas"
                                        value={`${compliance.policyBreachesByItem.other.percentage.toFixed(
                                            2
                                        )}%`}
                                        percentage={
                                            compliance.policyBreachesByItem
                                                .other.percentage
                                        }
                                        description={`${compliance.policyBreachesByItem.other.count} despesas`}
                                    /> */}
                                            {/* <DoughnutChart
                                        icon={AiOutlineDollarCircle}
                                        label="Despesas"
                                        value={`${compliance.policyBreachesByItem.expenses.percentage.toFixed(
                                            2
                                        )}%`}
                                        percentage={
                                            compliance.policyBreachesByItem
                                                .expenses.percentage
                                        }
                                        description={`${compliance.policyBreachesByItem.other.count} despesas`}
                                    /> */}
                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <BrokenPoliciesReason
                                            category={
                                                compliance.policyBreaches
                                                    .category
                                            }
                                            daysInAdvance={
                                                compliance.policyBreaches
                                                    .daysInAdvance
                                            }
                                            price={
                                                compliance.policyBreaches.price
                                            }
                                        />
                                        <OutOfPolicyTable
                                            topPolicyBreaches={
                                                compliance.topPolicyBreaches
                                            }
                                        />
                                    </div>
                                </div>
                            </ChartContainer>
                        ) : (
                            <Skeleton width="100%" height="30rem" />
                        )}
                    </>
                )}
                {selectedTab === 'EXPENSES' && (
                    <>
                        {expensesMetrics ? (
                            <ChartContainer>
                                <div className="title">
                                    <FaDollarSign />
                                    <h1 className="f22-700-dark">Despesas</h1>
                                </div>

                                <div className="expenses-flow-chart">
                                    <div className="trip-metrics">
                                        <div className="expenses-indicators">
                                            <LabelValue
                                                size="LARGE"
                                                value={`R$ ${
                                                    expensesMetrics.totalExpensesAmount.toLocaleString(
                                                        'pt-br',
                                                        {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) || 0
                                                }`}
                                                title="Gastos com despesas"
                                                description="Valor total gasto em despesas no período."
                                            />
                                            <LabelValue
                                                size="LARGE"
                                                value={`${expensesMetrics.totalExpenses.toFixed(
                                                    0
                                                )}`}
                                                title="Quantidade de despesas"
                                                description="Quantidade total de despesas."
                                            />
                                        </div>
                                        <ExpensesFlowChart
                                            totalAmount={
                                                expensesMetrics.totalExpenses
                                            }
                                            data={
                                                expensesMetrics.expenseCategoryRanking
                                            }
                                        />
                                    </div>

                                    <div className="main-indicators">
                                        <div className="main-companies">
                                            <div className="main-title">
                                                <h1 className="f18-700-dark">
                                                    Centros de custo
                                                </h1>
                                                <p className="f14-500-gray">
                                                    Total gasto com despesas.
                                                </p>
                                            </div>
                                            {expensesMetrics.expensesPerManagementInfoOptionOrderedByAmount.map(
                                                (expense, index) => {
                                                    if (index >= 5) return null
                                                    return (
                                                        <OrderedCard
                                                            key={`expense${index}`}
                                                            index={index + 1}
                                                            title={`${
                                                                expense.label ||
                                                                'Outros'
                                                            }`}
                                                            subTitle={expense.totalExpensesAmount.toFixed(
                                                                2
                                                            )}
                                                        />
                                                    )
                                                }
                                            )}
                                        </div>

                                        <div className="main-routes">
                                            <div className="main-title">
                                                <h1 className="f18-700-dark">
                                                    Centros de custo
                                                </h1>
                                                <p className="f14-500-gray">
                                                    Quantidade de despesas.
                                                </p>
                                            </div>
                                            {expensesMetrics.expensesPerManagementInfoOptionOrderedByCount.map(
                                                (expense, index) => {
                                                    if (index >= 5) return null
                                                    return (
                                                        <OrderedCard
                                                            key={`expense${index}`}
                                                            index={index + 1}
                                                            isCurrency={false}
                                                            title={`${
                                                                expense.label ||
                                                                'Outros'
                                                            }`}
                                                            subTitle={expense.totalExpenses.toFixed(
                                                                2
                                                            )}
                                                        />
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </ChartContainer>
                        ) : (
                            <Skeleton width="100%" height="30rem" />
                        )}
                        <div className="section-title">
                            <h1 className="f24-700-dark">
                                Métricas de despesas
                            </h1>
                        </div>
                        {expensesCompliance ? (
                            <ChartContainer>
                                <div className="title">
                                    <img
                                        className="shield-img"
                                        src={shield}
                                        alt="shield"
                                    />
                                    <h1 className="f22-700-dark">Despesas</h1>
                                </div>
                                <div className="grid-container">
                                    <div className="left-side">
                                        <div className="indicators">
                                            <LabelValue
                                                size="LARGE"
                                                value={`R$ ${
                                                    expensesCompliance?.response.totalBreachAmount.toLocaleString(
                                                        'pt-br',
                                                        {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) || 0
                                                }`}
                                                title="Valor gasto fora da política"
                                                // description="Quantidade total de despesas."
                                            />
                                            <LabelValue
                                                size="LARGE"
                                                value={`${
                                                    expensesCompliance?.response.totalBreachCount.toFixed(
                                                        0
                                                    ) || 0
                                                }`}
                                                title="Despesas fora da política"
                                                // description="Quantidade total de despesas."
                                            />
                                        </div>
                                        <ExpensesBrokenPoliciesReason
                                            policyBreachesByExpenseCategory={
                                                expensesCompliance.response
                                                    .policyBreachesByExpenseCategory
                                            }
                                        />
                                    </div>
                                    <div className="right-side">
                                        <ExpensesComplianceTable
                                            topPolicyBreaches={
                                                expensesCompliance.response
                                                    .topPolicyBreaches
                                            }
                                        />
                                    </div>
                                </div>
                            </ChartContainer>
                        ) : (
                            <Skeleton width="100%" height="30rem" />
                        )}
                    </>
                )}
            </div>
        </HomeContainer>
    )
}
