import React, { useState } from 'react'
import { Button } from '../../components/Button'
import { useCompany } from '../../hooks/use-company'
import { ListMembers } from '../ListMembers'
import { MembersToAdd } from '../MembersToAdd'
import { OptionsToAdd } from '../OptionsToAdd'
import { useEditMember } from './hook/useEditMember'

export function RequestsFor() {
    const [openAddMembers, setOpenAddMembers] = useState(false)
    const [openAddManagement, setOpenAddManagement] = useState<
        string | undefined
    >()

    const { requestsFor, requestsForManagementInfo } = useEditMember()
    const [openListMembers, setOpenListMembers] = useState<
        | {
              id: string
              label: string
              url?: string
          }[]
        | undefined
    >()

    const { member, companyManagementInfos } = useCompany()

    function returnLinkedLabel(list: string[], type: 'USER' | 'MANAGEMENTS') {
        if (list.length === 0) {
            return `Não existem ${
                type === 'USER' ? 'viajantes vinculados' : 'opções vinculadas'
            }`
        }
        if (list.length <= 2) {
            return `${list.map((t) => t).join(', ')}`
        }
        return `${list[0]}, ${list[1]}... +${list.length - 2}`
    }

    function returnLinkedOptionsLabel(managementInfoId: string) {
        const fullManagementInfoInUser = requestsForManagementInfo.find(
            (mId) => mId.id === managementInfoId
        )
        if (fullManagementInfoInUser) {
            if (fullManagementInfoInUser.options.length === 1) {
                return `${fullManagementInfoInUser.options.map(
                    (opt) => opt.label
                )}`
            }
            if (fullManagementInfoInUser.options.length === 2) {
                return `${fullManagementInfoInUser.options
                    .map((opt) => opt.label)
                    .join(', ')}`
            }
            if (fullManagementInfoInUser.options.length > 2) {
                return `${fullManagementInfoInUser.options[0].label}, ${
                    fullManagementInfoInUser.options[2].label
                }... +${fullManagementInfoInUser.options.length - 2}`
            }
        }
        return `Nenhum ${
            companyManagementInfos.find((mId) => mId.id === managementInfoId)
                ?.label
        }...`
    }

    return (
        <div className="corporative-infos">
            <div className="company-info-header">
                <div className="company-info-header--title">
                    <h1 className="f18-700-dark">
                        {member?.firstName} {member?.lastName} solicita viagens
                        para
                    </h1>
                </div>
            </div>

            <div className="management-info" style={{ marginTop: '2rem' }}>
                <div className="row-button">
                    <div className="title-values">
                        <h1 className="reference-title">Usuários</h1>
                        <h1 className="f16-500-gray">
                            {returnLinkedLabel(
                                requestsFor.map((o) => o.label),
                                'USER'
                            )}
                        </h1>
                    </div>
                    <Button
                        color="PRIMARY"
                        buttonType="TEXT"
                        text="Editar"
                        onClick={() => setOpenAddMembers(true)}
                    />
                </div>
            </div>

            {companyManagementInfos.map((m) => {
                return (
                    <div className="management-info" key={m.id}>
                        <div className="row-button">
                            <div className="title-values">
                                <h1 className="reference-title">{m.label}</h1>
                                <h1 className="f16-500-gray">
                                    {returnLinkedOptionsLabel(m.id)}
                                </h1>
                            </div>
                            <Button
                                color="PRIMARY"
                                buttonType="TEXT"
                                text="Editar"
                                onClick={() => setOpenAddManagement(m.id)}
                            />
                        </div>
                    </div>
                )
            })}
            {openAddManagement && (
                <OptionsToAdd
                    isOpen={!!openAddManagement}
                    onRequestClose={() => setOpenAddManagement(undefined)}
                    managementId={openAddManagement}
                    addedOptions={
                        requestsForManagementInfo.find(
                            (m) => m.id === openAddManagement
                        )?.options
                    }
                    action="REQUESTS_FOR"
                />
            )}
            <MembersToAdd
                isOpen={openAddMembers}
                onRequestClose={() => setOpenAddMembers(false)}
                usersAdded={requestsFor}
                action="REQUESTS_FOR"
            />
            {openListMembers && (
                <ListMembers
                    isOpen={!!openListMembers}
                    onRequestClose={() => setOpenListMembers(undefined)}
                    usersAdded={openListMembers}
                />
            )}
        </div>
    )
}
