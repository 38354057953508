/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { AiOutlineDollarCircle } from 'react-icons/ai'
import { ControlButton } from '../../../components/ControlButton'
import { SelectInput } from '../../../components/SelectInput'
import { TitleDescription } from '../../../components/TitleDescription'
import { useCompany } from '../../../hooks/use-company'
import { ApprovalFlowSetting } from '../../../interfaces/traveler-clearance/approval-flow-setting'
import {
    ReturnApprovalFlowLabel,
    ReturnApprovalLevelLabel,
} from '../../../utils/users/functions'
import { DropDownPolitic } from '../headerPolicy'
import { SinglePolicieContainer } from './styles'
import { toolTips } from '../data/labels'
import { AdvanceMoneyPolicy } from '../../../interfaces/traveler-clearance/traveler-clearance/polices/advance-money-policy'
import { SimpleInput } from '../../../components/SimpleInput'

interface Props {
    advanceMoney: AdvanceMoneyPolicy
}

export function AdvanceMoney({ advanceMoney }: Props) {
    // hooks
    const { editTravelerClearance, selectTravelerClearance, company } =
        useCompany()
    const [advMoney, setAdvmoney] = useState<AdvanceMoneyPolicy>(advanceMoney)

    const approvalLevelOptions: {
        label: string
        value: number
    }[] = new Array(company?.maxApprovalLevel || 1).fill(1).map((_, i) => {
        return {
            label: `Nivel ${i + 1}`,
            value: i + 1,
        }
    })

    useEffect(() => {
        setAdvmoney(advanceMoney)
    }, [selectTravelerClearance])

    return (
        <DropDownPolitic
            title="Adiantamentos"
            description="Adiantamentos que fogem das regras configuradas abaixo serão considerados como fora da política."
            icon={AiOutlineDollarCircle}
            callback={async () => {
                await editTravelerClearance(selectTravelerClearance!.id, {
                    advanceMoney: advMoney,
                })
            }}
        >
            <SinglePolicieContainer>
                <TitleDescription title="Permitir fazer solicitações de adiantamentos?" />
                <div className="inputs">
                    <ControlButton
                        id="advMoney.productEnabled"
                        type="SWITCH"
                        isPressed={advMoney.enabled}
                        callback={() => {
                            setAdvmoney((prevState: any) => {
                                return {
                                    ...prevState,
                                    enabled: !prevState.enabled,
                                }
                            })
                        }}
                    />
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription
                    title="Fluxo de aprovação"
                    description="Defina quando um adiantamento precisa ser aprovado e quantos níveis de aprovação devem existir."
                    info={toolTips.approvalFlow}
                />
                <div className="inputs">
                    {/* <SelectInput
                        name="adv"
                        value={{
                            label: ReturnApprovalFlowLabel(
                                advMoney.approvalFlow.setting
                            ),
                            value: advMoney.approvalFlow.setting,
                        }}
                        options={[
                            {
                                label: 'Sempre',
                                value: ApprovalFlowSetting.ALWAYS,
                            },
                            {
                                label: 'Dentro da política',
                                value: ApprovalFlowSetting.INSIDE_POLICY,
                            },
                            {
                                label: 'Nunca',
                                value: ApprovalFlowSetting.NEVER,
                            },
                        ]}
                        onChange={(e) => {
                            if (e) {
                                setAdvmoney((prevState) => {
                                    return {
                                        ...prevState,
                                        approvalFlow: {
                                            ...prevState.approvalFlow,
                                            setting: e.value,
                                        },
                                    }
                                })
                            }
                        }}
                    /> */}
                    <div className="label-input">
                        <p>Defina a quantidade de níveis</p>
                        <SelectInput
                            name="adv2"
                            value={{
                                label: ReturnApprovalLevelLabel(
                                    advMoney.approvalFlow.requiredApprovalLevel
                                ),
                                value: advMoney.approvalFlow
                                    .requiredApprovalLevel,
                            }}
                            options={approvalLevelOptions}
                            onChange={(e) => {
                                if (e) {
                                    setAdvmoney((prevState) => {
                                        return {
                                            ...prevState,
                                            approvalFlow: {
                                                ...prevState.approvalFlow,
                                                requiredApprovalLevel: e.value,
                                            },
                                        }
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription
                    title="Valor máximo permitido para um adiantamento"
                    description="Defina um valor máximo permitido para um adiantamento."
                />
                <div className="inputs">
                    <SimpleInput
                        label=""
                        name="a"
                        isPricing
                        value={advMoney.maxAmount}
                        onChange={(e) => {
                            if (!/^[0-9]*$/.test(e.target.value)) return
                            setAdvmoney((prevState: any) => {
                                return {
                                    ...prevState,
                                    maxAmount: e.target.value,
                                }
                            })
                        }}
                    />
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription title="Permitir somente apenas um adiantamento em aberto?" />
                <div className="inputs">
                    <ControlButton
                        id="adv.canOnlyHaveOneOpened"
                        type="SWITCH"
                        isPressed={advMoney.canOnlyHaveOneOpened}
                        callback={() => {
                            setAdvmoney((prevState: any) => {
                                return {
                                    ...prevState,
                                    canOnlyHaveOneOpened:
                                        !prevState.canOnlyHaveOneOpened,
                                }
                            })
                        }}
                    />
                </div>
            </SinglePolicieContainer>
        </DropDownPolitic>
    )
}
