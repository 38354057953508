import styled from 'styled-components'

export const ButtonContainer = styled.button`
    position: relative;
    width: 100%;
    padding: 1.5rem 1.5rem;
    border-radius: 0.8rem;
    font-size: 1.6rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
        cursor: not-allowed;
    }
    .icon-space {
        width: 3.5rem;
    }
    svg {
        position: absolute;
        left: 1.5rem;
        margin-right: 1rem;
        height: 2rem;
        width: 2rem;
    }
`
