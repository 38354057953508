/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import ModalContainer from 'react-modal'
import { toast } from 'react-toastify'
import { Button } from '../../components/Button'
import { ExportReportContainer } from './styles'
import { useCompany } from '../../hooks/use-company'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    type: 'USERS' | 'INVITE_USER' | 'OPTIONS' | 'MANAGEMENT_INFOS' | undefined
    managementInfoId?: string
}

export function ImportFile({
    isOpen,
    onRequestClose,
    type,
    managementInfoId,
}: Props) {
    const [busy, setBusy] = useState(false)
    const [csv, setCsv] = useState<File | undefined>()
    const { importUsers, importInviteUsers, importOptions } = useCompany()

    async function importHandler() {
        if (!csv)
            return toast.warning('Insira um arquivo csv para ser importado')
        setBusy(true)
        const formData = new FormData()
        formData.append('file', csv)

        let response
        if (type === 'USERS') {
            response = await importUsers(formData)
        }
        if (type === 'INVITE_USER') {
            response = await importInviteUsers(formData)
        }
        if (type === 'OPTIONS' && managementInfoId) {
            response = await importOptions(formData, managementInfoId)
        }
        setBusy(false)
        if (response) window.location.reload()
        return undefined
    }

    function dinamicLabel() {
        if (type === 'USERS') {
            return 'Adicionar usuários em lote'
        }
        if (type === 'INVITE_USER') {
            return 'Convidar usuários em lote'
        }
        if (type === 'OPTIONS' && managementInfoId) {
            return 'Importar opções gerenciais em lote'
        }
        if (type === 'MANAGEMENT_INFOS') {
            return 'Importar informações de gerenciais em lote'
        }
        return ''
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-export"
        >
            <ExportReportContainer>
                <div className="content">
                    <div className="header">
                        <div className="header-title">
                            <h1 className="f24-700-dark">{dinamicLabel()}</h1>
                            <p className="f14-500-gray">
                                Adicione uma planilha no formato correto para
                                importar os dados.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="content-import">
                    <div className="import-btn">
                        <label htmlFor="multiple-bookings">
                            <div className="container">
                                {csv ? (
                                    <p>{csv.name}</p>
                                ) : (
                                    <p>Clique aqui para selecionar o arquivo</p>
                                )}
                            </div>
                            <input
                                type="file"
                                id="multiple-bookings"
                                className="multiple-bookings"
                                onChange={(e: any) => {
                                    setCsv(e.target.files[0])
                                }}
                            />
                        </label>
                    </div>
                    <Button
                        color="PRIMARY"
                        buttonType="FILLED"
                        text="Importar arquivo"
                        busy={busy}
                        onClick={async () => importHandler()}
                    />
                </div>
            </ExportReportContainer>
        </ModalContainer>
    )
}
