/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { Form } from '@unform/web'
import React, { useEffect, useRef, useState } from 'react'
import { FiActivity, FiPlusCircle } from 'react-icons/fi'
import { MdDownload, MdListAlt, MdUpload } from 'react-icons/md'
import { v4 } from 'uuid'
import { toast } from 'react-toastify'
import { FaPlusCircle } from 'react-icons/fa'
import { Button } from '../../../components/Button'
import { SideMenu } from '../../../components/SideMenu'
import { ManagementInfo } from '../../../entities/management-info'
import { useCompany } from '../../../hooks/use-company'
import { Input } from '../../../UnformInputs/Input'
import { Table } from '../../../utils/table'
import { SingleConfigurationManagement } from './SingleConfigurationManagement'
import { ManagementSubPageContainer, ManagementSubPageContent } from './styles'
import { getValidationErrors } from '../../../utils/getValidationErrors'
import { CostCenterHandler } from '../../../modals/CostCenterHandler'
import { usePayment } from '../../../hooks/use-payment'
import { AddList } from '../../../modals/AddList'
import { ImportFile } from '../../../modals/ImportFile'
import { ControlButton } from '../../../components/ControlButton'

export function ManagementSubPage() {
    // hooks
    const {
        addManagementInfo,
        getManagementInfo,
        managementInfo,
        openEditOption,
        openEditOptionHandler,
        exportManagementInfos,
        editManagementInfo,
    } = useCompany()
    const { elementToAddSettings, elementToAddSettingsHandler } = usePayment()

    const formRef = useRef<FormHandles>(null)
    // state
    const [selectedManagementInfo, setselectedManagementInfo] = useState<
        ManagementInfo | undefined
    >()
    const [openCreateNewManagementInfo, setOpenCreateNewManagementInfo] =
        useState(false)
    const [
        openCreateNewManagementInfoOption,
        setOpenCreateNewManagementInfoOption,
    ] = useState(false)
    const [busyBtn, setBusyBtn] = useState(false)
    const [exportBusy, setExportBusy] = useState(false)
    const [busy, setBusy] = useState(false)
    const [shouldRefresh, setShouldRefresh] = useState(false)
    const [openImportFile, setOpenImportFile] = useState<
        'USERS' | 'INVITE_USER' | 'OPTIONS' | 'MANAGEMENT_INFOS' | undefined
    >()

    useEffect(() => {
        setBusy(true)
        ;(async () => {
            const response = await getManagementInfo()
            if (response) {
                setselectedManagementInfo(response[0])
            }
            setBusy(false)
        })()
    }, [])

    useEffect(() => {
        if (shouldRefresh) {
            setBusy(true)
            ;(async () => {
                const response = await getManagementInfo()
                if (response) {
                    setselectedManagementInfo(
                        response.find(
                            (m) => m.id === selectedManagementInfo!.id
                        )
                    )
                }
                setBusy(false)
            })()
            setShouldRefresh(false)
        }
    }, [shouldRefresh])

    function changeSubMenu(type: string) {
        setselectedManagementInfo(managementInfo.find((m) => m.id === type))
        setOpenCreateNewManagementInfo(false)
    }

    async function addManagementInfoHandler(data: { label: string }) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                label: Yup.string().required('Insira um nome '),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            const response = await addManagementInfo(data.label)
            if (response) {
                setselectedManagementInfo(response)
                setBusyBtn(false)
                toast.success(
                    `${data.label} adicionado como novo campo gerencial`
                )
                setOpenCreateNewManagementInfo(false)
                setShouldRefresh(true)
            }
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            toast.info('Verifique se você preencheu o campo')
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    async function editManagementInfoHandler() {
        const res = await editManagementInfo(
            selectedManagementInfo?.id || '',
            !selectedManagementInfo?.isMandatory
        )
        if (res) {
            toast.success('Obrigatoriedade alterada com sucesso')
            setselectedManagementInfo({
                ...selectedManagementInfo!,
                isMandatory: res.isMandatory,
            })
        }
    }

    return (
        <ManagementSubPageContainer>
            {busy ? (
                <div />
            ) : (
                <>
                    <div className="side-menu">
                        <SideMenu
                            changeTab={changeSubMenu}
                            title="Configurações Gerais"
                            value={selectedManagementInfo?.id ?? ''}
                            sideMenus={
                                managementInfo.map((management) => {
                                    return {
                                        id: management.id,
                                        label: management.label,
                                        icon: FiActivity,
                                    }
                                }) ?? []
                            }
                        />
                        <button
                            type="button"
                            className="add-management-info"
                            onClick={() => {
                                setOpenCreateNewManagementInfo(true)
                            }}
                        >
                            <FiPlusCircle />
                            <h2>Criar outro campo gerencial</h2>
                        </button>
                        <Button
                            buttonType="FILLED"
                            text="Exportar campos gerenciais"
                            color="PRIMARY"
                            icon={MdDownload}
                            busy={exportBusy}
                            onClick={async () => {
                                setExportBusy(true)
                                await exportManagementInfos()
                                setExportBusy(false)
                            }}
                        />
                    </div>
                    <ManagementSubPageContent>
                        {openCreateNewManagementInfo ? (
                            <div className="create-management-info">
                                <div className="card">
                                    <MdListAlt />
                                    <div className="description">
                                        <h1>Novo campo gerencial</h1>
                                        <p>Como vai se chamar o campo?</p>
                                    </div>
                                </div>
                                <Form
                                    ref={formRef}
                                    onSubmit={addManagementInfoHandler}
                                >
                                    <div className="input">
                                        <Input
                                            name="label"
                                            label="Nome do campo gerencial"
                                        />
                                    </div>
                                    <Button
                                        text="Criar campo"
                                        type="submit"
                                        buttonType="FILLED"
                                        color="PRIMARY"
                                        busy={busyBtn}
                                    />
                                </Form>
                            </div>
                        ) : (
                            <>
                                <div className="management-info">
                                    <div className="card">
                                        <MdListAlt />
                                        <div className="description">
                                            <div>
                                                <h1>
                                                    {
                                                        selectedManagementInfo?.label
                                                    }
                                                </h1>
                                                <p>{`${
                                                    selectedManagementInfo
                                                        ?.options.length
                                                } ${
                                                    selectedManagementInfo
                                                        ?.options.length === 1
                                                        ? `opção cadastrada em ${selectedManagementInfo.label}.`
                                                        : `opções cadastradas em ${selectedManagementInfo?.label}.`
                                                } `}</p>
                                            </div>

                                            <div className="isMandatory">
                                                <p>{`${selectedManagementInfo?.label} deve ser obrigatório?`}</p>
                                                <ControlButton
                                                    id="isMandatory"
                                                    isPressed={
                                                        selectedManagementInfo?.isMandatory ||
                                                        false
                                                    }
                                                    type="SWITCH"
                                                    callback={async () => {
                                                        await editManagementInfoHandler()
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="verify-btn">
                                        <Button
                                            buttonType="BORDERED"
                                            color="PRIMARY"
                                            borderPrimary={false}
                                            text="Verificar"
                                        />
                                    </div> */}
                                </div>
                                <div className="filters">
                                    <div className="add-button">
                                        <Button
                                            text="Importar opções em lote"
                                            buttonType="BORDERED"
                                            color="PRIMARY"
                                            icon={MdUpload}
                                            onClick={() =>
                                                setOpenImportFile('OPTIONS')
                                            }
                                        />
                                    </div>
                                    <div className="add-button">
                                        <Button
                                            text="Adicionar opção"
                                            buttonType="BORDERED"
                                            color="PRIMARY"
                                            icon={FaPlusCircle}
                                            onClick={() =>
                                                setOpenCreateNewManagementInfoOption(
                                                    true
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <Table>
                                    <div
                                        className="table-header"
                                        style={{
                                            gridTemplateColumns:
                                                '1.3fr 3fr 2fr 1.5fr 1fr',
                                        }}
                                    >
                                        <h1 className="start">Código</h1>
                                        <h1 className="start">Nome</h1>
                                        <h1 className="start">Qnt. Membros</h1>
                                        <h1 className="start">Status</h1>
                                        <h1 className="start">Ação</h1>
                                    </div>
                                    {selectedManagementInfo?.options.map(
                                        (option) => {
                                            return (
                                                <SingleConfigurationManagement
                                                    key={v4()}
                                                    option={option}
                                                    // isMandatory={
                                                    //     selectedManagementInfo.isMandatory ||
                                                    //     false
                                                    // }
                                                />
                                            )
                                        }
                                    )}
                                </Table>
                            </>
                        )}
                    </ManagementSubPageContent>
                    {selectedManagementInfo && (
                        <CostCenterHandler
                            isOpen={openCreateNewManagementInfoOption}
                            onRequestClose={() =>
                                setOpenCreateNewManagementInfoOption(false)
                            }
                            shouldRefresh={() => setShouldRefresh(true)}
                            managementId={selectedManagementInfo.id}
                        />
                    )}
                    {selectedManagementInfo && openEditOption && (
                        <CostCenterHandler
                            isOpen={!!openEditOption}
                            onRequestClose={() =>
                                openEditOptionHandler(undefined)
                            }
                            shouldRefresh={() => setShouldRefresh(true)}
                            managementId={selectedManagementInfo.id}
                            option={openEditOption}
                        />
                    )}
                </>
            )}
            {elementToAddSettings?.type !== 'CLOSED' && (
                <AddList
                    isOpen={elementToAddSettings.type !== 'CLOSED'}
                    onRequestClose={() => {
                        elementToAddSettingsHandler({
                            list: [],
                            listToAdd: [],
                            type: 'CLOSED',
                            id: '',
                        })
                        setOpenCreateNewManagementInfoOption(false)
                        openEditOptionHandler(undefined)
                    }}
                    paymentMethod={elementToAddSettings.paymentMethod}
                    id={elementToAddSettings.id}
                    type={elementToAddSettings.type}
                    list={elementToAddSettings.list}
                    listToAdd={elementToAddSettings.listToAdd}
                />
            )}
            {openImportFile && (
                <ImportFile
                    isOpen={!!openImportFile}
                    onRequestClose={() => setOpenImportFile(undefined)}
                    type={openImportFile}
                    managementInfoId={selectedManagementInfo?.id}
                />
            )}
        </ManagementSubPageContainer>
    )
}
