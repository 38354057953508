import styled from 'styled-components'

export const ProvidersSubPageContainer = styled.div`
    margin: 0 auto;
    width: 98rem;
    .providers-info {
        padding: 3.5rem 2.5rem;
        background-color: #fff;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        margin-bottom: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .verify-btn {
            button {
                padding: 1.5rem 3rem;
            }
        }
        .card {
            display: flex;
            align-items: flex-start;
            svg {
                height: 2rem;
                width: 2rem;
                margin-right: 2rem;
            }
            .description {
                h1 {
                    color: #191b1e;
                    font-size: 2.2rem;
                    font-weight: 700;
                    margin-bottom: 0.8rem;
                }
                p {
                    color: #646669;
                    font-weight: 500;
                    font-size: 1.6rem;
                }
            }
        }
    }
`
export const ProvidersSubPageContent = styled.div`
    .title {
        font-size: 2.4rem;
        font-weight: 700;
        color: #000;
        margin-bottom: 2.6rem;
    }
    .providers-cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 2rem;
        margin-bottom: 4rem;
    }
`
