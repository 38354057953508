import styled from 'styled-components'

export const InputContainer = styled.div`
    .error-message {
        text-align: start;
        font-size: 1.3rem;
        color: var(--color-error);
        margin: 0;
    }

    .error {
        color: var(--color-error);
    }
`
export const InputContent = styled.div`
    position: relative;
    width: 100%;
    min-width: 23rem;
    font-size: 1.6rem;
    position: relative;
    height: 4.5rem;
    overflow: hidden;
    border: 1px solid var(--color-light-gray);
    border-radius: 0.8rem;
    svg {
        position: absolute;
        top: 50%;
        width: 2rem;
        height: 2rem;
        color: var(--color-secondary-gray);
        transform: translate(50%, -50%);
    }
    .percentage {
        position: absolute;
        right: 0;
        height: 4.5rem;
        width: 4.5rem;
        background-color: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-gray);
    }

    & input:focus {
        border-radius: 0.8rem;
        border: 1px solid #000;
    }
    input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        padding-left: 1.5rem;
    }
`
