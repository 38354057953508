import styled from 'styled-components'

export const PaymentMethodsSubPageContainer = styled.div`
    margin: 0 auto;
    width: 140rem;
    margin-bottom: 4rem;
    .payment-method-info {
        padding: 3.5rem 2.5rem;
        background-color: #fff;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        margin-bottom: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .verify-btn {
            button {
                padding: 1.5rem 3rem;
            }
        }
        .card {
            display: flex;
            align-items: flex-start;
            svg {
                height: 2rem;
                width: 2rem;
                margin-right: 2rem;
            }
            .description {
                h1 {
                    color: #191b1e;
                    font-size: 2.2rem;
                    font-weight: 700;
                    margin-bottom: 0.8rem;
                }
                p {
                    color: #646669;
                    font-weight: 500;
                    font-size: 1.6rem;
                }
            }
        }
    }
`

export const PaymentMethodFilter = styled.div`
    height: 5rem;
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
        width: 20rem;
    }
`

export const PaymentMethodsSubPageContent = styled.div`
    width: 140rem;
`
