import styled from 'styled-components'

export const SingleUserContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 2fr 2fr 2fr;
    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        &:not(:last-child) {
            border-right: 2px solid #c8cacd;
        }
        .user-info {
            padding: 1.5rem 2rem 1.5rem 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            &--data {
                margin-left: 1.3rem;
                .user-name {
                    width: 20rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #191b1e;
                    font-size: 1.6rem;
                    font-weight: 700;
                }
                .user-email {
                    width: 20rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #646669;
                    font-weight: 500;
                    font-size: 1.4rem;
                }
            }
        }
        .item-button {
            width: 16rem;
        }
        .select-div {
            width: 100%;
            padding: 0 1.3rem;
        }
    }
    .start {
        justify-content: start;
    }
    &:nth-child(2n + 2) {
        background: #fff;
    }
`
