export enum ProductTypes {
    FLIGHT = 'FLIGHT',
    HOTEL = 'HOTEL',
    CAR = 'CAR',
}

export interface PaymentFilter {
    isActive?: boolean
    productTypes?: ProductTypes[]
    canBeUsedOnExpenses?: boolean
    isGlobal?: boolean
    managementInfos: { id: string; options: string[] }[]
    accessibleByUserId?: string
}
