/* eslint-disable @typescript-eslint/no-unused-vars */

import ParkingLot from '../../assets/expenses/parking_lot.png'
import ShoopingBasket from '../../assets/expenses/shopping_basket.png'
import GasStation from '../../assets/expenses/gas_station.png'
import DollarSign from '../../assets/expenses/dollar-sign.png'
import GroceryStore from '../../assets/expenses/grocery_store.png'
import Restaurant from '../../assets/expenses/restaurant.png'
import { InvoiceType } from '../../enums/InvoiceType.enum'
import { ExpensePaymentMethod } from '../../enums/expense-payment-method.enum'
import { Expenses } from '../../interfaces/expenses'
import { ExpenseStatus } from '../../enums/expenses-status.enum'

export function expenseTypeIcon(type: string) {
    if (type === 'MILEAGE') return GasStation
    if (type === 'ADVANCED_MONEY') return ShoopingBasket
    return Restaurant
}

export function expensesIconHandler(type: InvoiceType) {
    if (type === InvoiceType.GROCERIES) return GroceryStore
    if (type === InvoiceType.MEAL) return Restaurant
    return DollarSign
}

export function expensesPaymentLabel(type: ExpensePaymentMethod) {
    if (type === ExpensePaymentMethod.ADVANCE_MONEY) return 'Adiantamento'
    if (type === ExpensePaymentMethod.CASH) return 'Dinheiro'
    return 'Não reembolsável'
}

export function expenseStatusLabel(status: string) {
    if (status === ExpenseStatus.PENDING_APPROVAL) {
        return 'Enviado para aprovação'
    }
    if (status === ExpenseStatus.CONFIRMED) {
        return 'Confirmado'
    }
    if (status === ExpenseStatus.REJECTED) {
        return 'Rejeitado'
    }

    if (status === ExpenseStatus.CREATED) {
        return 'Criado'
    }

    if (status === ExpenseStatus.COMPLETED) {
        return 'Conciliado'
    }

    return ''
}

export function translatedName(type: InvoiceType): string {
    switch (type) {
        case InvoiceType.MEAL:
            return 'Refeição'
        case InvoiceType.TOLL:
            return 'Pedágio'
        case InvoiceType.GROCERIES:
            return 'Mercado'
        case InvoiceType.EXPENSE:
            return 'Despesa'
        case InvoiceType.APP_99:
            return 'App 99'
        case InvoiceType.HR_ACTION:
            return 'Ação de RH'
        case InvoiceType.TRAVEL_ADVANCE:
            return 'Adiantamento de Viagem'
        case InvoiceType.WATER:
            return 'Água'
        case InvoiceType.LUNCH:
            return 'Almoço'
        case InvoiceType.LUNCH_WITH_CLIENT:
            return 'Almoço com Cliente'
        case InvoiceType.SEAT:
            return 'Assento'
        case InvoiceType.LUGGAGE:
            return 'Bagagem'
        case InvoiceType.GIFTS:
            return 'Presentes'
        case InvoiceType.BREAKFAST:
            return 'Café da Manhã'
        case InvoiceType.FUEL:
            return 'Combustível'
        case InvoiceType.PARKING:
            return 'Estacionamento'
        case InvoiceType.HAPPY_HOUR_WITH_CLIENT:
            return 'Happy Hour com Cliente'
        case InvoiceType.DINNER:
            return 'Jantar'
        case InvoiceType.DINNER_WITH_CLIENT:
            return 'Jantar com Cliente'
        case InvoiceType.SNACK:
            return 'Lanche'
        case InvoiceType.LAUNDRY:
            return 'Lavanderia'
        case InvoiceType.OTHERS:
            return 'Outros'
        case InvoiceType.AIR_TICKET:
            return 'Passagem Aérea'
        case InvoiceType.BUS_TICKET:
            return 'Passagem de Ônibus'
        case InvoiceType.ROUTE:
            return 'Rota'
        case InvoiceType.MILEAGE:
            return 'Quilometragem'
        case InvoiceType.BOARDING_FEES:
            return 'Taxa de Embarque'
        case InvoiceType.TAXI:
            return 'Táxi'
        case InvoiceType.UBER:
            return 'Uber'
        case InvoiceType.TRIP:
            return 'Viagem'
        default:
            return 'Indefinido'
    }
}
