import styled from 'styled-components'

export const SingleCreditCardContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr 2fr 2fr 2fr 1fr 1fr;
    &:nth-child(2n + 2) {
        background: #fff;
    }
    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        padding: 1.8rem 0;

        h1 {
            font-size: 1.6rem;
            color: #000;
            font-weight: 700;
        }
        img {
            margin-left: 2.5rem;
        }
        .description {
            width: 100%;
            margin-left: 2.5rem;
            h1 {
                font-size: 1.6rem;
                color: #191b1e;
                font-weight: 700;
            }
            p {
                font-size: 1.6rem;
                font-weight: 500;
                color: #646669;
            }
        }
        &:not(:last-child) {
            border-right: 2px solid #c8cacd;
        }
        .select-div {
            width: 100%;
            padding: 0 1.3rem;
        }
        .item-actions {
            display: flex;
            align-items: center;
            .btn {
                &:not(:last-child) {
                    margin-right: 2rem;
                }
            }
        }
    }
`
