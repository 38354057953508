import styled from 'styled-components'

export const InviteUserContainer = styled.div`
    margin-bottom: 5rem;
    .form > div:not(:last-child) {
        margin-bottom: 1.5rem;
    }
    .form {
        &--grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1.5rem;
        }
        &--grid-3 {
            display: grid;
            grid-template-columns: 3fr 1fr;
            grid-gap: 1.5rem;
        }
    }
    .information-row {
        width: var();
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 0;
        &:not(:last-child) {
            border-bottom: 2px solid #f4f4f4;
        }
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .information {
            margin-left: 2rem;
            .tags {
                display: flex;
                .tag {
                    background: rgba(77, 221, 20, 0.1);
                    border: 1px solid #3bb90a;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.5rem;
                    color: #3bb90a;
                    margin-right: 1rem;
                    svg {
                        margin-left: 1rem;
                    }
                }
                .tag--active {
                    background: rgba(77, 221, 20, 0.1);
                    border: 1px solid #3bb90a;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.5rem;
                    color: #3bb90a;
                    margin-right: 1rem;
                    svg {
                        margin-left: 1rem;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .tag--error {
                    background: rgba(232, 96, 74, 0.2);
                    border: 1px solid rgba(219, 97, 78, 1);
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.5rem;
                    color: rgba(219, 97, 78, 1);
                    margin-right: 1rem;
                    svg {
                        margin-left: 1rem;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
`
export const Flex = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin-bottom: 2rem;
`
