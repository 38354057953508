import styled from 'styled-components'

export const ExpensesModalContainer = styled.div`
    .header {
        background-color: #fff;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 3.6rem 2.1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .description {
            display: flex;
            align-items: center;
            gap: 2rem;
        }
        .history-btn {
            border: none;
            background-color: inherit;
            color: var(--color-primary);
            font-weight: 700;
            font-size: 1.4rem;
            &:hover {
                cursor: pointer;
            }
        }
        margin-bottom: 1.5rem;
    }
    .content {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        &-block {
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            width: 100%;
            padding: 1.5rem;
            background-color: #fff;
            .italic {
                font-style: italic;
            }
            .container {
                .label-value {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    align-items: center;
                    padding: 2rem 0;
                    border-bottom: 1px solid #f4f4f4;
                    & > p {
                        padding: 0 1rem;
                    }
                    .row {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        .avatar {
                            height: 3rem;
                            width: 3rem;
                            border-radius: 50%;
                            background-color: var(--color-primary);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            h1 {
                                color: #fff;
                                font-size: 1rem;
                                font-weight: 700;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        margin-bottom: 1.5rem;
    }
    .comments {
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        width: 100%;
        padding: 1.5rem;
        background-color: #fff;
        .description {
            min-height: 8rem;
            margin-top: 1.5rem;
            padding: 1.5rem;
            border-radius: 8px;
            border: 1px solid #c8cacd;
            background-color: #edeff2;
        }
    }
`
