import styled from 'styled-components'

export const TravelerTagContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: #f6f7f8;
    border: 1px solid #c8cacd;
    border-radius: 9999px;
    min-width: 18.8rem;
    max-width: 22rem;
    height: 3.6rem;

    .image {
        height: 3.6rem;
        width: 3.6rem;
        img {
            height: 3.6rem;
            width: 3.6rem;
            border-radius: 50%;
        }
        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.6rem;
            width: 3.6rem;
            background-color: var(--color-primary);
            border-radius: 50%;
            color: #fff;
        }
    }
    .name {
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0rem 1rem;
        overflow: hidden;
    }
`
