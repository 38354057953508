import styled from 'styled-components'

export const OfficeHandlerContainer = styled.div`
    .form > div:not(:last-child) {
        margin-bottom: 1.5rem;
    }
    .form {
        &--grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1.5rem;
        }
        &--grid-3 {
            display: grid;
            grid-template-columns: 3fr 1fr;
            grid-gap: 1.5rem;
        }
    }
`

export const EditPaymentMethodContainer = styled.div`
    max-height: 80vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 0.5rem;
        background: #edeff2;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background: #96989b;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #96989b;
    }
    .action-btns {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .action-btn {
            margin: 0 auto;
            margin-top: 3rem;
            width: 20rem;
        }
    }
    .action-btn {
        margin: 0 auto;
        margin-top: 3rem;
        width: 20rem;
    }
`
export const PaymentMethodInfo = styled.div`
    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        .label-value {
            .label {
                margin-bottom: 0.4rem;
            }
        }
    }
`
export const PaymentMethodInfoUsedInContainer = styled.div`
    .payment-type-used {
        .select-type {
            padding: 1.8rem 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #f4f4f4;
        }
    }
`

export const UsersLinkedContainer = styled.div`
    .row-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 10rem;
        }
    }
`
export const CostCentersLinkedContainer = styled.div`
    .row-button {
        &:not(:last-child) {
            border-bottom: 1px solid #f4f4f4;
        }
        padding: 1rem 0%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 10rem;
        }
        .info-row {
            display: flex;
            align-items: center;
            & > h1 {
                margin-right: 1rem;
            }
        }
    }
`
