import styled from 'styled-components'

export const GeneralSubPageContainer = styled.div`
    display: grid;
    grid-template-columns: 28rem 80rem 28rem;
    grid-gap: 2rem;
    align-items: flex-start;
    justify-content: center;
`
export const GeneralSubPageContent = styled.div`
    background-color: #fff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
`
