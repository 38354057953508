/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { MdPeople } from 'react-icons/md'
import ModalContainer from 'react-modal'
import { toast } from 'react-toastify'
import { Button } from '../../components/Button'
import { Input } from '../../UnformInputs/Input'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { useCompany } from '../../hooks/use-company'
import { PolicieContainer } from './styles'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
}

export function CreateTravelerClearanceC({ isOpen, onRequestClose }: Props) {
    // hooks
    const { createTravelerClearance } = useCompany()

    // ref
    const formRef = useRef<FormHandles>(null)
    // state
    const [busyBtn, setBusyBtn] = useState(false)

    async function handleInviteUser(data: { label: string }) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                label: Yup.string().required(
                    'Insira um nome para o novo perfil de viajante'
                ),
            })
            await schema.validate(data, {
                abortEarly: false,
            })

            const response = await createTravelerClearance(data.label)
            if (response) {
                toast.success(`Política ${data.label} criada com sucesso`)
            }
            setBusyBtn(false)
            onRequestClose()
        } catch (error) {
            setBusyBtn(false)
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <Form ref={formRef} onSubmit={handleInviteUser} noValidate>
                <div className="modal-header">
                    <div className="modal-header-title">
                        <MdPeople />
                        <h1 className="f22-700-dark">
                            Criar novo perfil de viajante
                        </h1>
                    </div>
                    <div className="modal-header-description">
                        <div className="modal-header-description--blank" />
                        <p className="f16-500-gray">
                            O perfil de viajante tem como responsabilidade
                            determinar suas políticas de viagem.
                        </p>
                    </div>
                </div>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <PolicieContainer>
                    <div className="modal-content">
                        <div className="title">
                            <h1 className="f16-500-gray-secondary">
                                Perfil do perfil de viajante
                            </h1>
                        </div>

                        <div className="form">
                            <Input label="Nome do perfil" name="label" />
                        </div>
                    </div>
                </PolicieContainer>
                <div className="modal-confirm-btn">
                    <Button
                        color="PRIMARY"
                        buttonType="FILLED"
                        text="Criar perfil"
                        busy={busyBtn}
                        type="submit"
                    />
                </div>
            </Form>
        </ModalContainer>
    )
}
