import styled from 'styled-components'

export const AvatarContainer = styled.div`
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
        color: #fff;
        font-size: 1.4rem;
        font-weight: 700;
    }
    img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
    }
`
