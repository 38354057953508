/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { MdApartment } from 'react-icons/md'
import ModalContainer from 'react-modal'
import { toast } from 'react-toastify'
import { Button } from '../../components/Button'
import { Input } from '../../UnformInputs/Input'
import { getValidationErrors } from '../../utils/getValidationErrors'

import { OfficeHandlerContainer } from './styles'
import { useCompany } from '../../hooks/use-company'
import { Address } from '../../interfaces/address'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
}

export function OfficeHandler({ isOpen, onRequestClose }: Props) {
    // hooks
    const { createOffice } = useCompany()

    // ref
    const formRef = useRef<FormHandles>(null)
    // state
    const [busyBtn, setBusyBtn] = useState(false)

    async function handleOfficeHandler(data: Address) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                zipCode: Yup.string().required('Código postal obrigatório.'),
                country: Yup.string().required('País obrigatório'),
                state: Yup.string().required('Estado obrigatório'),
                city: Yup.string().required('Cidade obrigatória'),
                street: Yup.string().required('Logradouro obrigatório'),
                number: Yup.string().required('Número obrigatório'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            const response = await createOffice({
                ...data,
                neighborhood: 'a',
                geolocation: {
                    latitude: 0.33,
                    longitude: -0.22,
                },
            })
            if (response) {
                toast.success('Novo escritório cadastrado')
                setBusyBtn(false)
                onRequestClose()
                return
            }
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <Form ref={formRef} onSubmit={handleOfficeHandler} noValidate>
                <div className="modal-header">
                    <div className="modal-header-title">
                        <MdApartment />
                        <h1 className="f22-700-dark">Novo Escritório</h1>
                    </div>
                    <div className="modal-header-description">
                        <div className="modal-header-description--blank" />
                        <p className="f16-500-gray">
                            Crie um escritório, seus viajantes poderão usar essa{' '}
                            <br />
                            informação para realizar reservas mais próximas do
                            mesmo.
                        </p>
                    </div>
                </div>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <OfficeHandlerContainer>
                    <div className="modal-content">
                        <div className="title">
                            <h1 className="f16-500-gray-secondary">
                                Informações do escritório
                            </h1>
                        </div>

                        <div className="form">
                            {/* <Input label="Nome do escritório" name="label" /> */}
                            <div className="form--grid">
                                <Input label="Código postal" name="zipCode" />
                                <Input label="País" name="country" />
                            </div>
                            <div className="form--grid">
                                <Input label="Cidade" name="city" />
                                <Input label="Estado" name="state" />
                            </div>
                            <div className="form--grid-3">
                                <Input label="Logradouro" name="street" />
                                <Input label="Número" name="number" />
                            </div>
                            <Input label="Complemento" name="extraInfo" />
                        </div>
                    </div>
                </OfficeHandlerContainer>
                <div className="modal-confirm-btn">
                    <Button
                        color="PRIMARY"
                        buttonType="FILLED"
                        text="Criar escritório"
                        busy={busyBtn}
                        type="submit"
                    />
                </div>
            </Form>
        </ModalContainer>
    )
}
