import React from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorBoundaryContainer } from './style'
import { Button } from '../components/Button'
import ErrorImage from '../assets/error.jpg'

export function MyFallbackComponent({ error, resetErrorBoundary }: any) {
    const params = window.location.search.split('?')[1]
    const history = useHistory()

    return (
        <ErrorBoundaryContainer role="alert">
            <div>
                <h1 className="error-title">
                    Desculpe, a página não está disponível no momento.
                </h1>
                <p className="error-description">
                    Caso este erro persista, entre em contato pelo chat
                    apresentando a mensagem a baixo.
                </p>
                <div className="error-container">
                    <p>{JSON.stringify(error.message)}</p>
                    <p>{params}</p>
                </div>
                <div className="btns">
                    <Button
                        buttonType="FILLED"
                        color="PRIMARY"
                        text="Voltar para a página anterior"
                        onClick={() => {
                            resetErrorBoundary()
                            history.goBack()
                        }}
                    />
                    <Button
                        buttonType="BORDERED"
                        color="PRIMARY"
                        text="Voltar para a página inicial"
                        onClick={() => {
                            resetErrorBoundary()
                            history.replace('/home')
                        }}
                    />
                </div>
            </div>

            <div className="error-image">
                <img src={ErrorImage} alt="" />
            </div>
        </ErrorBoundaryContainer>
    )
}
