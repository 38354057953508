/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import ModalContainer from 'react-modal'
import { IoMdSearch } from 'react-icons/io'
import { BsFillXCircleFill, BsFillPlusCircleFill } from 'react-icons/bs'
import { v4 } from 'uuid'
import { toast } from 'react-toastify'
import { MdList } from 'react-icons/md'
import { AddTravalersInTripContainer, Traveler } from './styles'
import { Avatar } from '../../components/Avatar'
import { SearchInput } from '../../components/SearchInput'
import { Button } from '../../components/Button'
import { useCompany } from '../../hooks/use-company'
import { useEditMember } from '../EditMember/hook/useEditMember'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    usersAdded: {
        id: string
        label: string
        url?: string | undefined
    }[]
    action: 'APPROVES_FOR' | 'REQUESTS_FOR'
}

export function MembersToAdd({
    isOpen,
    onRequestClose,
    usersAdded,
    action,
}: Props) {
    const { members } = useCompany()
    const { membersToAddChanger } = useEditMember()
    const [searchTraveler, setSearchTraveler] = useState('')

    function elementsToAdd() {
        const data: { id: string; label: string; url?: string }[] = []
        members.forEach((m) => {
            data.push({
                id: m.id,
                label: `${m.firstName} ${m.lastName}`,
                url: m.photo?.url,
            })
        })
        return data
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button
                className="close"
                type="button"
                onClick={() => onRequestClose()}
            >
                X
            </button>
            <AddTravalersInTripContainer>
                <div className="modal-header">
                    <div className="modal-header-title">
                        <MdList />
                        <h1 className="f22-700-dark">Vincular viajante</h1>
                    </div>
                </div>
                <div className="modal-content">
                    <SearchInput
                        label="Pesquisar"
                        name="search-traveler"
                        icon={IoMdSearch}
                        value={searchTraveler}
                        onChange={(e) => setSearchTraveler(e.target.value)}
                    />
                    {!searchTraveler ? (
                        <>
                            <div className="added-travelers">
                                <h1 className="f14-700-secondary-gray added-travelers--title">
                                    VIAJANTES VINCULADOS
                                </h1>
                                {usersAdded.length > 0 ? (
                                    usersAdded.map((element) => {
                                        return (
                                            <Traveler key={v4()}>
                                                <div className="traveler-info">
                                                    <div className="traveler-info--logo">
                                                        <Avatar
                                                            label={
                                                                element.label
                                                            }
                                                            img={
                                                                element.url
                                                                    ? element.url
                                                                    : undefined
                                                            }
                                                        />
                                                    </div>
                                                    <div className="traveler-info--name">
                                                        <p className="f16-500-gray">
                                                            {element.label}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button
                                                    className="action-button remove"
                                                    type="button"
                                                    onClick={() => {
                                                        membersToAddChanger(
                                                            element,
                                                            'REMOVE',
                                                            action
                                                        )
                                                    }}
                                                >
                                                    <BsFillXCircleFill />
                                                </button>
                                            </Traveler>
                                        )
                                    })
                                ) : (
                                    <h1
                                        className="f14-700-secondary-gray added-travelers--title"
                                        style={{
                                            margin: '2rem 2rem 4rem 2rem',
                                        }}
                                    >
                                        Nenhum viajante vinculado
                                    </h1>
                                )}
                            </div>
                            <div className="add-travalers">
                                <h1 className="f14-700-secondary-gray add-travelers--title">
                                    VIAJANTES NÃO VINCULADOS
                                </h1>
                                {elementsToAdd().map((element) => {
                                    if (
                                        usersAdded.find(
                                            (listElement) =>
                                                element.id === listElement.id
                                        )
                                    ) {
                                        return null
                                    }
                                    return (
                                        <Traveler key={v4()}>
                                            <div className="traveler-info">
                                                <div className="traveler-info--logo">
                                                    <Avatar
                                                        label={element.label}
                                                        img={
                                                            element.url
                                                                ? element.url
                                                                : undefined
                                                        }
                                                    />
                                                </div>
                                                <div className="traveler-info--name">
                                                    <p className="f16-500-gray">
                                                        {element.label}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                className="action-button add"
                                                type="button"
                                                onClick={() => {
                                                    membersToAddChanger(
                                                        element,
                                                        'ADD',
                                                        action
                                                    )
                                                }}
                                            >
                                                <BsFillPlusCircleFill />
                                            </button>
                                        </Traveler>
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <div className="add-travalers">
                            <h1 className="f14-700-secondary-gray add-travelers--title">
                                RESULTADOS
                            </h1>
                            {elementsToAdd().map((element) => {
                                if (
                                    usersAdded.find(
                                        (elementList) =>
                                            element.id === elementList.id
                                    )
                                ) {
                                    return null
                                }
                                if (
                                    element.label
                                        .toLowerCase()
                                        .includes(searchTraveler.toLowerCase())
                                ) {
                                    return (
                                        <Traveler key={v4()}>
                                            <div className="traveler-info">
                                                <div className="traveler-info--logo">
                                                    <Avatar
                                                        label={element.label}
                                                        img={
                                                            element.url
                                                                ? element.url
                                                                : undefined
                                                        }
                                                    />
                                                </div>
                                                <div className="traveler-info--name">
                                                    <p className="f16-500-gray">
                                                        {element.label}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                className="action-button add"
                                                type="button"
                                                onClick={() => {
                                                    membersToAddChanger(
                                                        element,
                                                        'ADD',
                                                        action
                                                    )
                                                }}
                                            >
                                                <BsFillPlusCircleFill />
                                            </button>
                                        </Traveler>
                                    )
                                }
                                return null
                            })}
                        </div>
                    )}
                </div>
                <div className="save-btn">
                    <Button
                        buttonType="FILLED"
                        text="Salvar"
                        color="PRIMARY"
                        type="button"
                        onClick={() => {
                            onRequestClose()
                        }}
                    />
                </div>
            </AddTravalersInTripContainer>
        </ModalContainer>
    )
}
