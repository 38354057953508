/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import ModalContainer from 'react-modal'
import { GiShieldDisabled } from 'react-icons/gi'
import { ExpensesModalContainer } from './styles'
import { expensesDateFormatter } from '../../utils/expensnes/dateFormatter'
import { expenseTypeIcon } from '../../utils/expensnes/ExpensesIconsHandler'
import { priceFormatter } from '../../utils/expensnes/priceFormatter'
import { AdvanceMoney } from '../../interfaces/advanced-money'
import { useExpenses } from '../../hooks/use-expenses'
import { advanceMoneyOutOfPolicyHandler } from '../../utils/expensnes/expenseOutOfPolicyHandler'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    advanceMoney: AdvanceMoney
}

export default function AdvanceMoneyModal({
    isOpen,
    onRequestClose,
    advanceMoney,
}: Props) {
    const { openHistoryModal, openUseHistoryModal, openApprovalStagesModal } =
        useExpenses()
    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-expenses"
        >
            <ExpensesModalContainer>
                <div className="header">
                    <div className="description">
                        <div className="image">
                            <img
                                src={expenseTypeIcon('ADVANCED_MONEY')}
                                alt="expense-image"
                            />
                        </div>
                        <div className="observations">
                            <h3 className="f22-700-dark">{`Adiantamento #${
                                advanceMoney?.numberIdentifier || ''
                            }`}</h3>

                            <p className="f14-500-gray">
                                {advanceMoney.createdAt &&
                                    `${expensesDateFormatter(
                                        new Date(
                                            advanceMoney.createdAt
                                        ).toDateString()
                                    )}`}
                            </p>
                            <button
                                type="button"
                                className="history-btn"
                                onClick={() =>
                                    openHistoryModal(advanceMoney.history)
                                }
                            >
                                Ver histórico
                            </button>
                            <div>
                                <button
                                    type="button"
                                    className="history-btn"
                                    onClick={() =>
                                        openApprovalStagesModal(
                                            advanceMoney.approvalStages
                                        )
                                    }
                                >
                                    Ver estágios aprovação
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="price-date">
                        <div className="out-of-policy">
                            {advanceMoneyOutOfPolicyHandler(
                                advanceMoney.travelerClearanceEvaluation
                            ).isOutOfPolicy ? (
                                <GiShieldDisabled />
                            ) : (
                                <div />
                            )}
                        </div>
                        <h3 className="f22-700-dark">
                            {`R$${priceFormatter(advanceMoney.amount || 0)}`}
                        </h3>
                    </div>
                </div>

                <div className="content">
                    <div className="content-block">
                        <p className="f16-500-gray italic">
                            Informações gerenciais
                        </p>
                        <div className="container">
                            <div className="label-value">
                                <p className="f16-500-gray">Viagem</p>
                                <div className="value">
                                    <p className="f16-500-dark">
                                        {`${
                                            advanceMoney.trip?.numberIdentifier
                                                ? `#${advanceMoney.trip.numberIdentifier}`
                                                : ''
                                        } ${advanceMoney.trip?.label || '-'}`}
                                    </p>
                                </div>
                            </div>
                            <div className="label-value">
                                <p className="f16-500-gray">Participantes</p>
                                <div className="value">
                                    <p className="f16-500-dark">
                                        {`${advanceMoney.traveler.firstName} ${advanceMoney.traveler.lastName}`}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-block">
                        <p className="f16-500-gray italic">
                            Informações sobre o adiantamento
                        </p>
                        <div className="container">
                            <div className="label-value">
                                <p className="f16-500-gray">Adiantamento</p>
                                <div className="value">
                                    <p className="f16-500-dark">-</p>
                                </div>
                            </div>
                            <div className="label-value">
                                <p className="f16-500-gray">Data</p>
                                <div className="value">
                                    <p className="f16-500-dark">
                                        {expensesDateFormatter(
                                            new Date(
                                                advanceMoney.createdAt
                                            ).toDateString()
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="label-value">
                                <p className="f16-500-gray">
                                    Valor inicial do adiantamento
                                </p>
                                <div className="value">
                                    <p className="f16-500-dark">{`R$${priceFormatter(
                                        advanceMoney.amount || 0
                                    )}`}</p>
                                </div>
                            </div>
                            {/* <div className="label-value">
                                <p className="f16-500-gray">
                                    Valor atual do adiantamento
                                </p>
                                <div className="value">
                                    <p className="f16-500-dark">{`R$${priceFormatter(
                                        advanceMoney.currentAmount
                                    )}`}</p>
                                </div>
                            </div>
                            <div className="label-value">
                                <p className="f16-500-gray">
                                    Valor devolvido do adiantamento
                                </p>
                                <div className="value">
                                    <p className="f16-500-dark">{`R$${priceFormatter(
                                        advanceMoney.amount -
                                            advanceMoney.currentAmount
                                    )}`}</p>
                                </div>
                            </div> */}
                            {advanceMoney.expenseHistory && (
                                <div className="label-value">
                                    <p className="f16-500-gray">
                                        Histórico de uso
                                    </p>
                                    <div className="value">
                                        <button
                                            type="button"
                                            className="history-btn"
                                            onClick={() =>
                                                openUseHistoryModal(
                                                    advanceMoney
                                                )
                                            }
                                        >
                                            Ver aqui
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {advanceMoneyOutOfPolicyHandler(
                    advanceMoney.travelerClearanceEvaluation
                ).isOutOfPolicy && (
                    <div className="comments">
                        <p className="f16-500-gray">
                            Quebra de política nos seguintes tópicos:
                        </p>
                        <div className="out-of-policy">
                            {advanceMoneyOutOfPolicyHandler(
                                advanceMoney.travelerClearanceEvaluation
                            ).messages.map((message, index) => (
                                <li
                                    className="out-of-policy-message"
                                    key={index}
                                >
                                    {message}
                                </li>
                            ))}
                        </div>
                    </div>
                )}
                <div className="comments" style={{ marginBottom: '1.5rem' }}>
                    <p className="f16-500-gray">Informações gerenciais:</p>
                    <div className="management-info">
                        {advanceMoney.managementInfo &&
                        advanceMoney.managementInfo.length > 0 ? (
                            advanceMoney.managementInfo.map(
                                (management, index) => (
                                    <div className="label-value-m">
                                        <p className="f16-500-dark">
                                            {management.label}
                                        </p>
                                        <div className="value">
                                            <p
                                                className="f14-500-gray"
                                                key={index}
                                            >
                                                {management.options.map(
                                                    (option) => option.label
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                )
                            )
                        ) : (
                            <p className="f14-500-gray">
                                Debug Sem informações gerencias vinculadas
                            </p>
                        )}
                    </div>
                </div>
                <div className="comments">
                    <p className="f16-500-gray">Descrição</p>
                    <div className="description">
                        <p className="f16-500-gray">
                            {advanceMoney.observations || 'Sem observações'}
                        </p>
                    </div>
                </div>
            </ExpensesModalContainer>
        </ModalContainer>
    )
}
