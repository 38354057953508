/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from '../../../../components/Button'
import { SelectInput } from '../../../../components/SelectInput'
import { SingleCreditCardContainer } from './styles'
import { PaymentMethod } from '../../../../entities/payment-method'
import { usePayment } from '../../../../hooks/use-payment'
import { chooseImagePaymentMethod } from '../../../../utils/paymentFunctions'
import { ControlButton } from '../../../../components/ControlButton'
import { useCompany } from '../../../../hooks/use-company'
import { PaymentMethodType } from '../../../../enums/payment-method-type.enum'

export interface Props {
    paymentMethod: PaymentMethod
}

export function SingleCreditCard({ paymentMethod }: Props) {
    const { company } = useCompany()
    const { choosePaymentMethodToEdit, editPaymentMethod, paymentMethods } =
        usePayment()

    const [flight, setFlight] = useState<{ label: string; value: string }>(
        () => {
            if (paymentMethod.companyInfo.productTypes.includes('FLIGHT')) {
                return { label: 'Habilitado', value: 'FLIGHT' }
            }
            return { label: 'Desabilitado', value: 'FALSE' }
        }
    )
    const [hotel, setHotel] = useState<{ label: string; value: string }>(() => {
        if (paymentMethod.companyInfo.productTypes.includes('HOTEL')) {
            return { label: 'Habilitado', value: 'HOTEL' }
        }
        return { label: 'Desabilitado', value: 'FALSE' }
    })

    const [car, setCar] = useState<{
        label: string
        value: string
    }>(() => {
        if (paymentMethod.companyInfo.productTypes.includes('CAR')) {
            return { label: 'Habilitado', value: 'CAR' }
        }
        return { label: 'Desabilitado', value: 'FALSE' }
    })

    const [isActive, setIsActive] = useState<boolean>(paymentMethod.isActive)
    const [doRequest, setDoRequest] = useState(false)

    function isTypeAllowed() {
        let isAllowed = false
        if (company) {
            if (
                paymentMethod.type === PaymentMethodType.CREDIT_CARD &&
                company.enabledPaymentMethods.creditCard
            ) {
                isAllowed = true
            }
            if (
                paymentMethod.type === PaymentMethodType.INVOICE &&
                company.enabledPaymentMethods.invoice
            ) {
                isAllowed = true
            }
        }
        return isAllowed
        return true
    }

    useEffect(() => {
        setFlight(() => {
            if (paymentMethod.companyInfo.productTypes.includes('FLIGHT')) {
                return { label: 'Habilitado', value: 'FLIGHT' }
            }
            return { label: 'Desabilitado', value: 'FALSE' }
        })
        setHotel(() => {
            if (paymentMethod.companyInfo.productTypes.includes('HOTEL')) {
                return { label: 'Habilitado', value: 'HOTEL' }
            }
            return { label: 'Desabilitado', value: 'FALSE' }
        })
        setCar(() => {
            if (paymentMethod.companyInfo.productTypes.includes('CAR')) {
                return { label: 'Habilitado', value: 'CAR' }
            }
            return { label: 'Desabilitado', value: 'FALSE' }
        })
    }, [paymentMethods])

    useEffect(() => {
        ;(async () => {
            if (doRequest) {
                const products = [hotel.value, car.value, flight.value].filter(
                    (p) => p !== 'FALSE'
                )
                const response = await editPaymentMethod(
                    {
                        companyInfo: {
                            productTypes: products as [
                                'CAR' | 'HOTEL' | 'FLIGHT' | 'BUS'
                            ],
                        },
                        isActive,
                    },
                    paymentMethod.id
                )
                if (response) {
                    toast.success('Forma de pagamento editada com sucesso')
                }
            }
        })()
        setDoRequest(false)
    }, [car, hotel, flight, doRequest])

    return (
        <SingleCreditCardContainer>
            <div className="item">
                {paymentMethod.type === PaymentMethodType.CREDIT_CARD ? (
                    <img
                        src={chooseImagePaymentMethod(
                            paymentMethod.creditCard?.cardBrand
                        )}
                        alt={paymentMethod.creditCard?.cardBrand}
                    />
                ) : (
                    <img
                        src={chooseImagePaymentMethod('INVOICE')}
                        alt="INVOICE"
                    />
                )}
                {paymentMethod.type === PaymentMethodType.CREDIT_CARD ? (
                    <div className="description">
                        <h1>{`${paymentMethod.creditCard?.cardBrand} final ${paymentMethod.creditCard?.lastFourDigits}`}</h1>
                        <p>{paymentMethod.label}</p>
                    </div>
                ) : (
                    <div className="description">
                        <h1>Faturado</h1>
                    </div>
                )}
            </div>
            <div className="item">
                <h1 className="expiry">
                    {paymentMethod.creditCard?.expirationDate || '-'}
                </h1>
            </div>
            <div className="item">
                <div className="select-div">
                    <SelectInput
                        name="a"
                        value={flight}
                        options={[
                            { label: 'Habilitado', value: 'FLIGHT' },
                            { label: 'Desabilitado', value: 'FALSE' },
                        ]}
                        onChange={(e) => {
                            if (!isTypeAllowed())
                                return toast.warning(
                                    'Sua empresa não possuí esta forma de pagamento habilitada'
                                )
                            if (e) {
                                setFlight({ label: e.label, value: e.value })
                            }
                            setDoRequest(true)
                        }}
                    />
                </div>
            </div>
            <div className="item">
                <div className="select-div">
                    <SelectInput
                        name="a"
                        value={hotel}
                        options={[
                            { label: 'Habilitado', value: 'HOTEL' },
                            { label: 'Desabilitado', value: 'FALSE' },
                        ]}
                        onChange={(e) => {
                            if (!isTypeAllowed())
                                return toast.warning(
                                    'Sua empresa não possuí esta forma de pagamento habilitada'
                                )
                            if (e) {
                                setHotel({ label: e.label, value: e.value })
                            }
                            setDoRequest(true)
                        }}
                    />
                </div>
            </div>
            <div className="item">
                <div className="select-div">
                    <SelectInput
                        value={car}
                        name="a"
                        options={[
                            { label: 'Habilitado', value: 'CAR' },
                            { label: 'Desabilitado', value: 'FALSE' },
                        ]}
                        onChange={(e) => {
                            if (!isTypeAllowed())
                                return toast.warning(
                                    'Sua empresa não possuí esta forma de pagamento habilitada'
                                )
                            if (e) {
                                setCar({ label: e.label, value: e.value })
                            }
                            setDoRequest(true)
                        }}
                    />
                </div>
            </div>
            <div className="item">
                <div className="item-actions">
                    <div className="btn">
                        <ControlButton
                            id={paymentMethod.id}
                            isPressed={isTypeAllowed() && isActive}
                            type="SWITCH"
                            callback={() => {
                                if (!isTypeAllowed())
                                    return toast.warning(
                                        'Sua empresa não possuí esta forma de pagamento habilitada'
                                    )
                                setIsActive(!isActive)
                                setDoRequest(true)
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="item-actions">
                    <div className="btn">
                        <Button
                            buttonType="TEXT"
                            color="PRIMARY"
                            type="button"
                            text="Editar"
                            onClick={() => {
                                if (!isTypeAllowed())
                                    return toast.warning(
                                        'Sua empresa não possuí esta forma de pagamento habilitada'
                                    )
                                choosePaymentMethodToEdit(paymentMethod)
                            }}
                        />
                    </div>
                    {/* <div className="btn">
                        <Button
                            buttonType="TEXT"
                            color="PRIMARY"
                            type="button"
                            text="Inativar"
                        />
                    </div> */}
                </div>
            </div>
        </SingleCreditCardContainer>
    )
}
