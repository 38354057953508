import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { BarChartContainer } from './styles'

ChartJS.register(
    ChartDataLabels,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

interface Props {
    tripSpendings: {
        label: string
        value: number
    }[]
    expenseSpendings: {
        label: string
        value: number
    }[]
}

export function BarChart({ expenseSpendings, tripSpendings }: Props) {
    const labels = tripSpendings.map((d) => d.label)
    const data = {
        labels,
        datasets: [
            {
                label: 'Viagens',
                data: tripSpendings.map((d) => d.value),
                borderColor: '#072330',
                backgroundColor: '#072330',
                stack: 'Stack 0',
            },
            {
                label: 'Despesas',
                data: expenseSpendings.map((d) => d.value),
                borderColor: '#00ABF2',
                backgroundColor: '#00ABF2',
                stack: 'Stack 0',
            },
        ],
    }
    const options = {
        responsive: true,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top' as const,
                display: false,
            },

            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
            datalabels: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label(context: any) {
                        let label = context.dataset.label || ''

                        if (label) {
                            label += ': '
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                            }).format(context.parsed.y)
                        }
                        return label
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    color: 'white',
                    borderColor: '#96989B',
                },
                stacked: true,
            },
            y: {
                grid: {
                    color: 'white',
                    borderColor: 'white',
                },
                stacked: true,
                ticks: {
                    display: false,
                    beginAtZero: false,
                    min: 0,
                    stepSize: 5,
                    fontColor: '#ABACB3',
                },
            },
        },
    }
    return (
        <BarChartContainer>
            <Bar data={data} options={options} />
        </BarChartContainer>
    )
}
