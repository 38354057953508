import styled, { css } from 'styled-components'

interface PropsRoadMapButton {
    isActive: boolean
}

export const SideMenuBtn = styled.button<PropsRoadMapButton>`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
    background-color: var(--color-light);
    svg {
        margin-right: 1rem;
        color: #c8cacd;
    }
    h2 {
        color: #96989b;
        font-size: 1.6rem;
        font-weight: 500;
    }
    border: none;
    height: 6.5rem;
    transition: all 0.4s ease;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 4px;
        height: 6.5rem;
        border-radius: 0px 999px 999px 0px;
        background-color: inherit;
    }
    &:hover {
        background-color: var(--color-table);
        svg {
            color: var(--color-primary);
        }
        h2 {
            color: #191b1e;
        }
        &::before {
            background-color: var(--color-primary);
        }
    }
    ${(props) => {
        if (props.isActive) {
            return css`
                background-color: var(--color-table);
                svg {
                    color: var(--color-primary);
                }
                h2 {
                    color: #191b1e;
                }
                &::before {
                    background-color: var(--color-primary);
                }
            `
        }
        return null
    }}
`

export const SideMenuContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 3rem;
    background-color: var(--color-light);
    border-radius: 0.8rem;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    margin-bottom: 1rem;
    .scroll {
        max-height: 50vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 0.5rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 0.5rem;
            background: #edeff2;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            border-radius: 0.5rem;
            background: #c8cacd;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #c8cacd;
        }
    }
    .title {
        padding: 1.4rem;
    }
`
