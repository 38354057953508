/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { MdArrowDropDown, MdPeople } from 'react-icons/md'
import ModalContainer from 'react-modal'
import { toast } from 'react-toastify'
import { Button } from '../../components/Button'
import { Input } from '../../UnformInputs/Input'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { Accordion, PolicieContainer } from './styles'
import { useExpenses } from '../../hooks/use-expenses'
import {
    ExpenseCategoryPolicy,
    ExpensesCategory,
} from '../../entities/expenses-category'
import { SinglePolicieContainer } from '../../pages/Politics/styles'
import { TitleDescription } from '../../components/TitleDescription'
import { ControlButton } from '../../components/ControlButton'
import { SimpleInput } from '../../components/SimpleInput'
import { ExpenseCategoryPolicyC } from '../ExpenseCategoryPolicy'

interface Props {
    isOpen: boolean
    expenseCategory?: ExpensesCategory
    onRequestClose: () => void
}

export function ExpenseCategoryHandler({
    isOpen,
    onRequestClose,
    expenseCategory,
}: Props) {
    // hooks
    const {
        createExpenseCategories,
        editExpenseCategories,
        changeExpenseCategory,
        openEditExpenseCategory,
    } = useExpenses()

    // ref
    const formRef = useRef<FormHandles>(null)
    // state
    const [busyBtn, setBusyBtn] = useState(false)

    const [oppenedAccordion, setOppenedAccordion] = useState<string>()
    const [openPolicyModal, setOpenPolicyModal] = useState(false)

    const setPolicyEnabled = (
        travelerClearanceId: string,
        enabled: boolean
    ) => {
        // @ts-ignore
        changeExpenseCategory((prevCategory: any) => {
            // Check if the previous state exists
            if (!prevCategory) return null

            // Clone the previous state to avoid direct mutations
            const newCategory = { ...prevCategory }

            // If policies exist, update them
            if (newCategory.policies) {
                const policyIndex = newCategory.policies.findIndex(
                    (policy: any) =>
                        policy.travelerClearanceId === travelerClearanceId
                )

                if (policyIndex !== undefined) {
                    // Make sure a matching policy was found
                    newCategory.policies[policyIndex].enabled = enabled
                }
            }

            return newCategory
        })
    }

    const setMaxAmountPerDay = (travelerClearanceId: string, data: string) => {
        // @ts-ignore
        changeExpenseCategory((prevCategory: any) => {
            // Check if the previous state exists
            if (!prevCategory) return null

            // Clone the previous state to avoid direct mutations
            const newCategory = { ...prevCategory }

            // If policies exist, update them
            if (newCategory.policies) {
                const policyIndex = newCategory.policies.findIndex(
                    (policy: any) =>
                        policy.travelerClearanceId === travelerClearanceId
                )

                if (policyIndex !== undefined) {
                    // Make sure a matching policy was found
                    newCategory.policies[policyIndex].maxAmountPerDay = data
                }
            }

            return newCategory
        })
    }
    const setMaxItemsPerDay = (travelerClearanceId: string, data: string) => {
        // @ts-ignore
        changeExpenseCategory((prevCategory: any) => {
            // Check if the previous state exists
            if (!prevCategory) return null

            // Clone the previous state to avoid direct mutations
            const newCategory = { ...prevCategory }

            // If policies exist, update them
            if (newCategory.policies) {
                const policyIndex = newCategory.policies.findIndex(
                    (policy: any) =>
                        policy.travelerClearanceId === travelerClearanceId
                )

                if (policyIndex !== undefined) {
                    // Make sure a matching policy was found
                    newCategory.policies[policyIndex].maxItemsPerDay = data
                }
            }

            return newCategory
        })
    }

    const setMaxAmountPerItem = (travelerClearanceId: string, data: string) => {
        // @ts-ignore
        changeExpenseCategory((prevCategory: ExpensesCategory) => {
            // Check if the previous state exists
            if (!prevCategory) return undefined

            // Clone the previous state to avoid direct mutations
            const newCategory = { ...prevCategory }

            // If policies exist, update them
            if (newCategory.policies) {
                const policyIndex = newCategory.policies.findIndex(
                    (policy: any) =>
                        policy.travelerClearanceId === travelerClearanceId
                )

                if (policyIndex !== undefined) {
                    // Make sure a matching policy was found
                    newCategory.policies[policyIndex].maxAmountPerItem = data
                }
            }

            return newCategory
        })
    }

    async function handleCategory(data: { label: string }) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})

            const schema = Yup.object().shape({
                label: Yup.string().required('Insira um nome válido'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            if (expenseCategory) {
                const response = await editExpenseCategories({
                    id: expenseCategory.id,
                    label: data.label,
                    policies: openEditExpenseCategory?.policies,
                })
                if (response) {
                    toast.success(`Categoria ${data.label} editada com sucesso`)
                }
            } else {
                const response = await createExpenseCategories(data.label)
                if (response) {
                    toast.success(`Categoria ${data.label} criada com sucesso`)
                }
            }
            setBusyBtn(false)
            onRequestClose()
        } catch (error) {
            setBusyBtn(false)
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    function addNewPolicy(data: ExpenseCategoryPolicy) {
        const policies = openEditExpenseCategory?.policies || []
        changeExpenseCategory({
            ...openEditExpenseCategory!,
            policies: [...policies, data],
        })
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-category"
        >
            <Form ref={formRef} onSubmit={handleCategory} noValidate>
                <div className="modal-header">
                    <div className="modal-header-title">
                        <MdPeople />
                        <h1 className="f22-700-dark">{`${
                            expenseCategory
                                ? 'Editar categoria'
                                : 'Criar nova categoria'
                        }`}</h1>
                    </div>
                    <div className="modal-header-description">
                        <div className="modal-header-description--blank" />
                        <p className="f16-500-gray" />
                    </div>
                </div>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <PolicieContainer>
                    <div className="modal-content">
                        <div className="title">
                            <h1 className="f16-500-gray-secondary">
                                Categoria de despesa
                            </h1>
                        </div>

                        {openEditExpenseCategory ? (
                            <div className="form">
                                <Input
                                    label="Nome da categoria"
                                    name="label"
                                    value={openEditExpenseCategory?.label || ''}
                                    onChange={(e) =>
                                        changeExpenseCategory({
                                            ...openEditExpenseCategory,
                                            label: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        ) : (
                            <div className="form">
                                <Input label="Nome da categoria" name="label" />
                            </div>
                        )}
                    </div>
                    {expenseCategory && (
                        <div className="modal-content">
                            <div className="title">
                                <h1 className="f16-500-gray-secondary">
                                    Políticas da despesa
                                </h1>
                                <button
                                    className="add-new-policy"
                                    type="button"
                                    onClick={() => setOpenPolicyModal(true)}
                                >
                                    + Adicionar nova política
                                </button>
                            </div>
                            {expenseCategory?.policies?.length === 0 ||
                                (!expenseCategory.policies && (
                                    <p className="f16-500-gray">
                                        Sem políticas cadastradas
                                    </p>
                                ))}
                            {expenseCategory?.policies?.map((policy) => (
                                <Accordion key={policy.travelerClearance.id}>
                                    <button
                                        className="accordion-header"
                                        type="button"
                                        onClick={() => {
                                            if (
                                                oppenedAccordion ===
                                                policy.travelerClearanceId
                                            ) {
                                                return setOppenedAccordion('')
                                            }
                                            return setOppenedAccordion(
                                                policy.travelerClearanceId
                                            )
                                        }}
                                    >
                                        {policy.travelerClearance.label}
                                        <MdArrowDropDown />
                                    </button>
                                    <div
                                        className={`hide-content ${
                                            oppenedAccordion ===
                                            policy.travelerClearanceId
                                                ? 'show-content'
                                                : 'hide-content'
                                        }`}
                                    >
                                        <SinglePolicieContainer>
                                            <TitleDescription title="Permitir fazer solicitações?" />
                                            <div className="inputs">
                                                <ControlButton
                                                    id={`${policy.travelerClearanceId}.productEnabled`}
                                                    type="SWITCH"
                                                    isPressed={policy.enabled}
                                                    callback={() => {
                                                        setPolicyEnabled(
                                                            policy.travelerClearanceId,
                                                            !policy.enabled
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </SinglePolicieContainer>
                                        <SinglePolicieContainer>
                                            <TitleDescription
                                                title="Valor máximo permitido por dia"
                                                description="Defina um valor máximo permitido por dia para despesas dessa categoria."
                                            />
                                            <div className="inputs">
                                                <SimpleInput
                                                    label=""
                                                    name="a"
                                                    isPricing
                                                    value={
                                                        policy.maxAmountPerDay
                                                    }
                                                    onChange={(e) => {
                                                        if (
                                                            !/^\d*\.?\d*$/.test(
                                                                e.target.value
                                                            )
                                                        )
                                                            return
                                                        setMaxAmountPerDay(
                                                            policy.travelerClearanceId,
                                                            e.target.value
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </SinglePolicieContainer>
                                        <SinglePolicieContainer>
                                            <TitleDescription
                                                title="Valor máximo permitido por despesa"
                                                description="Defina um valor máximo permitido para despesas dessa categoria."
                                            />
                                            <div className="inputs">
                                                <SimpleInput
                                                    label=""
                                                    name="a"
                                                    isPricing
                                                    value={
                                                        policy.maxAmountPerItem
                                                    }
                                                    onChange={(e) => {
                                                        if (
                                                            !/^\d*\.?\d*$/.test(
                                                                e.target.value
                                                            )
                                                        )
                                                            return
                                                        setMaxAmountPerItem(
                                                            policy.travelerClearanceId,
                                                            e.target.value
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </SinglePolicieContainer>
                                        <SinglePolicieContainer>
                                            <TitleDescription
                                                title="Quantidade máxima de solicitações por dia"
                                                description="Defina um valor máximo de solicitações por dia para essa despesa."
                                            />
                                            <div className="inputs">
                                                <SimpleInput
                                                    label=""
                                                    name="a"
                                                    value={
                                                        policy.maxItemsPerDay
                                                    }
                                                    onChange={(e) => {
                                                        if (
                                                            !/^\d*\.?\d*$/.test(
                                                                e.target.value
                                                            )
                                                        )
                                                            return
                                                        setMaxItemsPerDay(
                                                            policy.travelerClearanceId,
                                                            e.target.value
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </SinglePolicieContainer>
                                    </div>
                                </Accordion>
                            ))}
                        </div>
                    )}
                </PolicieContainer>
                <div className="modal-confirm-btn">
                    <Button
                        color="PRIMARY"
                        buttonType="FILLED"
                        text={
                            expenseCategory
                                ? 'Editar categoria'
                                : 'Criar categoria'
                        }
                        busy={busyBtn}
                        type="submit"
                    />
                </div>
            </Form>
            {openPolicyModal && (
                <ExpenseCategoryPolicyC
                    isOpen
                    onRequestClose={() => setOpenPolicyModal(false)}
                    addNewPolicy={addNewPolicy}
                    currentPoliciesIds={
                        expenseCategory?.policies?.map(
                            (p) => p.travelerClearanceId
                        ) ?? []
                    }
                />
            )}
        </ModalContainer>
    )
}
