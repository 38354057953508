import styled from 'styled-components'

export const OutOfPolicyTableContainer = styled.div`
    background-color: #edeff2;
    padding: 2.5rem 0 2.5rem 2.5rem;
    border-radius: 0.8rem;
    height: 100%;
    .table-title {
        padding-right: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1.5px solid #dedede;
        button {
            min-width: 1.2rem;
            .label {
                padding: 1rem;
                .tab-label {
                    font-size: 1.4rem;
                }
            }
        }
    }
    .table-header {
        padding-right: 2.5rem;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        align-items: center;
        margin: 2.5rem 0 1rem 0;
        .center {
            width: 100%;
            text-align: center;
        }
    }
    .tabe-content {
        height: 33rem;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 0.5rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 0.5rem;
            background: #edeff2;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            border-radius: 0.5rem;
            background: #c8cacd;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #c8cacd;
        }
        .table-row {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr 1fr;
            margin-right: 2rem;
            .center {
                width: 100%;
                text-align: center;
            }
            padding: 1.5rem 0;
            border-bottom: 1.5px solid #dedede;
        }
    }
`
