/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { MdCreditCard } from 'react-icons/md'
import { Button } from '../../../components/Button'
import {
    PaymentMethodFilter,
    PaymentMethodsSubPageContainer,
    PaymentMethodsSubPageContent,
} from './styles'

import { Table } from '../../../utils/table'
import { SingleCreditCard } from './SingleCreditCard'
import { usePayment } from '../../../hooks/use-payment'
import { PaymentMethodHandler } from '../../../modals/PaymentMethodHandler'
import { AddList } from '../../../modals/AddList'
import { useCompany } from '../../../hooks/use-company'
import { PaymentFilter } from '../../../components/PaymentFilter'

export function PaymentMethodsSubPage() {
    // hooks
    const {
        getPaymentMethods,
        paymentMethods,
        choosePaymentMethodToEdit,
        paymentMehodToEdit,
        elementToAddSettings,
        elementToAddSettingsHandler,
        busy,
        paymentFilter,
    } = usePayment()

    const { getMembers, company, members, getManagementInfo, managementInfo } =
        useCompany()
    // state
    const [openPaymentMethodHandler, setOpenPaymentMethodHandler] =
        useState(false)

    useEffect(() => {
        getPaymentMethods()
        if (company) {
            getMembers(company.id)
            getManagementInfo()
        }
    }, [])

    useEffect(() => {
        getPaymentMethods()
    }, [paymentFilter])

    return (
        <PaymentMethodsSubPageContainer>
            <div className="payment-method-info">
                <div className="card">
                    <MdCreditCard />
                    <div className="description">
                        <h1>Formas de pagamento</h1>
                    </div>
                </div>
            </div>
            <PaymentMethodFilter>
                <Button
                    buttonType="FILLED"
                    color="PRIMARY"
                    text="Adicionar novo cartão"
                    onClick={() => setOpenPaymentMethodHandler(true)}
                />
            </PaymentMethodFilter>
            <div>
                <PaymentFilter
                    managementInfos={managementInfo}
                    members={members}
                />
            </div>
            <PaymentMethodFilter />
            <PaymentMethodsSubPageContent>
                {!busy ? (
                    <Table>
                        <div
                            className="table-header"
                            style={{
                                gridTemplateColumns:
                                    ' 3fr 1fr 2fr 2fr 2fr 1fr 1fr',
                            }}
                        >
                            <h1 className="start">Formas de pagamento</h1>
                            <h1 className="center">Expiração</h1>
                            <h1 className="center">Aéreo</h1>
                            <h1 className="center">Hotel</h1>
                            <h1 className="center">Locação de carro</h1>
                            <h1 className="center">Ativo</h1>
                            <h1 className="center">Ações</h1>
                        </div>
                        {paymentMethods.map((paymentMethod) => {
                            return (
                                <SingleCreditCard
                                    key={paymentMethod.id}
                                    paymentMethod={paymentMethod}
                                />
                            )
                        })}
                    </Table>
                ) : (
                    <div />
                )}
            </PaymentMethodsSubPageContent>
            <PaymentMethodHandler
                isOpen={openPaymentMethodHandler}
                onRequestClose={() => setOpenPaymentMethodHandler(false)}
            />
            {paymentMehodToEdit && (
                <PaymentMethodHandler
                    isOpen={!!paymentMehodToEdit}
                    onRequestClose={() => choosePaymentMethodToEdit(undefined)}
                    paymentMethod={paymentMehodToEdit}
                />
            )}
            {elementToAddSettings?.type !== 'CLOSED' && (
                <AddList
                    isOpen={elementToAddSettings.type !== 'CLOSED'}
                    onRequestClose={() => {
                        elementToAddSettingsHandler({
                            list: [],
                            listToAdd: [],
                            type: 'CLOSED',
                            id: '',
                        })
                    }}
                    paymentMethod={elementToAddSettings.paymentMethod}
                    id={elementToAddSettings.id}
                    type={elementToAddSettings.type}
                    list={elementToAddSettings.list}
                    listToAdd={elementToAddSettings.listToAdd}
                />
            )}
        </PaymentMethodsSubPageContainer>
    )
}
