import styled from 'styled-components'

export const BrokenPoliciesReasonContainer = styled.div`
    height: 38rem;
    overflow-y: scroll;
    padding: 1rem;
    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 0.5rem;
        background: #edeff2;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background: #c8cacd;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #c8cacd;
    }
    width: 100%;
    margin-top: 2rem;
    .broken-title {
        margin-bottom: 2rem;
    }
    .label-values-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2rem;
        border-bottom: 1px solid #f4f4f4;
        margin-bottom: 1rem;
        .values {
            display: flex;
            align-items: center;
            .divider {
                height: 2rem;
                width: 1px;
                background-color: #edeff2;
                margin: 0 1rem;
            }
        }
    }
    .progressive-bar-container {
        width: 100%;
        margin-bottom: 3rem;
        .label-values {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;
            .values {
                display: flex;
                align-items: center;
                .divider {
                    height: 2rem;
                    width: 1px;
                    background-color: #edeff2;
                    margin: 0 1rem;
                }
            }
        }
        .progressive-bar {
            width: 100%;
            height: 1rem;
            background-color: #edeff2;
            margin-right: 1.2rem;
            position: relative;
            border-radius: 1px;
            .filled {
                position: absolute;
                left: 0;
                height: 1rem;
                background-color: #db614e;
                border-radius: 1px;
            }
        }
    }
`
