/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react'
import { FinancialContainer } from './styles'
import { Header } from '../../components/Header'
import { PillTabs } from '../../components/PillTabs'
import Expenses from './Expenses'
import AdvanceMoney from './AdvanceMoney'
import { ExpensesProvider } from '../../hooks/use-expenses'

export default function FinancialPage() {
    const [selectedSubMenu, setSelectedSubMenu] = useState<
        'EXPENSES' | 'ADVANCE_MONEY'
    >('EXPENSES')

    function changeSubMenu(type: 'EXPENSES' | 'ADVANCE_MONEY') {
        setSelectedSubMenu(type)
    }

    return (
        <FinancialContainer>
            <Header header="EXPENSES" />
            <ExpensesProvider>
                <div className="sub-menu-container">
                    <div className="sub-menu">
                        <PillTabs
                            changeTab={changeSubMenu}
                            value={selectedSubMenu}
                            theme="LIGHT"
                            pillTabs={[
                                { id: 'EXPENSES', label: 'Despesas' },
                                {
                                    id: 'ADVANCE_MONEY',
                                    label: 'Adiantamento',
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="financial-content">
                    {selectedSubMenu === 'EXPENSES' && <Expenses />}
                    {selectedSubMenu === 'ADVANCE_MONEY' && <AdvanceMoney />}
                </div>
            </ExpensesProvider>
        </FinancialContainer>
    )
}
