import styled from 'styled-components'

export const SingleConfigurationManagementContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1.3fr 3fr 2fr 1.5fr 1fr;
    &:nth-child(2n + 2) {
        background: #fff;
    }
    .item {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        &:not(:last-child) {
            border-right: 2px solid #c8cacd;
        }
        .item-label {
            padding: 2rem;
            font-size: 1.6rem;
            color: #191b1e;
            font-weight: 700;
        }
        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.5rem;
        }
    }
`
