'use client'

/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import ModalContainer from 'react-modal'
import { ExpensesModalContainer } from './styles'
import { AdvanceMoney } from '../../interfaces/advanced-money'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    approvalStages: AdvanceMoney['approvalStages']
}

export default function AdvanceMoneyApprovalStagesModal({
    isOpen,
    onRequestClose,
    approvalStages,
}: Props) {
    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-history"
        >
            <ExpensesModalContainer>
                <div className="content-block">
                    <p className="f16-500-gray italic">Estágios de aprovação</p>
                    <div className="container">
                        {approvalStages?.map((item, index) => {
                            return (
                                <div className="label-value" key={index}>
                                    <p className="f16-500-gray">
                                        {item.approved
                                            ? `Aprovado no nível ${item.level} por ${item.approver.firstName} ${item.approver.lastName}`
                                            : `Não aprovado no nível ${item.level}`}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </ExpensesModalContainer>
        </ModalContainer>
    )
}
