import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { FlownChartContainer } from './styles'

ChartJS.register(ArcElement, Tooltip, Legend)

interface FlownChartProps {
    economic: number
    promotional: number
    plus: number
    top: number
}

export function FlownChart({
    economic = 0,
    promotional = 0,
    plus = 0,
    top = 0,
}: FlownChartProps) {
    const data = {
        datasets: [
            {
                data: [economic, promotional, plus, top],
                backgroundColor: ['#00ABF2', '#0289CD', '#064383', '#08205E'],
            },
        ],
    }

    const divider = (economic + promotional + plus + top) / 100
    return (
        <FlownChartContainer>
            <div className="flown-title">
                <h1 className="f18-700-dark margin">Classes voadas</h1>
                <p className="f16-500-gray">
                    Percentual de voos realizados em cada classe
                </p>
            </div>
            <div className="chart-content">
                <div className="chart">
                    <Doughnut
                        data={data}
                        options={{
                            cutout: 60,
                            plugins: {
                                datalabels: {
                                    color: 'transparent',
                                },
                            },
                        }}
                    />
                </div>
                <div className="labels-container">
                    <div className="line">
                        <div className="label">
                            <div className="circle economic" />
                            <h2 className="f16-500-dark">Econômica Básica</h2>
                        </div>
                        <div className="value">
                            <p className="f16-500-dark">
                                {divider > 0
                                    ? Math.round(economic / divider)
                                    : 0}
                                %
                            </p>
                        </div>
                    </div>

                    <div className="line">
                        <div className="label">
                            <div className="circle promotional" />
                            <h2 className="f16-500-dark">Promocional</h2>
                        </div>
                        <div className="value">
                            <p className="f16-500-dark">
                                {divider > 0
                                    ? Math.round(promotional / divider)
                                    : 0}
                                %
                            </p>
                        </div>
                    </div>

                    <div className="line">
                        <div className="label">
                            <div className="circle plus" />
                            <h2 className="f16-500-dark">Econômica Plus</h2>
                        </div>
                        <div className="value">
                            <p className="f16-500-dark">
                                {divider > 0 ? Math.round(plus / divider) : 0}%
                            </p>
                        </div>
                    </div>

                    <div className="line">
                        <div className="label">
                            <div className="circle top" />
                            <h2 className="f16-500-dark">Econômica Top</h2>
                        </div>
                        <div className="value">
                            <p className="f16-500-dark">
                                {divider > 0 ? Math.round(top / divider) : 0}%
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </FlownChartContainer>
    )
}
