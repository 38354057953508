/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { User } from '../../entities/user'
import { returnSystemClearanceLabel } from '../../utils/users/functions'
import { Avatar } from '../Avatar'
import { Button } from '../Button'
import { SelectInput } from '../SelectInput'
import { SingleUserContainer } from './styles'
import { useCompany } from '../../hooks/use-company'

export interface Props {
    member: User
    onOpen(member: string): void
}

export function SingleUser({ member, onOpen }: Props) {
    const { company, members } = useCompany()

    // state
    const [systemClearance, setSystemClearance] = useState<{
        label: string
        value: string
    }>({
        label: returnSystemClearanceLabel(
            member.companyInfo[0].systemClearance
        ),
        value: member.companyInfo[0].systemClearance,
    })

    const [travelerClearance, setTravelerClearance] = useState<{
        label: string
        value: string
    }>({
        label:
            company?.travelerClearances.find(
                (c) => c.id === member.companyInfo[0].travelerClearanceId
            )?.label || '',
        value: member.companyInfo[0].travelerClearanceId,
    })

    useEffect(() => {
        setSystemClearance({
            label: returnSystemClearanceLabel(
                member.companyInfo[0].systemClearance
            ),
            value: member.companyInfo[0].systemClearance,
        })
        setTravelerClearance({
            label:
                company?.travelerClearances.find(
                    (c) => c.id === member.companyInfo[0].travelerClearanceId
                )?.label || '',
            value: member.companyInfo[0].travelerClearanceId,
        })
    }, [members])

    return (
        <SingleUserContainer>
            <div className="item start">
                <div className="user-info">
                    <Avatar
                        img={member.photo?.url ?? undefined}
                        label={`${member.firstName.replaceAll(
                            ' ',
                            ''
                        )} ${member.lastName.replaceAll(' ', '')}`}
                    />
                    <div className="user-info--data">
                        <div className="user-name">{`${member.firstName} ${member.lastName}`}</div>
                        <div className="user-email">
                            {member.companyInfo[0].userCorporateEmail}
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="select-div">
                    <SelectInput
                        name="a"
                        value={systemClearance}
                        options={[
                            { label: 'Viajante', value: 'TRAVELER' },
                            { label: 'Gerente', value: 'MANAGER' },
                            { label: 'Administrador', value: 'ADMIN' },
                        ]}
                        isDisabled
                    />
                </div>
            </div>

            <div className="item">
                <div className="select-div">
                    <SelectInput
                        value={travelerClearance}
                        name="a"
                        options={[{ label: 'aa', value: 'a' }]}
                        isDisabled
                    />
                </div>
            </div>
            <div className="item">
                <div className="item-button">
                    <Button
                        buttonType="BORDERED"
                        color="PRIMARY"
                        type="button"
                        text="Ver tudo"
                        onClick={() => onOpen(member.id)}
                    />
                </div>
            </div>
        </SingleUserContainer>
    )
}
