import React from 'react'
import { IconType } from 'react-icons'
import { Link } from 'react-router-dom'
import { v4 } from 'uuid'
import { SubTabsContainer, Tab } from './styles'

interface SubTabsProps {
    direction: 'HORIZONTAL' | 'VERTICAL'
    tabs: { label: string; icon?: IconType; id: string; path?: string }[]
    value: string
    changeTab?(type: string): void
}

export function SubTabs({ direction, tabs, value, changeTab }: SubTabsProps) {
    return (
        <SubTabsContainer direction={direction}>
            {tabs.map((tab) => {
                return (
                    <Tab
                        key={v4()}
                        onClick={() => changeTab && changeTab(tab.id)}
                        selectedTab={value === tab.id}
                        direction={direction}
                    >
                        {tab.path ? (
                            <Link to={tab.path}>
                                <div className="label">
                                    {tab.icon && <tab.icon />}
                                    <h1 className="tab-label">{tab.label}</h1>
                                </div>
                                <div className={`selector `} />
                            </Link>
                        ) : (
                            <>
                                <div className="label">
                                    {tab.icon && <tab.icon />}
                                    <h1 className="tab-label">{tab.label}</h1>
                                </div>
                                <div className={`selector `} />
                            </>
                        )}
                    </Tab>
                )
            })}
        </SubTabsContainer>
    )
}
