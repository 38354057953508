import styled from 'styled-components'

export const FlownChartContainer = styled.div`
    width: 100%;
    padding: 2rem;
    background-color: #edeff2;
    border-radius: 0.8rem;
    width: 56rem;
    .flown-title {
        margin-bottom: 3rem;
        .margin {
            margin-bottom: 0.3rem;
        }
    }
    .chart-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .chart {
            height: 20rem;
            width: 20rem;
            margin-right: 4rem;
        }
        .line {
            width: 25rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .label {
                display: flex;
                align-items: center;
                .circle {
                    width: 2rem;
                    height: 2rem;
                    border-radius: 2rem;
                    background-color: red;
                    margin-right: 1.4rem;
                }
                .economic {
                    background-color: #00abf2;
                }
                .promotional {
                    background-color: #0289cd;
                }
                .plus {
                    background-color: #064383;
                }
                .top {
                    background-color: #08205e;
                }
            }
            &:not(:last-child) {
                margin-bottom: 3rem;
            }
        }
    }
`
