/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import ModalContainer from 'react-modal'
import { ExpensesModalContainer } from './styles'
import { Expenses } from '../../interfaces/expenses'
import { priceFormatter } from '../../utils/expensnes/priceFormatter'
import { historyDateFormatter } from '../../utils/expensnes/dateFormatter'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    ocrData: Expenses['ocrData']
}

export default function OcrDataModal({
    isOpen,
    onRequestClose,
    ocrData,
}: Props) {
    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-history"
        >
            <ExpensesModalContainer>
                <div className="header">
                    <h1 className="f18-700-dark">Dados de OCR</h1>
                </div>
                <div className="content-block">
                    <div className="container">
                        <p className="f16-500-gray italic">Endereço</p>
                        <div className="label-value">
                            <p className="f16-500-gray">CEP</p>
                            <p className="f16-500-gray">
                                {ocrData.address.cep || '-'}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Estado</p>
                            <p className="f16-500-gray">
                                {ocrData.address.state || '-'}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Cidade</p>
                            <p className="f16-500-gray">
                                {ocrData.address.city || '-'}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Bairro</p>
                            <p className="f16-500-gray">
                                {ocrData.address.neighborhood || '-'}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="content-block">
                    <div className="container">
                        <p className="f16-500-gray italic">Pagamento</p>
                        <div className="label-value">
                            <p className="f16-500-gray">Empresa</p>
                            <p className="f16-500-gray">
                                {ocrData.companyName}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">CNPJ</p>
                            <p className="f16-500-gray">{ocrData.cnpj}</p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Data</p>
                            <p className="f16-500-gray">
                                {historyDateFormatter(ocrData.date)}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Total pago</p>
                            <p className="f16-500-gray">
                                R${priceFormatter(ocrData.paidAmount)}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Troco</p>
                            <p className="f16-500-gray">
                                R${priceFormatter(ocrData.change)}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Valor total</p>
                            <p className="f16-500-gray">
                                R${priceFormatter(ocrData.total)}
                            </p>
                        </div>
                    </div>
                </div>
            </ExpensesModalContainer>
        </ModalContainer>
    )
}
