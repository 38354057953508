import styled, { css } from 'styled-components'

interface Props {
    theme: 'DARK' | 'LIGHT'
}

export const PillTabsContainer = styled.div<Props>`
    ${(props) => {
        if (props.theme === 'LIGHT') {
            return css`
                height: 5rem;
                background-color: var(--color-light-gray);
                padding: 0.3rem 0.3rem;
                border-radius: 100em;
            `
        }
        return css`
            background-color: var(--color-dark);
            padding: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            border-top-right-radius: 0.8rem;
            border-top-left-radius: 0.8rem;
        `
    }}
`

export const PillTab = styled.button<Props>`
    height: 4.4rem;
    padding: 0 3rem;
    border: none;
    border-radius: 100rem;
    outline: none;
    font-size: 1.6rem;
    font-weight: 600;
    transition: all 0.4s ease;
    .pill-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            height: 2rem;
            width: 2rem;
            margin-right: 0.5rem;
        }
    }

    ${(props) => {
        if (props.theme === 'LIGHT') {
            return css`
                background-color: var(--color-light);
                min-width: 16rem;
            `
        }
        return css`
            background-color: var(--color-gray);
            color: var(--color-light);
            min-width: 20rem;
        `
    }}
`
