/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes } from 'react'
import { FiCheck } from 'react-icons/fi'
import { ControlButtonContainer } from './styles'

interface ControlButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    id: string
    type: 'SWITCH' | 'RADIO' | 'CHECKBOX'
    callback(): void
    isPressed: boolean
}

export function ControlButton({
    id,
    type,
    callback,
    isPressed,
}: ControlButtonProps) {
    return (
        <ControlButtonContainer>
            <input
                type="checkbox"
                id={id}
                checked={isPressed}
                onChange={() => {
                    callback()
                }}
            />
            {type === 'SWITCH' && (
                <div className="switch-container ">
                    <label htmlFor={id} className="switch-label">
                        <div className="switch-circle" />
                    </label>
                </div>
            )}
            {type === 'RADIO' && (
                <label htmlFor={id} className="radio-label">
                    <div className="radio-container" />
                </label>
            )}
            {type === 'CHECKBOX' && (
                <label htmlFor={id} className="checkbox-label">
                    <div className="checkbox-container ">
                        {isPressed && <FiCheck />}
                    </div>
                </label>
            )}
        </ControlButtonContainer>
    )
}
