/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { MdContacts } from 'react-icons/md'
import ModalContainer from 'react-modal'
import { toast } from 'react-toastify'
import { Button } from '../../components/Button'
import { Input } from '../../UnformInputs/Input'
import { getValidationErrors } from '../../utils/getValidationErrors'

import { OfficeHandlerContainer } from './styles'
import { useCompany } from '../../hooks/use-company'
import { CompanyContact } from '../../interfaces/company-contact'
import { phoneFormatter } from '../../utils/maskFunctions'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
}

export function ContactHandler({ isOpen, onRequestClose }: Props) {
    // hooks
    const { addContactInfo } = useCompany()

    // ref
    const formRef = useRef<FormHandles>(null)
    // state
    const [busyBtn, setBusyBtn] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')

    async function handleContactHandler(data: CompanyContact) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                department: Yup.string().required('Insira um departamento'),
                name: Yup.string().required('Insira um nome'),
                email: Yup.string()
                    .email('Insira um email válido')
                    .required('Insira um email'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            if (data.phoneNumber.length < 14 || data.phoneNumber.length > 15) {
                setBusyBtn(false)

                return toast.warning('Telefone inválido.')
            }
            const response = await addContactInfo({
                ...data,
                phoneNumber: phoneFormatter(data.phoneNumber, 'TO_API'),
            })
            if (response) {
                toast.success('Novo contato cadastrado')
                setPhoneNumber('')
                setBusyBtn(false)
                onRequestClose()
                return
            }
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={() => {
                setPhoneNumber('')
                onRequestClose()
            }}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <Form ref={formRef} onSubmit={handleContactHandler} noValidate>
                <div className="modal-header">
                    <div className="modal-header-title">
                        <MdContacts />
                        <h1 className="f22-700-dark">Novo Contato</h1>
                    </div>
                    <div className="modal-header-description">
                        <div className="modal-header-description--blank" />
                        <p className="f16-500-gray">
                            Crie um novo contato, para seus viajantes <br />
                        </p>
                    </div>
                </div>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <OfficeHandlerContainer>
                    <div className="modal-content">
                        <div className="title">
                            <h1 className="f16-500-gray-secondary">
                                Informações do contato
                            </h1>
                        </div>

                        <div className="form">
                            <Input label="Departamento" name="department" />
                            <Input label="Nome" name="name" />
                            <div className="form--grid">
                                <Input
                                    label="Telefone"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={(e) =>
                                        setPhoneNumber(
                                            phoneFormatter(
                                                e.target.value,
                                                'INPUT'
                                            )
                                        )
                                    }
                                />
                                <Input label="E-mail" name="email" />
                            </div>
                        </div>
                    </div>
                </OfficeHandlerContainer>
                <div className="modal-confirm-btn">
                    <Button
                        color="PRIMARY"
                        buttonType="FILLED"
                        text="Criar contato"
                        busy={busyBtn}
                        type="submit"
                    />
                </div>
            </Form>
        </ModalContainer>
    )
}
