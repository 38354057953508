import styled from 'styled-components'

export const SinglePolicieContainer = styled.div`
    padding: 2.2rem 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    &:not(:last-child) {
        border-bottom: 1px solid #f6f7f8;
    }
    .inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-around;
        & > :first-child {
            margin-bottom: 1rem;
        }
        .label-input {
            display: flex;
            align-items: center;
            p {
                font-size: 1.4rem;
                margin-right: 1rem;
                font-style: italic;
            }
        }
    }
`
