/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import ModalContainer from 'react-modal'
import { Button } from '../../components/Button'
import { ControlButton } from '../../components/ControlButton'
import {
    carReportColumns,
    flightReportColumns,
    generalReportColumns,
    hotelReportColumns,
} from '../../utils/Reports/generalReports'
import { ExportReportContainer } from './styles'
import { useCompany } from '../../hooks/use-company'
import { useDashboard } from '../../hooks/useDashboard'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    type: 'GENERAL' | 'FLIGHT' | 'HOTEL' | 'CAR' | 'EXPENSES' | 'BENV'
}

export function ExportReport({ isOpen, onRequestClose, type }: Props) {
    const { company } = useCompany()
    const { reportFilter, downloadReportData } = useDashboard()
    const managementInfos = company?.managementInfo
    const [busy, setBusy] = useState(false)

    const [selectedGeneralColumns, setSelectedGeneralColumns] = useState<
        string[]
    >(Object.keys(generalReportColumns).map((key) => key))
    const [selectedFlightColumns, setSelectedFlightColumns] = useState<
        string[]
    >(Object.keys(flightReportColumns).map((key) => key))
    const [selectedHotelColumns, setSelectedHotelColumns] = useState<string[]>(
        Object.keys(hotelReportColumns).map((key) => key)
    )
    const [selectedCarColumns, setSelectedCarColumns] = useState<string[]>(
        Object.keys(carReportColumns).map((key) => key)
    )

    const [selectedManagementInfos, setSelectedManagementInfos] = useState<
        string[]
    >(managementInfos?.map((management) => management.label) || [])

    function returnListColumns() {
        if (type === 'GENERAL') return selectedGeneralColumns
        if (type === 'FLIGHT') return selectedFlightColumns
        if (type === 'HOTEL') return selectedHotelColumns
        if (type === 'CAR') return selectedCarColumns
        return []
    }

    async function exportReportHanldler() {
        setBusy(true)
        await downloadReportData(
            type,
            reportFilter,
            returnListColumns(),
            selectedManagementInfos
        )
        setBusy(false)
    }

    const checkboxes = (
        <div className="checkboxes">
            {type === 'GENERAL' && (
                <>
                    {Object.keys(generalReportColumns).map((key) => (
                        <div className="checkbox-label" key={key}>
                            <ControlButton
                                type="CHECKBOX"
                                id={key}
                                isPressed={
                                    selectedGeneralColumns.filter(
                                        (column) => column === key
                                    ).length > 0
                                }
                                callback={() => {
                                    if (
                                        selectedGeneralColumns.filter(
                                            (column) => column === key
                                        ).length > 0
                                    ) {
                                        const newArray =
                                            selectedGeneralColumns.filter(
                                                (column) => column !== key
                                            )
                                        setSelectedGeneralColumns([...newArray])
                                    } else {
                                        setSelectedGeneralColumns(
                                            (prevState) => [...prevState, key]
                                        )
                                    }
                                }}
                            />
                            <h1
                                style={{
                                    marginLeft: '1rem',
                                }}
                            >
                                {/* @ts-ignore */}
                                {generalReportColumns[key]}
                            </h1>
                        </div>
                    ))}
                </>
            )}
            {type === 'FLIGHT' && (
                <>
                    {Object.keys(flightReportColumns).map((key) => (
                        <div className="checkbox-label" key={key}>
                            <ControlButton
                                type="CHECKBOX"
                                id={key}
                                isPressed={
                                    selectedFlightColumns.filter(
                                        (column) => column === key
                                    ).length > 0
                                }
                                callback={() => {
                                    if (
                                        selectedFlightColumns.filter(
                                            (column) => column === key
                                        ).length > 0
                                    ) {
                                        const newArray =
                                            selectedFlightColumns.filter(
                                                (column) => column !== key
                                            )
                                        setSelectedFlightColumns([...newArray])
                                    } else {
                                        setSelectedFlightColumns(
                                            (prevState) => [...prevState, key]
                                        )
                                    }
                                }}
                            />
                            <h1
                                style={{
                                    marginLeft: '1rem',
                                }}
                            >
                                {/* @ts-ignore */}
                                {flightReportColumns[key]}
                            </h1>
                        </div>
                    ))}
                </>
            )}
            {type === 'HOTEL' && (
                <>
                    {Object.keys(hotelReportColumns).map((key) => (
                        <div className="checkbox-label" key={key}>
                            <ControlButton
                                type="CHECKBOX"
                                id={key}
                                isPressed={
                                    selectedHotelColumns.filter(
                                        (column) => column === key
                                    ).length > 0
                                }
                                callback={() => {
                                    if (
                                        selectedHotelColumns.filter(
                                            (column) => column === key
                                        ).length > 0
                                    ) {
                                        const newArray =
                                            selectedHotelColumns.filter(
                                                (column) => column !== key
                                            )
                                        setSelectedHotelColumns([...newArray])
                                    } else {
                                        setSelectedHotelColumns((prevState) => [
                                            ...prevState,
                                            key,
                                        ])
                                    }
                                }}
                            />
                            <h1
                                style={{
                                    marginLeft: '1rem',
                                }}
                            >
                                {/* @ts-ignore */}
                                {hotelReportColumns[key]}
                            </h1>
                        </div>
                    ))}
                </>
            )}
            {type === 'CAR' && (
                <>
                    {Object.keys(carReportColumns).map((key) => (
                        <div className="checkbox-label" key={key}>
                            <ControlButton
                                type="CHECKBOX"
                                id={key}
                                isPressed={
                                    selectedCarColumns.filter(
                                        (column) => column === key
                                    ).length > 0
                                }
                                callback={() => {
                                    if (
                                        selectedCarColumns.filter(
                                            (column) => column === key
                                        ).length > 0
                                    ) {
                                        const newArray =
                                            selectedCarColumns.filter(
                                                (column) => column !== key
                                            )
                                        setSelectedCarColumns([...newArray])
                                    } else {
                                        setSelectedCarColumns((prevState) => [
                                            ...prevState,
                                            key,
                                        ])
                                    }
                                }}
                            />
                            <h1
                                style={{
                                    marginLeft: '1rem',
                                }}
                            >
                                {/* @ts-ignore */}
                                {carReportColumns[key]}
                            </h1>
                        </div>
                    ))}
                </>
            )}
        </div>
    )

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-export"
        >
            <ExportReportContainer>
                <div className="content">
                    {type !== 'BENV' && (
                        <>
                            <div className="header">
                                <div className="header-title">
                                    <h1 className="f24-700-dark">
                                        Exportar relatório
                                    </h1>
                                    <p className="f14-500-gray">
                                        Selecione as colunas que serão
                                        exportadas para a planilha.
                                    </p>
                                </div>
                                <div className="header-buttons">
                                    <Button
                                        buttonType="TEXT"
                                        text="Selecionar todos"
                                        color="PRIMARY"
                                        onClick={() => {
                                            if (type === 'GENERAL')
                                                setSelectedGeneralColumns(
                                                    Object.keys(
                                                        generalReportColumns
                                                    ).map((key) => key)
                                                )
                                            if (type === 'FLIGHT')
                                                setSelectedFlightColumns(
                                                    Object.keys(
                                                        flightReportColumns
                                                    ).map((key) => key)
                                                )
                                            if (type === 'HOTEL')
                                                setSelectedHotelColumns(
                                                    Object.keys(
                                                        hotelReportColumns
                                                    ).map((key) => key)
                                                )
                                            if (type === 'CAR')
                                                setSelectedCarColumns(
                                                    Object.keys(
                                                        carReportColumns
                                                    ).map((key) => key)
                                                )
                                        }}
                                    />
                                    <Button
                                        buttonType="TEXT"
                                        text="Limpar"
                                        color="PRIMARY"
                                        onClick={() => {
                                            if (type === 'GENERAL')
                                                setSelectedGeneralColumns([])
                                            if (type === 'FLIGHT')
                                                setSelectedFlightColumns([])
                                            if (type === 'HOTEL')
                                                setSelectedHotelColumns([])
                                            if (type === 'CAR')
                                                setSelectedCarColumns([])
                                        }}
                                    />
                                </div>
                            </div>
                            {checkboxes}
                        </>
                    )}
                    <div className="header" style={{ marginTop: '4rem' }}>
                        <div className="header-title">
                            <h1 className="f24-700-dark">
                                Informações gerenciais
                            </h1>
                            <p className="f14-500-gray">
                                Selecione as colunas de informações gerencias
                                que serão exportadas na planilha.
                            </p>
                        </div>
                        <div className="header-buttons">
                            <Button
                                buttonType="TEXT"
                                text="Selecionar todos"
                                color="PRIMARY"
                                onClick={() => {
                                    setSelectedManagementInfos(
                                        managementInfos?.map(
                                            (management) => management.label
                                        ) || []
                                    )
                                }}
                            />
                            <Button
                                buttonType="TEXT"
                                text="Limpar"
                                color="PRIMARY"
                                onClick={() => {
                                    setSelectedManagementInfos([])
                                }}
                            />
                        </div>
                    </div>
                    <div className="checkboxes">
                        {managementInfos?.map((key) => (
                            <div className="checkbox-label" key={key.id}>
                                <ControlButton
                                    type="CHECKBOX"
                                    id={key.label}
                                    isPressed={
                                        selectedManagementInfos.filter(
                                            (column) => column === key.label
                                        ).length > 0
                                    }
                                    callback={() => {
                                        if (
                                            selectedManagementInfos.filter(
                                                (column) => column === key.label
                                            ).length > 0
                                        ) {
                                            const newArray =
                                                selectedManagementInfos.filter(
                                                    (column) =>
                                                        column !== key.label
                                                )
                                            setSelectedManagementInfos([
                                                ...newArray,
                                            ])
                                        } else {
                                            setSelectedManagementInfos(
                                                (prevState) => [
                                                    ...prevState,
                                                    key.label,
                                                ]
                                            )
                                        }
                                    }}
                                />
                                {/* @ts-ignore */}
                                <h1 style={{ marginLeft: '1rem' }}>
                                    {key.label}
                                </h1>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="export-btn">
                    <Button
                        color="PRIMARY"
                        buttonType="FILLED"
                        text="Exportar"
                        busy={busy}
                        onClick={() => exportReportHanldler()}
                    />
                </div>
            </ExportReportContainer>
        </ModalContainer>
    )
}
