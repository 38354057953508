import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { IconType } from 'react-icons'
import { DoughnutChartContainer } from './styles'

interface Props {
    label: string
    value: string
    icon?: IconType
    percentage: number
    description?: string
}

ChartJS.register(ArcElement, Tooltip)

export function DoughnutChart({
    label,
    value,
    icon: Icon,
    percentage,
    description,
}: Props) {
    const data = {
        datasets: [
            {
                data: [percentage, percentage - 100],
                backgroundColor: ['#00ABF2', '#EDEFF2'],
            },
        ],
    }

    return (
        <DoughnutChartContainer>
            <div className="chart">
                <Doughnut
                    data={data}
                    options={{
                        cutout: 27,
                        plugins: {
                            tooltip: {
                                enabled: false, // Disable the tooltip
                            },
                            datalabels: {
                                color: 'transparent',
                            },
                        },
                    }}
                />
                <div className="label">
                    {Icon && <Icon />}
                    <p className="f14-500-gray">{percentage.toFixed(0)}%</p>
                </div>
            </div>
            <div className="info">
                <p className="f16-500-gray label">{label}</p>
                <h1 className="f22-700-dark">{value}</h1>
                {description && <p className="f16-500-gray">{description}</p>}
            </div>
        </DoughnutChartContainer>
    )
}
