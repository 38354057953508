/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import React, { useEffect, useRef, useState } from 'react'
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR'
import * as Yup from 'yup'
import { MdRemove } from 'react-icons/md'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { v4 } from 'uuid'
import { toast } from 'react-toastify'
import { Input } from '../../../UnformInputs/Input'
import { SelectInput } from '../../../components/SelectInput'
import { TripInfoConfigContainer } from './styles'
import { Button } from '../../../components/Button'
import { User } from '../../../entities/user'
import { cnpjFormatter, cpfFormatter } from '../../../utils/maskFunctions'
import { DocumentType } from '../../../enums/document-type.enum'
import { getValidationErrors } from '../../../utils/getValidationErrors'
import { EditProfileDto } from '../../../dtos/edit-profile'

interface Props {
    member: User
}

export function TripInfoConfig({ member }: Props) {
    // ref
    const formRef = useRef<FormHandles>(null)
    // state
    const [addNewDocument, setAddNewDocument] = useState(false)
    const [userSex, setUserSex] = useState<{ label: string; value: string }>(
        member
            ? {
                  label: member.sex === 'MALE' ? 'Masculino' : 'Feminino',
                  value: member.sex,
              }
            : {
                  label: 'Selecionar',
                  value: 'none',
              }
    )
    const [busyBtn, setBusyBtn] = useState(false)
    const [busy, setBusy] = useState(false)

    function closeAddDocumentModal() {
        setAddNewDocument(false)
    }

    useEffect(() => {
        formRef.current?.setData({
            firstName: member.travelerInfo?.firstName || '',
            lastName: member.travelerInfo?.lastName || '',
            dateOfBirth:
                format(new Date(member.dateOfBirth || ''), `dd'/'MM'/'Y`, {
                    locale: ptLocale,
                }) || '',
        })
    }, [])

    async function handleEditTravelerInfos(data: {
        firstName: string
        lastName: string
    }) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                firstName: Yup.string().required('Nome inválido'),
                lastName: Yup.string().required('Sobrenome inválido'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            const editProfileData: EditProfileDto = {
                travelerInfo: {
                    firstName: data.firstName || undefined,
                    lastName: data.lastName || undefined,
                },
            }
            // await editUserProfile(editProfileData)
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <TripInfoConfigContainer>
            <Form ref={formRef} onSubmit={handleEditTravelerInfos}>
                <div className="trip-info-config">
                    <div className="trip-info-config-header">
                        <div className="trip-info-config-header--title">
                            <h1 className="f18-700-dark">
                                Informações para reservas
                            </h1>
                            <p className="f16-500-gray">
                                São os dados que serão utilizados nas suas
                                reservas e emissões. É importante que estas
                                informações estejam iguais aos seus documentos
                                que serão apresentados para a companhia aérea e
                                para o hotel.
                            </p>
                        </div>
                        <Button
                            buttonType="TEXT"
                            color="PRIMARY"
                            text="Salvar"
                            type="submit"
                        />
                    </div>
                    <div className="trip-info-config-form">
                        <Input
                            label="Primeiro nome legal"
                            normalLabel
                            name="firstName"
                        />
                        <Input
                            label="Último Sobrenome legal"
                            normalLabel
                            name="lastName"
                        />
                        <SelectInput
                            label="Gênero"
                            name="sex"
                            value={userSex}
                            options={[
                                {
                                    label: 'Masculino',
                                    value: 'MALE',
                                },
                                {
                                    label: 'Feminino',
                                    value: 'FEMALE',
                                },
                            ]}
                            onChange={(e) => {
                                if (e) {
                                    setUserSex({
                                        label: e.label,
                                        value: e.value,
                                    })
                                }
                            }}
                        />
                        <Input
                            label="Data de nascimento"
                            normalLabel
                            name="dateOfBirth"
                        />
                    </div>
                </div>
                <div className="trip-info-config">
                    <div className="trip-info-config-header">
                        <div className="trip-info-config-header--title">
                            <h1 className="f18-700-dark">Documentos</h1>
                            <p className="f16-500-gray">
                                Documentos que utilizaremos para confirmação de
                                reservas e emissões na plataforma.
                            </p>
                        </div>
                        <div className="blank" />
                    </div>
                    {member.documents.map((doc, index) => {
                        return (
                            <div
                                className="trip-info-config-documents"
                                key={v4()}
                            >
                                <div className="trip-info-config-documents-row">
                                    <h1 className="f16-700-dark trip-info-config-documents--doc">
                                        {doc.type}
                                    </h1>
                                    <h2 className="f14-500-gray">
                                        {doc.type === DocumentType.CNPJ
                                            ? cnpjFormatter(doc.value, 'INPUT')
                                            : cpfFormatter(doc.value, 'INPUT')}
                                    </h2>
                                    <Button
                                        color="PRIMARY"
                                        buttonType="TEXT"
                                        icon={MdRemove}
                                        text=""
                                        onClick={async () => {
                                            // await deleteDocument(index)
                                        }}
                                    />
                                </div>
                                <div className="trip-info-config-documents--divider" />
                            </div>
                        )
                    })}
                    <Button
                        text="Adicionar documento"
                        buttonType="TEXT"
                        color="PRIMARY"
                        icon={AiOutlinePlusCircle}
                        onClick={() => setAddNewDocument(true)}
                    />
                </div>
            </Form>
        </TripInfoConfigContainer>
    )
}
{
    /* <AddDocument
             isOpen={addNewDocument}
             onRequestClose={closeAddDocumentModal}
        /> */
}
