/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import { IconType } from 'react-icons/lib'
import { v4 } from 'uuid'
import { PillTabsContainer, PillTab } from './styles'

interface PillTabsProps {
    value: string
    pillTabs: {
        label: string
        id: string
        icon?: IconType
    }[]
    theme: 'LIGHT' | 'DARK'
    changeTab(type: string): void
}

export function PillTabs({ pillTabs, theme, value, changeTab }: PillTabsProps) {
    function hintColor(t: 'DARK' | 'LIGHT') {
        if (t === 'DARK') {
            return 'var(--color-secondary-gray)'
        }
        return 'var(--color-gray)'
    }

    return (
        <PillTabsContainer theme={theme}>
            {pillTabs.map((pillTab) => {
                return (
                    <PillTab
                        key={v4()}
                        type="button"
                        theme={theme}
                        onClick={() => changeTab(pillTab.id)}
                        style={
                            pillTab.id !== value
                                ? {
                                      background: 'inherit',
                                      color: hintColor(theme),
                                  }
                                : {}
                        }
                    >
                        <div className="pill-icon">
                            {pillTab.icon && <pillTab.icon />}
                            {pillTab.label}
                        </div>
                    </PillTab>
                )
            })}
        </PillTabsContainer>
    )
}
