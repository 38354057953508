import styled from 'styled-components'

export const AddTravalersInTripContainer = styled.div`
    margin-bottom: 5rem;
    margin-top: 0rem;

    .modal-content {
        min-height: 50vh;
        max-height: 50vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 0.5rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 0.5rem;
            background: #edeff2;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            border-radius: 0.5rem;
            background: #c8cacd;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #c8cacd;
        }
        .added-travelers {
            &--title {
                margin: 1.8rem 0 0 0;
            }
        }
        .add-travalers {
            margin-top: 1.5rem;
        }
    }
    .save-btn {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
        width: 20rem;
        margin: auto;
    }
`

export const Traveler = styled.div`
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .traveler-info {
        display: flex;
        align-items: center;
        &--logo {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            margin-right: 1rem;
            img {
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
            }
        }
    }
    .action-button {
        border: none;
        background-color: inherit;
        svg {
            height: 2.6rem;
            width: 2.6rem;
        }
    }
    .remove {
        svg {
            color: var(--color-warning);
        }
    }
    .add {
        svg {
            color: var(--color-dark);
        }
    }
`
