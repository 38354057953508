/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import { TravelerTagContainer } from './styles'

interface Props {
    firstName: string
    lastName: string
    photo?: { url: string }
}

export default function TravelerTag({ firstName, lastName, photo }: Props) {
    return (
        <TravelerTagContainer>
            <div className="image">
                {photo ? (
                    <img src={photo.url} alt="traveler-photo" />
                ) : (
                    <div className="logo">
                        {firstName[0]}
                        {lastName[0]}
                    </div>
                )}
            </div>
            <p className="name f16-700-dark">{`${firstName} ${lastName}`}</p>
        </TravelerTagContainer>
    )
}
