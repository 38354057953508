import styled from 'styled-components'

export const HeaderContainer = styled.header`
    position: relative;
    width: 100%;
    height: 5.5rem;
    background-color: var(--color-light);
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    z-index: 500;
    .header-logo {
        align-self: center;
        margin-left: 2.3rem;
        img {
            width: 12.3rem;
            height: 3rem;
        }
    }
    .header-navigation {
        align-self: center;
    }
    .header-menu {
        margin-right: 2.3rem;
        align-self: center;
        justify-self: flex-end;
        display: flex;
        flex-direction: row;
        align-items: center;
        .menu-help-label {
            font-size: 1.6rem;
            font-weight: 500;
            color: var(--color-primary);
            margin-right: 1.5rem;
        }
        .menu-divider {
            height: 3.2rem;
            width: 1px;
            background-color: var(--color-light-gray);
        }
        .menu-notification {
            width: 2rem;
            height: 2rem;
            margin: 0 1.5rem;
            svg {
                width: 2rem;
                height: 2rem;
                color: #323232;
            }
        }
        .menu-user {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 0 1rem 0 1.5rem;

            .user-avatar {
                height: 3.5rem;
                width: 3.5rem;
                border-radius: 50%;
                img {
                    height: 3.5rem;
                    width: 3.5rem;
                    border-radius: 50%;
                }
            }
            .user-info {
                margin: 0 1rem;
                .user-name-label {
                    font-size: 1.6rem;
                    font-weight: 700;
                }
                .user-company-label {
                    font-size: 1.4rem;
                    color: var(--color-gray);
                    font-weight: 500;
                }
            }
            .dropdown-btn {
                border: none;
                background-color: inherit;
                outline: none;
                svg {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }
    .menu-user-nav {
        width: 16em;
        position: absolute;
        bottom: 0;
        right: 0rem;
        transform: translateY(100%);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: var(--color-light);
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        a {
            text-decoration: none;
        }
        z-index: 50;
        .menu-user-nav--btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            border: none;
            background-color: inherit;
            padding: 2rem;
            border-bottom: 1px solid var(--color-background);
            svg {
                margin-right: 1rem;
            }
        }
    }
    .menu-user-nav-opaciy {
        position: absolute;
        opacity: 0.2;
        width: 100%;
        height: 100vh;
    }
`
