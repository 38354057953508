/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { MainUserContainer } from '../../components/MainUserContainer'
import { SubTabs } from '../../components/SubTabs'
import { ConfigurationContainer } from '../../components/UserConfigurationContainer'
import { useCompany } from '../../hooks/use-company'
import { UserCompanyInfo } from './UserCompanyInfo'
import { PaymentMethodsAndLoyalties } from './PaymentMethodsAndLoyalties'
import { UserProfile } from './Profile'
import { UserInfoContainer } from './styles'
import { TripInfoConfig } from './TripInfoConfig'

export function UserInfoPage() {
    // params
    const [, id] = window.location.search.split('=')
    // hooks
    const { getMember, member } = useCompany()
    // state
    const [busy, setBusy] = useState(false)
    const [selectedTab, setSelectedTab] = useState<
        'PROFILE' | 'TRIP_INFO' | 'PAYMENT_METHODS' | 'COMPANY_INFO'
    >('PROFILE')

    useEffect(() => {
        ;(async () => {
            await getMember(id)
            setBusy(false)
        })()
    }, [])

    return (
        <UserInfoContainer>
            {member ? (
                <MainUserContainer>
                    <ConfigurationContainer member={member}>
                        <div className="content-nav">
                            <SubTabs
                                direction="HORIZONTAL"
                                value={selectedTab}
                                changeTab={(
                                    value:
                                        | 'PROFILE'
                                        | 'TRIP_INFO'
                                        | 'PAYMENT_METHODS'
                                        | 'COMPANY_INFO'
                                ) => setSelectedTab(value)}
                                tabs={[
                                    {
                                        id: 'PROFILE',
                                        label: 'Meu perfil',
                                    },
                                    {
                                        id: 'TRIP_INFO',
                                        label: 'Informações de viagem',
                                    },
                                    {
                                        id: 'PAYMENT_METHODS',
                                        label: 'Pagamentos e fidelidade',
                                    },
                                    {
                                        id: 'COMPANY_INFO',
                                        label: 'Informações corporativas',
                                    },
                                ]}
                            />
                        </div>
                        {selectedTab === 'PROFILE' && (
                            <UserProfile member={member} />
                        )}
                        {selectedTab === 'TRIP_INFO' && (
                            <TripInfoConfig member={member} />
                        )}
                        {selectedTab === 'PAYMENT_METHODS' && (
                            <PaymentMethodsAndLoyalties member={member} />
                        )}
                        {selectedTab === 'COMPANY_INFO' && (
                            <UserCompanyInfo member={member} />
                        )}
                    </ConfigurationContainer>
                </MainUserContainer>
            ) : (
                <div />
            )}
        </UserInfoContainer>
    )
}
