import styled from 'styled-components'

export const CreateNewUserContainer = styled.div`
    max-height: 60vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 0.5rem;
        background: #edeff2;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background: #96989b;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #96989b;
    }
    .action-btn {
        margin: 0 auto;
        margin-top: 3rem;
        width: 20rem;
    }
    .form {
        &--grid {
            margin-bottom: 1.5rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1.5rem;
            .hidden-input {
                background-color: red;
                display: none;
                input {
                    display: none;
                }
            }
        }
        &--grid-3 {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 1.5rem;
        }
    }
`
