/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { MdPeople } from 'react-icons/md'
import ModalContainer from 'react-modal'
import { toast } from 'react-toastify'
import { Button } from '../../components/Button'
import { PolicieContainer } from './styles'
import { ExpenseCategoryPolicy } from '../../entities/expenses-category'
import { SinglePolicieContainer } from '../../pages/Politics/styles'
import { TitleDescription } from '../../components/TitleDescription'
import { ControlButton } from '../../components/ControlButton'
import { SimpleInput } from '../../components/SimpleInput'
import { SelectInput } from '../../components/SelectInput'
import { useCompany } from '../../hooks/use-company'

interface Props {
    isOpen: boolean
    addNewPolicy(data: ExpenseCategoryPolicy): void
    currentPoliciesIds: string[]
    onRequestClose: () => void
}

export function ExpenseCategoryPolicyC({
    isOpen,
    onRequestClose,
    addNewPolicy,
    currentPoliciesIds,
}: Props) {
    const { company } = useCompany()
    const formRef = useRef<FormHandles>(null)

    const [expCategoryPolicy, setExpCategoryPolicy] = useState<
        Omit<ExpenseCategoryPolicy, 'travelerClearance'>
    >({
        enabled: true,
        maxAmountPerDay: '',
        maxAmountPerItem: '',
        maxItemsPerDay: '',
        travelerClearanceId: '',
    })
    const [travelerClearance, setTravelerClearence] = useState<{
        label: string
        value: string
    }>({ label: 'Selecione', value: '' })

    async function handleInviteUser() {
        if (!expCategoryPolicy.travelerClearanceId)
            return toast.warning('Selecione uma política')
        if (!expCategoryPolicy.maxAmountPerDay)
            return toast.warning('Insira o valor maximo por dia')
        if (!expCategoryPolicy.maxAmountPerItem)
            return toast.warning('Insira o valor maximo por item')
        if (!expCategoryPolicy.maxItemsPerDay)
            return toast.warning('Insira o valor maximo por dia')
        addNewPolicy({
            ...expCategoryPolicy,
            travelerClearance: company!.travelerClearances.find(
                (clearance) =>
                    clearance.id === expCategoryPolicy.travelerClearanceId
            )!,
        })
        return onRequestClose()
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-category"
        >
            <Form ref={formRef} onSubmit={handleInviteUser} noValidate>
                <div className="modal-header">
                    <div className="modal-header-title">
                        <MdPeople />
                        <h1 className="f22-700-dark">
                            Adicionando nova política
                        </h1>
                    </div>
                    <div className="modal-header-description">
                        <div className="modal-header-description--blank" />
                        <p className="f16-500-gray" />
                    </div>
                </div>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <PolicieContainer>
                    <div className="modal-content">
                        <div>
                            <SinglePolicieContainer>
                                <TitleDescription
                                    title="Vincular à uma política"
                                    description="Defina uma política para ser vinculada à essa categoria de despesa."
                                />
                                <div className="inputs">
                                    <SelectInput
                                        name="a"
                                        value={travelerClearance}
                                        options={company!.travelerClearances
                                            .filter(
                                                (tc) =>
                                                    !currentPoliciesIds.includes(
                                                        tc.id
                                                    )
                                            )
                                            .map((tc) => ({
                                                label: tc.label,
                                                value: tc.id,
                                            }))}
                                        onChange={(e) => {
                                            if (e) {
                                                setTravelerClearence({
                                                    label: e.label,
                                                    value: e.value,
                                                })
                                                setExpCategoryPolicy(
                                                    (prevState) => {
                                                        return {
                                                            ...prevState,
                                                            travelerClearanceId:
                                                                e.value,
                                                        }
                                                    }
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            </SinglePolicieContainer>
                            <SinglePolicieContainer>
                                <TitleDescription title="Permitir fazer solicitações?" />
                                <div className="inputs">
                                    <ControlButton
                                        id={`${expCategoryPolicy}.productEnabled`}
                                        type="SWITCH"
                                        isPressed={
                                            expCategoryPolicy?.enabled || false
                                        }
                                        callback={() => {
                                            setExpCategoryPolicy({
                                                ...expCategoryPolicy,
                                                enabled:
                                                    !expCategoryPolicy?.enabled,
                                            })
                                        }}
                                    />
                                </div>
                            </SinglePolicieContainer>
                            <SinglePolicieContainer>
                                <TitleDescription
                                    title="Valor máximo permitido por dia"
                                    description="Defina um valor máximo permitido por dia para despesas dessa cetegoria."
                                />
                                <div className="inputs">
                                    <SimpleInput
                                        label=""
                                        name="a"
                                        isPricing
                                        value={
                                            expCategoryPolicy.maxAmountPerDay
                                        }
                                        onChange={(e) => {
                                            if (
                                                !/^\d*\.?\d*$/.test(
                                                    e.target.value
                                                )
                                            )
                                                return
                                            setExpCategoryPolicy({
                                                ...expCategoryPolicy,
                                                maxAmountPerDay: e.target.value,
                                            })
                                        }}
                                    />
                                </div>
                            </SinglePolicieContainer>
                            <SinglePolicieContainer>
                                <TitleDescription
                                    title="Valor máximo permitido por despesa"
                                    description="Defina um valor máximo permitido para despesas dessa categoria."
                                />
                                <div className="inputs">
                                    <SimpleInput
                                        label=""
                                        name="a"
                                        isPricing
                                        value={
                                            expCategoryPolicy.maxAmountPerItem
                                        }
                                        onChange={(e) => {
                                            if (
                                                !/^\d*\.?\d*$/.test(
                                                    e.target.value
                                                )
                                            )
                                                return
                                            setExpCategoryPolicy({
                                                ...expCategoryPolicy,
                                                maxAmountPerItem:
                                                    e.target.value,
                                            })
                                        }}
                                    />
                                </div>
                            </SinglePolicieContainer>
                            <SinglePolicieContainer>
                                <TitleDescription
                                    title="Quantidade máxima de solicitações por dia"
                                    description="Defina uma quantidade máxima solicitações por dia para essa despesa."
                                />
                                <div className="inputs">
                                    <SimpleInput
                                        label=""
                                        name="a"
                                        value={expCategoryPolicy.maxItemsPerDay}
                                        onChange={(e) => {
                                            if (
                                                !/^\d*\.?\d*$/.test(
                                                    e.target.value
                                                )
                                            )
                                                return
                                            setExpCategoryPolicy({
                                                ...expCategoryPolicy,
                                                maxItemsPerDay: e.target.value,
                                            })
                                        }}
                                    />
                                </div>
                            </SinglePolicieContainer>
                        </div>
                    </div>
                </PolicieContainer>
                <div className="modal-confirm-btn">
                    <Button
                        color="PRIMARY"
                        buttonType="FILLED"
                        text="Adicionar política"
                        type="submit"
                    />
                </div>
            </Form>
        </ModalContainer>
    )
}
