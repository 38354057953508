import styled from 'styled-components'

export const PoliticsContainer = styled.div`
    .politics-container {
        margin-top: 5.4rem;
        display: grid;
        grid-template-columns: 28rem 80rem 28rem;
        grid-gap: 2rem;
        align-items: flex-start;
        justify-content: center;
        .side-menu {
            .add-management-info {
                padding: 2rem 1rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                h2 {
                    color: var(--color-primary);
                    font-size: 1.6rem;
                    font-weight: 500;
                }
                svg {
                    margin-right: 1rem;
                    color: var(--color-primary);
                }
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
                border-radius: 8px;
                border: none;
            }
        }
    }
`

export const PoliticsContent = styled.div`
    .header {
        padding: 2.5rem;
        background-color: #fff;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        margin-bottom: 2rem;
        .title {
            display: flex;
            margin-bottom: 1rem;
            svg {
                width: 2.5rem;
                height: 2.5rem;
                color: #191b1e;
                margin-right: 2rem;
            }
            h1 {
                font-weight: 700;
                font-size: 2.2rem;
                color: #191b1e;
            }
        }
        p {
            font-weight: 500;
            font-size: 1.6rem;
            color: #646669;
        }
    }
    .politics {
        background-color: #fff;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        margin-bottom: 8rem;
    }
`

export const SinglePolicieContainer = styled.div`
    padding: 2.2rem 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    &:not(:last-child) {
        border-bottom: 1px solid #f6f7f8;
    }
    .inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        & > :first-child {
            margin-bottom: 1rem;
        }
    }
`
