/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { FiPlusCircle } from 'react-icons/fi'
import { MdShield } from 'react-icons/md'
import { Header } from '../../components/Header'
import { SideMenu } from '../../components/SideMenu'
import { useCompany } from '../../hooks/use-company'
import { useUser } from '../../hooks/use-user'
import { CreateTravelerClearanceC } from '../../modals/CreateTravelerClearance'
import { InternationalCarPolicy } from './PoliciesType/InternationalCarPolicy'
import { InternationalFlightPolicy } from './PoliciesType/InternationalFlightPolicy'
import { InternationalHotelPolicy } from './PoliciesType/InternationalHotel'
import { NationalCarPolicy } from './PoliciesType/NationalCarPolicy'
import { NationalFlightPolicy } from './PoliciesType/NationalFlightPolicy'
import { NationalHotelPolicy } from './PoliciesType/NationalHotel'
import { PoliticsContainer, PoliticsContent } from './styles'
import { InvoiceExpenses } from './PoliciesType/InvoiceExpenses'
import { AdvanceMoney } from './PoliciesType/AdvanceMoney'

export function PoliticsPage() {
    // hooks
    const {
        getCompanyProfile,
        travelerClearances,
        selectTravelerClearance,
        chooseTravelerClearanceToBeSelected,
    } = useCompany()
    const { user } = useUser()

    // state
    const [openCreatePolicie, setOpenCreatePolicie] = useState(false)

    const [busy, setBusy] = useState(false)
    const [changingClearance, setChangingClearance] = useState(false)

    function changeSubMenu(id: string) {
        setChangingClearance(true)
        chooseTravelerClearanceToBeSelected(
            travelerClearances.find((t) => t.id === id)!
        )
        setTimeout(() => {
            setChangingClearance(false)
        }, 300)
    }

    useEffect(() => {
        setBusy(true)
        ;(async () => {
            if (user) {
                await getCompanyProfile(user.companyInfo[0].id)
                // if (response!.travelerClearances.length > 0) {
                //     setTravelerClearances(response!.travelerClearances)
                //     setSelectTravelerClearance(response!.travelerClearances[0])
                // }
            }
            setBusy(false)
        })()
    }, [])

    return (
        <PoliticsContainer>
            {!busy ? (
                <>
                    <Header header="POLITICS" />
                    <div className="politics-container">
                        <div className="side-menu">
                            <SideMenu
                                changeTab={changeSubMenu}
                                title="Perfis de viajante"
                                value={selectTravelerClearance?.id || ''}
                                sideMenus={travelerClearances.map((t) => {
                                    return {
                                        id: t.id,
                                        label: t.label,
                                        icon: MdShield,
                                    }
                                })}
                            />
                            <button
                                type="button"
                                className="add-management-info"
                                onClick={() => setOpenCreatePolicie(true)}
                            >
                                <FiPlusCircle />
                                <h2>Criar outro campo perfil</h2>
                            </button>
                        </div>
                        {selectTravelerClearance && (
                            <PoliticsContent>
                                <div className="header">
                                    <div className="title">
                                        <MdShield />
                                        <h1>{selectTravelerClearance.label}</h1>
                                    </div>
                                    {/* <p>
                                        Voos fora desse padrão serão
                                        classificados como fora de política.
                                        <br /> 117 viajantes estão configurados
                                        com esse perfil de política.
                                    </p> */}
                                </div>
                                {!changingClearance && (
                                    <div className="politics">
                                        <NationalFlightPolicy
                                            nationalFlight={
                                                selectTravelerClearance.policies
                                                    .flight.national
                                            }
                                        />
                                        <InternationalFlightPolicy
                                            internationalFlight={
                                                selectTravelerClearance.policies
                                                    .flight.international
                                            }
                                        />
                                        <NationalHotelPolicy
                                            nationalHotel={
                                                selectTravelerClearance.policies
                                                    .hotel.national
                                            }
                                        />
                                        <InternationalHotelPolicy
                                            internationalHotel={
                                                selectTravelerClearance.policies
                                                    .hotel.international
                                            }
                                        />
                                        <NationalCarPolicy
                                            nationalCar={
                                                selectTravelerClearance.policies
                                                    .car.national
                                            }
                                        />
                                        <InternationalCarPolicy
                                            internationalCar={
                                                selectTravelerClearance.policies
                                                    .car.international
                                            }
                                        />
                                        {selectTravelerClearance.policies
                                            .expense && (
                                            <InvoiceExpenses
                                                invoiceExpenses={
                                                    selectTravelerClearance
                                                        .policies.expense
                                                        .invoice
                                                }
                                            />
                                        )}
                                        {selectTravelerClearance.policies
                                            .advanceMoney && (
                                            <AdvanceMoney
                                                advanceMoney={
                                                    selectTravelerClearance
                                                        .policies.advanceMoney
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                            </PoliticsContent>
                        )}
                    </div>
                    <CreateTravelerClearanceC
                        isOpen={openCreatePolicie}
                        onRequestClose={() => setOpenCreatePolicie(false)}
                    />
                </>
            ) : (
                <div />
            )}
        </PoliticsContainer>
    )
}
