import styled, { keyframes } from 'styled-components'

import background from '../../assets/initial-background.jpg'

const appear = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const InitialContainerStyle = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    min-height: 100vh;
    width: 100%;
    background-color: var(--color-light);
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .logo {
            width: 23rem;
            margin: 12rem 0 4.5rem 0;
            img {
                width: 23rem;
            }
        }
        .content-form {
            width: 100%;
            padding: 0 11rem;
            display: flex;
            text-align: center;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 8rem;
            animation: ${appear} 1s;
        }
    }

    .background {
        background-image: url(${background});
        background-repeat: no-repeat;

        background-size: cover;
        background-position: 0% 100%;
    }
    @media screen and (max-width: 87.5em) {
        .content {
            .content-form {
                padding: 0 8rem;
            }
        }
        .background {
            background-position: 50% 0%;
        }
    }
    @media screen and (max-width: 75em) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 37.5em) {
        //900px  //1rem = 8px, 8/16 = 50%
        .background {
            display: none;
        }
        .content {
            .content-form {
                padding: 0 2rem;
            }
        }
        grid-template-columns: 1fr;
    }
`

export const LoginContent = styled.div`
    width: 100%;
    .login-user-label {
        text-align: start;
        margin: 5.6rem 0 2.7rem 0;
    }
    form {
        div:first-child {
            margin-bottom: 2rem;
        }
        .password-recover {
            display: flex;
            justify-content: start;

            button {
                margin: 1.1rem 0 2.3rem 0;
                padding: 0;
            }
        }
    }
`
