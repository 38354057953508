import React, { useState } from 'react'
import { Button } from '../../components/Button'
import { ControlButton } from '../../components/ControlButton'
import { SelectInput } from '../../components/SelectInput'
import { useCompany } from '../../hooks/use-company'
import { ListMembers } from '../ListMembers'
import { MembersToAdd } from '../MembersToAdd'
import { OptionsToAdd } from '../OptionsToAdd'
import { useEditMember } from './hook/useEditMember'

export function ApprovesFor() {
    const [openAddMembers, setOpenAddMembers] = useState(false)
    const [openAddManagement, setOpenAddManagement] = useState<
        string | undefined
    >()

    const {
        isMaster,
        changeIsMaster,
        approvalLevel,
        changeApprovalLevel,
        approvalLevelOptions,
        approvesFor,
        approvesForManagementInfo,
        approvers,
    } = useEditMember()
    const { member, companyManagementInfos } = useCompany()

    const [openListMembers, setOpenListMembers] = useState<
        | {
              id: string
              label: string
              url?: string
          }[]
        | undefined
    >()

    function approvalLevelLabel() {
        if (approvalLevelOptions.find((opt) => opt.value === approvalLevel)) {
            return {
                label: approvalLevelOptions.find(
                    (opt) => opt.value === approvalLevel
                )?.label,
                value: approvalLevel,
            }
        }
        return {
            label: `Nível Indisponível`,
            value: approvalLevelOptions[approvalLevelOptions.length - 1].value,
        }
    }

    function returnLinkedLabel(list: string[], type: 'USER' | 'MANAGEMENTS') {
        if (list.length === 0) {
            return ` ${
                type === 'USER'
                    ? 'Não aprova para viajantes específicos'
                    : 'Não existem opções vinculadas'
            }`
        }
        if (list.length <= 2) {
            return `${list.map((t) => t).join(', ')}`
        }
        return `${list[0]}, ${list[1]}... +${list.length - 2}`
    }

    function returnLinkedOptionsLabel(managementInfoId: string) {
        const fullManagementInfoInUser = approvesForManagementInfo.find(
            (mId) => mId.id === managementInfoId
        )
        if (fullManagementInfoInUser) {
            if (fullManagementInfoInUser.options.length === 1) {
                return `${fullManagementInfoInUser.options.map(
                    (opt) => opt.label
                )}`
            }
            if (fullManagementInfoInUser.options.length === 2) {
                return `${fullManagementInfoInUser.options
                    .map((opt) => opt.label)
                    .join(', ')}`
            }
            if (fullManagementInfoInUser.options.length > 2) {
                return `${fullManagementInfoInUser.options[0].label}, ${
                    fullManagementInfoInUser.options[2].label
                }... +${fullManagementInfoInUser.options.length - 2}`
            }
        }
        return `Nenhum ${
            companyManagementInfos.find((mId) => mId.id === managementInfoId)
                ?.label
        }...`
    }

    return (
        <>
            <div className="corporative-infos">
                <div className="company-info-header">
                    <div className="company-info-header--title">
                        <h1 className="f18-700-dark">
                            Configurações das aprovações de {member?.firstName}{' '}
                            {member?.lastName}
                        </h1>
                    </div>
                </div>
                <div className="select-type">
                    <h1 className="f16-500-dark">Aprovador global</h1>
                    <ControlButton
                        type="SWITCH"
                        isPressed={isMaster || false}
                        id="ISMASTER"
                        callback={() => {
                            changeIsMaster()
                        }}
                    />
                </div>
                <div className="select-type">
                    <h1 className="f16-500-dark">Nível de aprovação</h1>
                    <SelectInput
                        name="APPROVAL_LEVEL"
                        id="APPROVAL_LEVEL"
                        value={approvalLevelLabel()}
                        options={approvalLevelOptions}
                        onChange={(e) => {
                            if (e) {
                                changeApprovalLevel(e.value)
                            }
                        }}
                    />
                </div>
            </div>
            <div className="corporative-infos">
                <div className="company-info-header">
                    <div className="company-info-header--title">
                        <h1 className="f18-700-dark">
                            Quem aprova viagem para {member?.firstName}{' '}
                            {member?.lastName}
                        </h1>
                    </div>
                    {approvers
                        .sort((a, b) => a.level - b.level)
                        .map((approver) => {
                            return (
                                <div
                                    className="management-info"
                                    style={{ marginTop: '2rem' }}
                                    key={approver.level}
                                >
                                    <p className="f14-500-gray">
                                        Nível {approver.level}
                                    </p>
                                    <div className="row-button">
                                        <h1 className="f16-500-dark">
                                            {returnLinkedLabel(
                                                approver.users.map(
                                                    (o) => o.label
                                                ),
                                                'USER'
                                            )}
                                        </h1>
                                        <Button
                                            color="PRIMARY"
                                            buttonType="TEXT"
                                            text="Ver todos"
                                            onClick={() =>
                                                setOpenListMembers(
                                                    approver.users
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
            <div className="corporative-infos">
                <div className="company-info-header">
                    <div className="company-info-header--title">
                        <h1 className="f18-700-dark">
                            {member?.firstName} {member?.lastName} aprova
                            viagens para
                        </h1>
                    </div>
                </div>

                <div className="management-info" style={{ marginTop: '2rem' }}>
                    <div className="row-button">
                        <div className="title-values">
                            <h1 className="reference-title">Usuários</h1>
                            <h1 className="f16-500-gray">
                                {returnLinkedLabel(
                                    approvesFor.map((o) => o.label),
                                    'USER'
                                )}
                            </h1>
                        </div>

                        <Button
                            color="PRIMARY"
                            buttonType="TEXT"
                            text="Editar"
                            onClick={() => setOpenAddMembers(true)}
                        />
                    </div>
                </div>
                {companyManagementInfos.map((m) => {
                    return (
                        <div className="management-info" key={m.id}>
                            <div className="row-button">
                                <div className="title-values">
                                    <h1 className="reference-title">
                                        {m.label}
                                    </h1>
                                    <h1 className="f16-500-gray">
                                        {returnLinkedOptionsLabel(m.id)}
                                    </h1>
                                </div>
                                <Button
                                    color="PRIMARY"
                                    buttonType="TEXT"
                                    text="Editar"
                                    onClick={() => setOpenAddManagement(m.id)}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
            {openAddManagement && (
                <OptionsToAdd
                    isOpen={!!openAddManagement}
                    onRequestClose={() => setOpenAddManagement(undefined)}
                    managementId={openAddManagement}
                    addedOptions={
                        approvesForManagementInfo.find(
                            (m) => m.id === openAddManagement
                        )?.options
                    }
                    action="APPROVES_FOR"
                />
            )}
            <MembersToAdd
                isOpen={openAddMembers}
                onRequestClose={() => setOpenAddMembers(false)}
                usersAdded={approvesFor}
                action="APPROVES_FOR"
            />
            {openListMembers && (
                <ListMembers
                    isOpen={!!openListMembers}
                    onRequestClose={() => setOpenListMembers(undefined)}
                    usersAdded={openListMembers}
                    type="MY_APPROVERS"
                />
            )}
        </>
    )
}
