import React from 'react'
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select'
import { SelectInputContainer } from './styles'

interface Props extends SelectProps<OptionTypeBase> {
    name: string
    label?: string
    options: { value: number | string | boolean | undefined; label: string }[]
    size?: 'sm' | 'lg'
}

export function SelectInput({
    name,
    options,
    label,
    size = 'lg',
    ...rest
}: Props) {
    return (
        <SelectInputContainer>
            <label htmlFor={name}>
                {label ? (
                    <p
                        className={`${
                            size === 'sm'
                                ? 'f14-500-gray'
                                : 'f16-500-dark normal-label'
                        }`}
                    >
                        {label}
                    </p>
                ) : null}
            </label>
            <ReactSelect
                classNamePrefix="react-select"
                isSearchable={false}
                options={options}
                {...rest}
            />
        </SelectInputContainer>
    )
}
