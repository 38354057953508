export enum ExpenseHistoryAction {
    CREATED = 'CREATED',
    SENT_TO_APPROVAL = 'SENT_TO_APPROVAL',
    CONFIRMED = 'CONFIRMED',
    REJECTED = 'REJECTED',
    EDITED = 'EDITED',
    COMPLETED = 'COMPLETED',
    RETURNED = 'RETURNED',
    EXPENSE_REJECTED = 'EXPENSE_REJECTED',
}
