/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import ModalContainer from 'react-modal'
import { ClipLoader } from 'react-spinners'
import { RiRefreshLine } from 'react-icons/ri'
import { ControlButton } from '../../components/ControlButton'
import { CredentialSupplier } from '../../entities/active-supplier'
import { useCompany } from '../../hooks/use-company'
import { Supplier } from '../../interfaces/supplier'
import { InviteUserContainer } from './styles'
import { DynamicSupplierForm } from './DynamicSupplierForm'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    supplier: Supplier
}

export interface SupplierProps {
    onSend(bool: boolean): void
    busyBtn: boolean
    isEditMode: boolean
    supplierId: string
    resetEditation: () => void
    credentialId: string | undefined
}

export interface DynamicSupplierProps {
    onSend(bool: boolean): void
    busyBtn: boolean
    isEditMode: boolean
    supplierId: string
    resetEditation: () => void
    credentialId: string | undefined
    credentialFieldRules: Supplier['credentialFieldRules']
}

export function HandleCredential({ isOpen, onRequestClose, supplier }: Props) {
    // hooks
    const {
        activatedSuppliers,
        editSupplierStatus,
        editSupplierCredential,
        getActivePromoCode,
    } = useCompany()

    // state
    const [busyBtn, setBusyBtn] = useState(false)
    const [activeSupplier, setActiveSuplier] = useState(
        !!activatedSuppliers.find(
            (supplierId) => supplierId.supplierId === supplier.id
        )
    )
    const [createCredential, setCreateCredential] = useState(false)
    const [credential, setCredential] = useState<
        CredentialSupplier | undefined
    >(() => {
        const activatedSupplier = activatedSuppliers.find(
            (s) => s.supplierId === supplier.id
        )
        return activatedSupplier?.credential || undefined
    })
    const [editCredential, setEditCredential] = useState(false)
    const [activePromoCode, setActivePromoCode] = useState<
        'active' | 'inactive'
    >()
    const [loadingPromoCode, setLoadingPromoCode] = useState(false)

    const handleButton = (bool: boolean) => {
        setBusyBtn(bool)
    }

    async function handleActivePromoCode() {
        if (
            credential &&
            (supplier.code === 'G3' ||
                supplier.code === 'AD' ||
                supplier.code === 'WOOBA' ||
                supplier.code === 'LA' ||
                supplier.code === 'LA-NDC')
        ) {
            setLoadingPromoCode(true)
            const { id } = credential
            const response = await getActivePromoCode(id)
            setActivePromoCode(response?.valid ? 'active' : 'inactive')
            setLoadingPromoCode(false)
        }
    }

    useEffect(() => {
        handleActivePromoCode()
    }, [])

    const returnSupplierInputs = () => {
        return (
            <DynamicSupplierForm
                busyBtn={busyBtn}
                onSend={(bool) => handleButton(bool)}
                isEditMode={editCredential}
                supplierId={supplier.id}
                resetEditation={() => {
                    setEditCredential(false)
                    setCreateCredential(false)
                }}
                credentialId={credential ? credential.id : undefined}
                credentialFieldRules={supplier.credentialFieldRules}
            />
        )
    }

    useEffect(() => {
        setCredential(() => {
            const activatedSupplier = activatedSuppliers.find(
                (s) => s.supplierId === supplier.id
            )
            return activatedSupplier?.credential || undefined
        })
        setActiveSuplier(
            !!activatedSuppliers.find(
                (supplierId) => supplierId.supplierId === supplier.id
            )
        )
    }, [activatedSuppliers])

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button
                className="close"
                type="button"
                onClick={() => onRequestClose()}
            >
                X
            </button>
            <InviteUserContainer>
                <div className="header">
                    <div className="supplier-image">
                        <img src={supplier.photoUrl} alt="Supplier img" />
                    </div>
                    <div className="information">
                        <h1 className="f24-700-dark">{supplier.name}</h1>
                        <div className="tags">
                            {activeSupplier && <div className="tag">Ativo</div>}
                            {loadingPromoCode && (
                                <div>
                                    <ClipLoader color="black" size={16} />
                                </div>
                            )}
                            {!loadingPromoCode &&
                                activePromoCode === 'active' &&
                                credential?.status === 'ACTIVE' && (
                                    <button
                                        type="button"
                                        className="tag--active"
                                        onClick={() => handleActivePromoCode()}
                                    >
                                        Acordo aplicado
                                        <RiRefreshLine />
                                    </button>
                                )}
                            {!loadingPromoCode &&
                                activePromoCode === 'inactive' && (
                                    <button
                                        type="button"
                                        className="tag--error"
                                        onClick={() => handleActivePromoCode()}
                                    >
                                        Acordo não aplicado
                                        <RiRefreshLine />
                                    </button>
                                )}
                        </div>
                    </div>
                </div>
                <div className="modal-content">
                    <div className="title">
                        <h1 className="f16-500-gray-secondary">
                            Informações principais
                        </h1>
                    </div>
                    <div className="information-row">
                        <p className="f16-500-gray">Fornecedor habilitado?</p>
                        <ControlButton
                            isPressed={activeSupplier}
                            callback={() => {
                                setActiveSuplier((prevState) => !prevState)
                                editSupplierStatus(
                                    supplier.id,
                                    activeSupplier ? 'disable' : 'enable'
                                )
                            }}
                            type="SWITCH"
                            id="active-supplier"
                        />
                    </div>
                    <div className="information-row">
                        <p className="f16-500-gray">
                            Utilizar credênciais próprias?
                        </p>
                        {credential ? (
                            <ControlButton
                                isPressed={credential.status === 'ACTIVE'}
                                callback={() => {
                                    const { status } = credential
                                    editSupplierCredential({
                                        id: supplier.id,
                                        credentialId: credential.id,
                                        data: {
                                            status:
                                                status === 'ACTIVE'
                                                    ? 'INACTIVE'
                                                    : 'ACTIVE',
                                        },
                                    })
                                }}
                                type="SWITCH"
                                id="has-credential"
                            />
                        ) : (
                            <ControlButton
                                isPressed={createCredential}
                                callback={() => {
                                    setCreateCredential(
                                        (prevState) => !prevState
                                    )
                                }}
                                type="SWITCH"
                                id="has-credential"
                            />
                        )}
                    </div>
                </div>
                {(createCredential || editCredential) && activeSupplier ? (
                    <div className="modal-content">
                        <div className="title">
                            <h1 className="f16-500-gray-secondary">
                                Informações da credêncial
                            </h1>
                        </div>
                        {credential?.status === 'ACTIVE' && (
                            <div className="information-row">
                                <p className="f16-500-gray">
                                    Ativar modo de edição
                                </p>
                                <ControlButton
                                    isPressed={editCredential}
                                    callback={() => {
                                        setEditCredential(
                                            (prevState) => !prevState
                                        )
                                    }}
                                    type="SWITCH"
                                    id="edit-credential"
                                />
                            </div>
                        )}
                        <div className="form" />
                        {returnSupplierInputs()}
                    </div>
                ) : (
                    <div className="modal-content">
                        <div className="title">
                            <h1 className="f16-500-gray-secondary">
                                Informações da credêncial
                            </h1>
                        </div>

                        <div className="form">
                            {credential?.status === 'ACTIVE' && (
                                <div className="information-row">
                                    <p className="f16-500-gray">
                                        Ativar modo de edição
                                    </p>
                                    <ControlButton
                                        isPressed={editCredential}
                                        callback={() => {
                                            setEditCredential(
                                                (prevState) => !prevState
                                            )
                                        }}
                                        type="SWITCH"
                                        id="edit-credential"
                                    />
                                </div>
                            )}
                            <h1>
                                {credential?.status === 'ACTIVE'
                                    ? `Utilizando credencial própria de username: ${credential.username}`
                                    : 'Credencial default'}
                            </h1>
                        </div>
                    </div>
                )}
            </InviteUserContainer>
        </ModalContainer>
    )
}
