import { User } from '@sentry/react'
import React, { ReactNode, useState } from 'react'
import { FiEdit } from 'react-icons/fi'
import { useCompany } from '../../hooks/use-company'
// import { SubTabs } from '../../components/SubTabs'
// import { useCompanies } from '../../hooks/use-companies'
// import { useUsers } from '../../hooks/use-users'
import { ConfigurationContainerStyle } from './styles'

interface Props {
    children: ReactNode
    member: User
}

export function ConfigurationContainer({ children, member }: Props) {
    // hooks
    // const { user, editUserProfile } = useUsers()
    const { company } = useCompany()
    const [currentImage, setCurrentImage] = useState<string | undefined>(() => {
        if (member.photo) {
            return member.photo.url
        }
        return undefined
    })

    async function handlePickImage(file: File) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            if (reader.result) {
                setCurrentImage(reader.result.toString())
            }
        }
    }

    return (
        <ConfigurationContainerStyle>
            <div className="content">
                <div className="content-user-avatar">
                    <label
                        htmlFor="avatar-img-input"
                        className="content-user-avatar--img"
                    >
                        <figure className="avatar-image-shape">
                            {currentImage ? (
                                <img src={currentImage} alt="Foto" />
                            ) : (
                                <div className="avatar-div">
                                    <p>{`${member.firstName[0]}${member.lastName[0]}`}</p>
                                </div>
                            )}
                            <figcaption className="avatar-image-text">
                                <FiEdit />
                                Editar
                            </figcaption>
                        </figure>

                        <input
                            type="file"
                            id="avatar-img-input"
                            onChange={async (e) => {
                                if (e.target.files && e.target.files[0]) {
                                    handlePickImage(e.target.files[0])
                                    // await editUserProfile({}, e.target.files[0])
                                }
                            }}
                        />
                    </label>
                    <div className="content-user-avatar-info">
                        <h1 className="content-user-avatar-info--name">
                            {`${member.firstName} ${member.lastName}`}
                        </h1>
                        <h2 className="content-user-avatar-info--company">
                            {company && company.name}
                        </h2>
                        <h3 className="content-user-avatar-info--email">
                            {member.email}
                        </h3>
                    </div>
                </div>

                {children}
            </div>
        </ConfigurationContainerStyle>
    )
}
