/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-no-bind */
import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { MdApartment } from 'react-icons/md'
import { toast } from 'react-toastify'
import { InnerHeader } from '../../../../components/InnerHeader'
import { CompanyInfoContainer } from './styles'
import { getValidationErrors } from '../../../../utils/getValidationErrors'
import { Input } from '../../../../UnformInputs/Input'
import { useCompany } from '../../../../hooks/use-company'
import { GeneralSubPageContent } from '../styles'

export function CompanyInfo() {
    // hooks
    const { company } = useCompany()

    // state
    const [companyInfoForm] = useState<{
        name: string
        domain: string
        legalName: string
        document: string
    }>({
        name: company?.name || '',
        domain: company?.domain || '',
        legalName: company?.legalName || '',
        document:
            company?.documents.find((d) => d.type === 'CNPJ')?.value ?? '',
    })

    const formRef = useRef<FormHandles>(null)

    async function companyInfoHendler(data: any) {
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                name: Yup.string().required('Coloque um nome'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
        } catch (error) {
            toast.info('Verifique se você preencheu o campo')
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <GeneralSubPageContent>
            <CompanyInfoContainer>
                <InnerHeader
                    // btnLabel="Salvar"
                    title="Informações da empresa"
                    callback={() => {}}
                    icon={MdApartment}
                />
                <Form ref={formRef} onSubmit={companyInfoHendler}>
                    <div className="label-input">
                        <h1 className="label">
                            Nome{' '}
                            <span>
                                (visto internamente pelos agentes e usuários)
                            </span>
                        </h1>
                        <Input
                            name="name"
                            label="Nome"
                            value={companyInfoForm.name}
                        />
                    </div>
                    <div className="label-input">
                        <h1 className="label">
                            Domínio{' '}
                            <span>
                                (domínio do endereço de e-mails da sua empresa)
                            </span>
                        </h1>
                        <Input
                            name="domain"
                            label="Domínio"
                            value={companyInfoForm.domain}
                        />
                    </div>
                    <div className="label-input">
                        <h1 className="label">Razão social</h1>
                        <Input
                            name="legalName"
                            label="Razão social"
                            value={companyInfoForm.legalName}
                        />
                    </div>
                    <div className="label-input">
                        <h1 className="label">CNPJ</h1>
                        <Input
                            name="document"
                            label="CNPJ"
                            value={companyInfoForm.document}
                        />
                    </div>
                </Form>
            </CompanyInfoContainer>
        </GeneralSubPageContent>
    )
}
