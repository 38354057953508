import React, { useState } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { TitleDescriptionContainer } from './styles'

interface Props {
    info?: string
    title: string
}

export function LabelTooltip({ title, info }: Props) {
    // state
    const [displayInfo, setDisplayInfo] = useState(false)

    return (
        <TitleDescriptionContainer>
            <div className="title">
                <p className="f16-500-gray">{title}</p>
                {info && (
                    <>
                        <button
                            type="button"
                            onMouseEnter={() => setDisplayInfo(true)}
                            onMouseLeave={() => setDisplayInfo(false)}
                        >
                            <FaInfoCircle />
                            <div
                                className={`info left ${
                                    displayInfo ? 'active' : 'hidden'
                                }`}
                            >
                                {info}
                            </div>
                        </button>
                    </>
                )}
            </div>
        </TitleDescriptionContainer>
    )
}
