import React, { InputHTMLAttributes } from 'react'
import { InputContainer, InputContent } from './styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    normalLabel?: string
    label: string
    icon?: any
}

export function SearchInput({
    name,
    label,
    icon: Icon,
    normalLabel,
    ...rest
}: Props) {
    return (
        <InputContainer>
            {normalLabel && (
                <h2 className="f16-500-dark normal-label">{label}</h2>
            )}
            <InputContent>
                {Icon && <Icon className="search-icon" />}
                <input
                    id={name}
                    autoComplete="off"
                    formNoValidate
                    required
                    placeholder={normalLabel ? '' : label}
                    {...rest}
                />
            </InputContent>
        </InputContainer>
    )
}
