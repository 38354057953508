/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unused-prop-types */
import React, { ReactNode, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'

import { Button } from '../../../components/Button'
import { DropDownPoliticsContainer } from './styles'

interface Props {
    title: string
    description: string
    children: ReactNode
    icon: any
    callback?(): Promise<any>
}

export function DropDownPolitic({
    title,
    description,
    children,
    icon: Icon,
    callback,
}: Props) {
    // hooks
    const [openDropDownPolitic, setOpenDropDownPlotic] = useState(false)
    return (
        <DropDownPoliticsContainer>
            <button
                type="button"
                className="dropdown-header"
                onClick={() => {
                    setOpenDropDownPlotic((prev) => !prev)
                }}
            >
                <div className="title-description">
                    <div className="title">
                        <Icon />
                        <h1>{title}</h1>
                    </div>
                    {openDropDownPolitic && <p>{description}</p>}
                </div>
                {!openDropDownPolitic ? (
                    <div className="arrow-btn">
                        <FiChevronDown />
                    </div>
                ) : (
                    <div className="btn-action">
                        <Button
                            type="button"
                            buttonType="FILLED"
                            color="PRIMARY"
                            text="Salvar"
                            onClick={async (e) => {
                                e.stopPropagation()
                                callback && (await callback())
                            }}
                        />
                    </div>
                )}
            </button>
            <div>{openDropDownPolitic && children}</div>
        </DropDownPoliticsContainer>
    )
}
