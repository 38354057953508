import styled from 'styled-components'

export const PolicieContainer = styled.div`
    .form > div:not(:last-child) {
        margin-bottom: 1.5rem;
    }
    .form {
        &--grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1.5rem;
        }
        &--grid-3 {
            display: grid;
            grid-template-columns: 3fr 1fr;
            grid-gap: 1.5rem;
        }
    }
`
