/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import ModalContainer from 'react-modal'
import { IoMdSearch } from 'react-icons/io'
import { BsFillXCircleFill, BsFillPlusCircleFill } from 'react-icons/bs'
import { v4 } from 'uuid'
import { toast } from 'react-toastify'
import { AddTravalersInTripContainer, Traveler } from './styles'
import { Avatar } from '../../components/Avatar'
import { SearchInput } from '../../components/SearchInput'
import { Button } from '../../components/Button'
import { usePayment } from '../../hooks/use-payment'
import { PaymentMethod } from '../../entities/payment-method'
import { useCompany } from '../../hooks/use-company'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    list: { label: string; id: string; url?: string }[]
    listToAdd: { label: string; id: string; url?: string }[]
    type: string
    id: string
    paymentMethod?: PaymentMethod
}

export function AddList({
    isOpen,
    onRequestClose,
    list,
    listToAdd,
    type,
    id,
    paymentMethod,
}: Props) {
    // hooks
    const { editPaymentMethod, elementToAddSettings } = usePayment()
    const { company, userIdsToAddInOptionHandler, editOption } = useCompany()

    // state
    const [searchTraveler, setSearchTraveler] = useState('')
    const [addedElements, setAddedElements] = useState<
        { label: string; id: string; url?: string }[]
    >(() => {
        return [...list]
    })
    const [elementsToAdd, setElementsToAdd] = useState<
        { label: string; id: string; url?: string }[]
    >(() => {
        return listToAdd.map((listData) => {
            return {
                id: listData.id,
                label: listData.label,
                url: listData.url,
            }
        })
    })

    async function editPaymentMethodHandler(ids: string[]) {
        const response = await editPaymentMethod(
            {
                companyInfo: { userIds: ids },
            },
            id
        )
        if (response) {
            toast.success('Forma de pagamento editada com sucesso')
            onRequestClose()
        }
    }

    async function editManagementPaymentMethodHandler(
        data: { id: string; options: string[] }[],
        paymnetId: string
    ) {
        const response = await editPaymentMethod(
            {
                companyInfo: { managementInfo: data },
            },
            paymnetId
        )
        if (response) {
            toast.success('Forma de pagamento editada com sucesso')
            onRequestClose()
        }
    }

    async function editOptionHandler(
        data: { label: string; externalId: string },
        ids: string[],
        managementId: string,
        optionId: string
    ) {
        const response = await editOption(managementId, optionId, {
            ...data,
            userIds: ids,
        })
        if (response) {
            toast.success('Campo gerencials vinculados a usuários com sucesso')
        }
    }

    function addedListHandler() {
        if (type === 'ADD_USER_TO_PAYMENT') {
            const usersId = addedElements.map((element) => element.id)
            editPaymentMethodHandler(usersId)
        }
        if (type === 'ADD_OPTION_TO_PAYMENT' && paymentMethod) {
            const { managementInfo } = paymentMethod.companyInfo
            const dataInfo: { id: string; options: string[] }[] = []
            dataInfo.push({ id, options: addedElements.map((e) => e.id) })
            const filteredManagement = managementInfo.filter((m) => m.id !== id)
            if (filteredManagement.length > 0) {
                filteredManagement.forEach((f) => {
                    dataInfo.push({
                        id: f.id,
                        options: f.options.map((o) => o.id),
                    })
                })
            }
            editManagementPaymentMethodHandler(dataInfo, paymentMethod.id)
        }
        if (type === 'ADD_USER_TO_CREATE_OPTION') {
            userIdsToAddInOptionHandler(addedElements.map((e) => e.id))
            onRequestClose()
        }
        if (
            type === 'ADD_USER_TO_OPTION' &&
            elementToAddSettings.option &&
            elementToAddSettings.managementId
        ) {
            editOptionHandler(
                {
                    externalId: elementToAddSettings.option.externalId,
                    label: elementToAddSettings.option.label,
                },
                addedElements.map((e) => e.id),
                elementToAddSettings.managementId,
                elementToAddSettings.option.id
            )
        }
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button
                className="close"
                type="button"
                onClick={() => onRequestClose()}
            >
                X
            </button>
            <AddTravalersInTripContainer>
                <div className="modal-content">
                    <div className="title">
                        <h1 className="f16-500-gray-secondary">
                            Adicionar passageiros.
                        </h1>
                    </div>
                    <SearchInput
                        label="Pesquisar"
                        name="search-traveler"
                        icon={IoMdSearch}
                        value={searchTraveler}
                        onChange={(e) => setSearchTraveler(e.target.value)}
                    />
                    {!searchTraveler ? (
                        <>
                            <div className="added-travelers">
                                <h1 className="f14-700-secondary-gray added-travelers--title">
                                    VIAJANTES ADICIONADOS
                                </h1>
                                {addedElements.map((element) => {
                                    return (
                                        <Traveler key={v4()}>
                                            <div className="traveler-info">
                                                <div className="traveler-info--logo">
                                                    <Avatar
                                                        label={element.label}
                                                        img={
                                                            element.url
                                                                ? element.url
                                                                : undefined
                                                        }
                                                    />
                                                </div>
                                                <div className="traveler-info--name">
                                                    <p className="f16-500-gray">
                                                        {element.label}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                className="action-button remove"
                                                type="button"
                                                onClick={() => {
                                                    setAddedElements(
                                                        (prevState) => {
                                                            const state =
                                                                prevState.filter(
                                                                    (prev) =>
                                                                        prev.id !==
                                                                        element.id
                                                                )

                                                            return state
                                                        }
                                                    )
                                                }}
                                            >
                                                <BsFillXCircleFill />
                                            </button>
                                        </Traveler>
                                    )
                                })}
                            </div>
                            <div className="add-travalers">
                                <h1 className="f14-700-secondary-gray add-travelers--title">
                                    ADICIONAR VIAJANTE
                                </h1>
                                {elementsToAdd.map((element) => {
                                    if (
                                        addedElements.find(
                                            (listElement) =>
                                                element.id === listElement.id
                                        )
                                    ) {
                                        return null
                                    }
                                    return (
                                        <Traveler key={v4()}>
                                            <div className="traveler-info">
                                                <div className="traveler-info--logo">
                                                    <Avatar
                                                        label={element.label}
                                                        img={
                                                            element.url
                                                                ? element.url
                                                                : undefined
                                                        }
                                                    />
                                                </div>
                                                <div className="traveler-info--name">
                                                    <p className="f16-500-gray">
                                                        {element.label}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                className="action-button add"
                                                type="button"
                                                onClick={() => {
                                                    setAddedElements(
                                                        (prevState) => [
                                                            ...prevState,
                                                            element,
                                                        ]
                                                    )
                                                }}
                                            >
                                                <BsFillPlusCircleFill />
                                            </button>
                                        </Traveler>
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <div className="add-travalers">
                            <h1 className="f14-700-secondary-gray add-travelers--title">
                                RESULTADOS
                            </h1>
                            {elementsToAdd.map((element) => {
                                if (
                                    addedElements.find(
                                        (elementList) =>
                                            element.id === elementList.id
                                    )
                                ) {
                                    return null
                                }
                                if (
                                    element.label
                                        .toLowerCase()
                                        .includes(searchTraveler.toLowerCase())
                                ) {
                                    return (
                                        <Traveler key={v4()}>
                                            <div className="traveler-info">
                                                <div className="traveler-info--logo">
                                                    <Avatar
                                                        label={element.label}
                                                        img={
                                                            element.url
                                                                ? element.url
                                                                : undefined
                                                        }
                                                    />
                                                </div>
                                                <div className="traveler-info--name">
                                                    <p className="f16-500-gray">
                                                        {element.label}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                className="action-button add"
                                                type="button"
                                                onClick={() => {
                                                    setAddedElements(
                                                        (prevState) => [
                                                            ...prevState,
                                                            element,
                                                        ]
                                                    )
                                                }}
                                            >
                                                <BsFillPlusCircleFill />
                                            </button>
                                        </Traveler>
                                    )
                                }
                                return null
                            })}
                        </div>
                    )}
                </div>
                <div className="save-btn">
                    <Button
                        buttonType="FILLED"
                        text="Salvar"
                        color="PRIMARY"
                        type="button"
                        onClick={() => addedListHandler()}
                    />
                </div>
            </AddTravalersInTripContainer>
        </ModalContainer>
    )
}
