import styled from 'styled-components'

export const LostEconomiesContainer = styled.div`
    padding: 3rem 3rem 3rem 1.5rem;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    display: flex;
    align-items: center;
    .image {
        width: 19rem;
        height: 8rem;
        margin-right: 1.8rem;
        img {
            height: 100%;
        }
    }
    .info {
        .margin {
            margin: 1.2rem 0 0.8rem 0;
        }
    }
`
