import React from 'react'
import { ProviderCardContainer } from './styles'
import { Supplier } from '../../../../interfaces/supplier'
import { ControlButton } from '../../../../components/ControlButton'
import { useCompany } from '../../../../hooks/use-company'

interface Props {
    supplier: Supplier
    chooseSupplierToOpen(supplier: Supplier): void
}

export function ProviderCard({ supplier, chooseSupplierToOpen }: Props) {
    const { activatedSuppliers, editSupplierStatus } = useCompany()

    const isActive = !!activatedSuppliers.find(
        (a) => a.supplierId === supplier.id
    )

    return (
        <ProviderCardContainer>
            <button
                className="provider-card"
                onClick={() => chooseSupplierToOpen(supplier)}
                type="button"
            >
                <img src={supplier.photoUrl} alt={supplier.name} />
                <h1>{supplier.name}</h1>
            </button>
            <div className="supplier-switcher">
                {/* <button
                    className="checkin-btn"
                    type="button"
                    onClick={() => getActivePromoCode(supplier.id)}
                >
                    Checar acordo
                </button> */}

                <ControlButton
                    id={supplier.id}
                    type="SWITCH"
                    isPressed={isActive}
                    callback={() =>
                        editSupplierStatus(
                            supplier.id,
                            isActive ? 'disable' : 'enable'
                        )
                    }
                />
            </div>
        </ProviderCardContainer>
    )
}
