import styled from 'styled-components'

export const ReportsContainer = styled.div`
    width: 100%;
`

export const ReportsContent = styled.div`
    width: 110rem;
    margin: 0 auto;
    .tabs-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5rem 0;
    }
    .filter {
        margin-bottom: 2.6rem;
    }
    .divider {
        width: 100%;
        background-color: #dedede;
        height: 2px;
    }
    .title {
        margin-top: 3.2rem;
    }
    .description {
        margin-top: 0.4rem;
        font-style: italic;
    }
    .buttons-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5rem;
        .selector {
            width: 27rem;
        }
        .buttons {
            display: flex;
            align-items: center;
            button {
                width: 22rem;
                &:first-child {
                    margin-right: 1rem;
                }
            }
        }
    }
    .table-data {
        margin-top: 1.5rem;
    }
    .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .no-data {
        padding: 3rem;
        background-color: #fff;
        text-align: center;
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
    }
`
export const PaginationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 2rem 0;
`
