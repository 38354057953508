/* eslint-disable import/no-unresolved */
/* eslint-disable prettier/prettier */
import Amex from '../assets/credit-cards/amex.png'
import Elo from '../assets/credit-cards/elo.png'
import Jcb from '../assets/credit-cards/jcb.png'
import Mastercard from '../assets/credit-cards/mastercard.png'
import Visa from '../assets/credit-cards/visa.png'
import Eia from '../assets/credit-cards/eia.png'
import Invoice from '../assets/credit-cards/invoice.png'


export function chooseImagePaymentMethod(type?: string) {
  if (type === 'Visa') {
    return Visa
  }
  if (type === 'Mastercard' || type === 'Maestro') {
    return Mastercard
  }
  if (type === 'JCB') {
    return Jcb
  }
  if (type === 'Elo') {
    return Elo
  }
  if (type === 'American Express') {
    return Amex
  }
  if (type === 'INVOICE') {
    return Invoice
  }
  return Eia
}
