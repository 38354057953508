/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { MdCategory } from 'react-icons/md'
import { useCompany } from '../../../hooks/use-company'
import { Supplier } from '../../../interfaces/supplier'
import { HandleCredential } from '../../../modals/HandleCredential'
import { ProviderCard } from './ProviderCard'
import { ProvidersSubPageContainer, ProvidersSubPageContent } from './styles'

export function ProvidersSubPage() {
    const { getSuppliers, suppliers, getActiveSupliers } = useCompany()

    const [busy, setBusy] = useState(false)
    const [openedSuplier, setOpenedSuplier] = useState<Supplier>()

    async function getAllSuppiers() {
        setBusy(true)
        await getSuppliers()
        await getActiveSupliers()
        setBusy(false)
    }

    useEffect(() => {
        getAllSuppiers()
    }, [])

    function chooseSupplierToOpen(supplier: Supplier) {
        setOpenedSuplier(supplier)
    }

    return (
        <ProvidersSubPageContainer>
            <div className="providers-info">
                <div className="card">
                    <MdCategory />
                    <div className="description">
                        <h1>Fornecedores</h1>
                        <p>
                            Bloqueie, insira acordos e dê preferência para os
                            fornecedores parceiros da ADA Travel.
                        </p>
                    </div>
                </div>
                <div className="verify-btn" />
            </div>
            <ProvidersSubPageContent>
                <h1 className="title">Aéreos</h1>
                <div className="providers-cards">
                    {suppliers
                        .filter((supplier) => supplier.type === 'AIRLINE')
                        .map((supplier) => {
                            return (
                                <ProviderCard
                                    chooseSupplierToOpen={chooseSupplierToOpen}
                                    supplier={supplier}
                                />
                            )
                        })}
                </div>
                <h1 className="title">Carros</h1>
                <div className="providers-cards">
                    {suppliers
                        .filter((supplier) => supplier.type === 'CAR')
                        .map((supplier) => {
                            return (
                                <ProviderCard
                                    chooseSupplierToOpen={chooseSupplierToOpen}
                                    supplier={supplier}
                                />
                            )
                        })}
                </div>
                <h1 className="title">Hospedagem</h1>
                <div className="providers-cards">
                    {suppliers
                        .filter((supplier) => supplier.type === 'HOTEL')
                        .map((supplier) => {
                            return (
                                <ProviderCard
                                    chooseSupplierToOpen={chooseSupplierToOpen}
                                    supplier={supplier}
                                />
                            )
                        })}
                </div>
                <h1 className="title">Pagamentos</h1>
                <div className="providers-cards">
                    {suppliers
                        .filter((supplier) => supplier.type === 'PAYMENT')
                        .map((supplier) => {
                            return (
                                <ProviderCard
                                    chooseSupplierToOpen={chooseSupplierToOpen}
                                    supplier={supplier}
                                />
                            )
                        })}
                </div>
            </ProvidersSubPageContent>
            {openedSuplier && (
                <HandleCredential
                    isOpen
                    onRequestClose={() => setOpenedSuplier(undefined)}
                    supplier={openedSuplier}
                />
            )}
        </ProvidersSubPageContainer>
    )
}
