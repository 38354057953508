import styled from 'styled-components'

export const ManagementSubPageContainer = styled.div`
    display: grid;
    grid-template-columns: 28rem 80rem 28rem;
    grid-gap: 2rem;
    align-items: flex-start;
    justify-content: center;
    .side-menu {
        margin-bottom: 1rem;
        .add-management-info {
            padding: 2rem 1rem;
            margin-bottom: 1rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            h2 {
                color: var(--color-primary);
                font-size: 1.6rem;
                font-weight: 500;
            }
            svg {
                margin-right: 1rem;
                color: var(--color-primary);
            }
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
            border-radius: 8px;
            border: none;
        }
    }
`
export const ManagementSubPageContent = styled.div`
    .management-info {
        padding: 3.5rem 2.5rem;
        background-color: #fff;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        margin-bottom: 8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .verify-btn {
            button {
                padding: 1.5rem 3rem;
            }
        }
        .card {
            width: 100%;
            display: flex;
            align-items: flex-start;
            svg {
                height: 2rem;
                width: 2rem;
                margin-right: 2rem;
            }
            .description {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .isMandatory {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    gap: 1rem;
                }
                h1 {
                    color: #191b1e;
                    font-size: 2.2rem;
                    font-weight: 700;
                    margin-bottom: 0.8rem;
                }
                p {
                    color: #646669;
                    font-weight: 500;
                    font-size: 1.6rem;
                }
            }
        }
    }
    .create-management-info {
        padding: 3.5rem 2.5rem;
        background-color: #fff;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        margin-bottom: 8rem;
        .card {
            display: flex;
            align-items: flex-start;
            svg {
                height: 2rem;
                width: 2rem;
                margin-right: 2rem;
            }
            .description {
                h1 {
                    color: #191b1e;
                    font-size: 2.2rem;
                    font-weight: 700;
                    margin-bottom: 0.8rem;
                }
                p {
                    color: #646669;
                    font-weight: 500;
                    font-size: 1.6rem;
                }
            }
        }
        form {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 4rem;
            margin: 3rem 0;
            .input {
                width: 100%;
            }
            button {
                width: 15rem;
                margin-left: 2rem;
            }
        }
    }

    .filters {
        height: 5rem;
        width: 100%;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`
