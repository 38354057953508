import React from 'react'
import { BrokenPoliciesReasonContainer } from './styles'

interface Props {
    category: number
    daysInAdvance: number
    price: number
}

export function BrokenPoliciesReason({
    category,
    daysInAdvance,
    price,
}: Props) {
    function widthBar(num: number) {
        return (num / 100) * 30
    }

    return (
        <BrokenPoliciesReasonContainer>
            <div className="broken-title">
                <h1 className="f16-700-dark">Motivos de quebra de política</h1>
            </div>
            <div className="progressive-bar-container">
                <div className="progressive-bar-label">
                    <div className="progressive-bar">
                        <div
                            className="filled"
                            style={{ width: `${widthBar(price)}rem` }}
                        />
                    </div>
                    <div className="label">
                        <p className="f16-500-dark">Preço</p>
                    </div>
                </div>
                <p className="f16-500-dark">{price.toFixed(2)}%</p>
            </div>
            <div className="progressive-bar-container">
                <div className="progressive-bar-label">
                    <div className="progressive-bar">
                        <div
                            className="filled"
                            style={{ width: `${widthBar(daysInAdvance)}rem` }}
                        />
                    </div>
                    <div className="label">
                        <p className="f16-500-dark">Antecedência</p>
                    </div>
                </div>
                <p className="f16-500-dark">{daysInAdvance.toFixed(2)}%</p>
            </div>
            <div className="progressive-bar-container">
                <div className="progressive-bar-label">
                    <div className="progressive-bar">
                        <div
                            className="filled"
                            style={{ width: `${widthBar(category)}rem` }}
                        />
                    </div>
                    <div className="label">
                        <p className="f16-500-dark">Categoria</p>
                    </div>
                </div>
                <p className="f16-500-dark">{category.toFixed(2)}%</p>
            </div>
        </BrokenPoliciesReasonContainer>
    )
}
