/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { v4 } from 'uuid'
import { DropdownFilterContainer, Selector } from './styles'

interface Props {
    items: { label: string; value: string }[]
    selectedItems: string[]
    handleSelectItems: (items: string[]) => void
    name: string
}

export function DynamicDropdownFilter({
    items,
    selectedItems,
    handleSelectItems,
    name,
}: Props) {
    const [selectedDrop, setSelectedDrop] = useState<boolean>(false)

    function selectLabels() {
        if (selectedItems.length > 0) {
            return true
        }
        return false
    }

    return (
        <DropdownFilterContainer>
            <label
                htmlFor={name}
                className={`selector-label ${selectLabels() ? 'bordered' : ''}`}
            >
                <input
                    type="checkbox"
                    name={name}
                    checked={selectedDrop}
                    id={name}
                    className="selector-input"
                    onChange={(e) => setSelectedDrop(e.target.checked)}
                />
                {selectLabels() ? <p>{name}</p> : <p>{name}</p>}
                {selectLabels() ? (
                    <AiFillCloseCircle
                        style={{ height: '1.6rem', width: '1.6rem' }}
                        onClick={() => {
                            handleSelectItems([])
                        }}
                    />
                ) : (
                    <></>
                )}
                <div className="selectors">
                    <div className="title-dropdown">
                        <h1>{name}</h1>
                        <div className="btns">
                            <button
                                className="select-all"
                                type="button"
                                onClick={() => {
                                    handleSelectItems(
                                        items.map((item) => item.value)
                                    )
                                }}
                            >
                                Selecionar todos
                            </button>
                            <button
                                className="clear-all"
                                type="button"
                                onClick={() => {
                                    handleSelectItems([])
                                }}
                            >
                                Limpar
                            </button>
                        </div>
                    </div>

                    {items.map((item) => {
                        return (
                            <Selector key={v4()}>
                                <label
                                    htmlFor={`center-${item.value}`}
                                    className="select-label"
                                >
                                    <input
                                        type="checkbox"
                                        id={`center-${item.value}`}
                                        className="center"
                                        checked={selectedItems.includes(
                                            item.value
                                        )}
                                        onChange={() => {
                                            if (
                                                selectedItems.includes(
                                                    item.value
                                                )
                                            ) {
                                                handleSelectItems(
                                                    selectedItems.filter(
                                                        (i) => i !== item.value
                                                    )
                                                )
                                            } else {
                                                handleSelectItems([
                                                    ...selectedItems,
                                                    item.value,
                                                ])
                                            }
                                        }}
                                    />
                                    <p>{item.label}</p>
                                </label>
                            </Selector>
                        )
                    })}
                </div>
            </label>
            {selectedDrop && (
                <div
                    className="disabled"
                    onClick={() => {
                        setSelectedDrop(false)
                    }}
                />
            )}
        </DropdownFilterContainer>
    )
}
