import styled from 'styled-components'

export const SelectInputContainer = styled.div`
    min-width: 18rem;
    label {
        p {
            margin-bottom: 1rem;
        }
    }
    .react-select__control {
        height: 5rem;
        border: 1px solid #c8cacd;
        border-radius: 0.8rem;
    }
    .css-1pahdxg-control:hover,
    .css-1pahdxg-control {
        border-color: #c8cacd;
        box-shadow: none;
    }
`
