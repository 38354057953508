/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import ModalContainer from 'react-modal'
import { ExpensesModalContainer } from './styles'
import { Expenses } from '../../interfaces/expenses'
import { historyDateFormatter } from '../../utils/expensnes/dateFormatter'
import { ExpenseHistoryAction } from '../../enums/expense-history.enum'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    history: Expenses['history']
}

export default function HistoryModal({
    isOpen,
    onRequestClose,
    history,
}: Props) {
    function getLabelStatus(status: ExpenseHistoryAction) {
        if (status === ExpenseHistoryAction.SENT_TO_APPROVAL) {
            return <h2>Enviado para aprovação</h2>
        }
        if (status === ExpenseHistoryAction.CONFIRMED) {
            return <h2>Aprovado</h2>
        }
        if (status === ExpenseHistoryAction.REJECTED) {
            return <h2>Rejeitado</h2>
        }

        if (status === ExpenseHistoryAction.CREATED) {
            return <h2>Criado</h2>
        }

        if (status === ExpenseHistoryAction.COMPLETED) {
            return <h2>Conciliado</h2>
        }
        if (status === ExpenseHistoryAction.EXPENSE_REJECTED) {
            return <h2>Despesa rejeitada</h2>
        }
        if (status === ExpenseHistoryAction.RETURNED) {
            return <h2>Retornado</h2>
        }
        return ''
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-history"
        >
            <ExpensesModalContainer>
                <div className="content-block">
                    <p className="f16-500-gray italic">Histórico da despesa</p>
                    <div className="container">
                        {history.map((item, index) => {
                            return (
                                <div className="label-value" key={index}>
                                    <p className="f16-500-gray">
                                        {getLabelStatus(item.action)}
                                    </p>
                                    {item.user && (
                                        <div className="row">
                                            {item.travelAgentId && (
                                                <div className="avatar">
                                                    <h1>TA</h1>
                                                </div>
                                            )}
                                            <p className="f16-500-gray">
                                                {`${item.user.firstName} ${item.user.lastName}`}
                                            </p>
                                        </div>
                                    )}
                                    <p className="f16-500-dark">
                                        {historyDateFormatter(item.datetime)}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </ExpensesModalContainer>
        </ModalContainer>
    )
}
