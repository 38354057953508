import styled from 'styled-components'

export const CompanyInfoContainer = styled.div`
    width: 100%;
    .company-info {
        border-radius: 0.8rem;
        background-color: var(--color-light);
        margin-bottom: 1.5rem;
        &-header {
            width: 100%;
            display: grid;
            grid-template-columns: 4fr 2fr;
            padding: 2rem;
            border-bottom: 1px solid var(--color-background);
            &--title {
                width: 70rem;
            }
        }
        &-form {
            padding: 2.5rem;
            &--grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 1.5rem;
                margin-bottom: 1.5rem;
            }
            &--input {
                margin-bottom: 1.5rem;
            }
        }
    }
`
