/* eslint-disable import/no-duplicates */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { Pagination } from '@material-ui/lab'
import { useAutosave } from 'react-autosave'
import { MdSearch } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import { AiFillDollarCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR'
import { format } from 'date-fns'
import { ExpensesContainer } from './styles'
import { SubTabs } from '../../../components/SubTabs'
import { useExpenses } from '../../../hooks/use-expenses'
import ExpenseCard from '../../../components/ExpenseCard'
import ExpensesModal from '../../../modals/ExpensesModal'
import { PaginationContainer } from '../../Reports/styles'
import HistoryModal from '../../../modals/HistoryModal'
import { useCompany } from '../../../hooks/use-company'
import { ExpensePaymentMethod } from '../../../enums/expense-payment-method.enum'
import { FilterInput } from '../../../components/FilterInput'
import { FilterInputBtn } from '../../../components/FilterInputBtn'
import { ChoseOnneMemberModal } from '../../../modals/ChoseOnneMemberModal'
import { ExpenseStatus } from '../../../enums/expenses-status.enum'
import { Expenses as ExpensesType } from '../../../interfaces/expenses'
import { ControlButton } from '../../../components/ControlButton'
import { priceFormatter } from '../../../utils/expensnes/priceFormatter'
import { Button } from '../../../components/Button'
import ApprovalStagesModal from '../../../modals/ApprovalStagesModal'
import QrCodeDataModal from '../../../modals/QrCodeDataModal'
import OcrDataModal from '../../../modals/OcrDataModal'
import { expensesPaymentLabel } from '../../../utils/expensnes/ExpensesIconsHandler'
import { ExpensesDateFilter } from '../../../components/ExpensesDateFilter'
import { SelectInput } from '../../../components/SelectInput'
import { usePayment } from '../../../hooks/use-payment'
import { DynamicDropdownFilter } from '../../../components/DynamicDropDownFilter'
import { PaymentMethod } from '../../../entities/payment-method'

export default function Expenses() {
    const {
        openModal,
        openModalHandler,
        getExpenses,
        expenses,
        count,
        openHistoryModal,
        openApprovalStagesModal,
        isHistoryModalOpen,
        isApprovalStagesModalOpen,
        completeExpensesInBatch,
        openQrCodeDataModal,
        isQrCodeDataModalOpen,
        isOcrDataModalOpen,
        openOcrDataModal,
        expensesDateFilter,
    } = useExpenses()

    const { getPaymentMethods, paymentMethods } = usePayment()

    const { members, getMembers } = useCompany()
    const { company } = useCompany()
    const [tabValue, setTabValue] = useState<ExpenseStatus>(
        () => ExpenseStatus.CONFIRMED
    )

    const [pageSelected, setPageSelected] = useState<number>(1)
    const [numberIdentifier, setNumberIdentifier] = useState('')
    const [busy, setBusy] = useState(false)
    const [expensesPaymentType, setExpensesPaymentType] = useState<
        ExpensePaymentMethod[]
    >([])
    const [creatorId, setCreatorId] = useState<string | undefined>()
    const [openCreatorModal, setOpenCreatorModal] = useState<boolean>(false)
    const [selectedExpenses, setSelectedExpenses] = useState<ExpensesType[]>([])
    const [buttonBusy, setButtonBusy] = useState(false)
    const [selectedPaymentType, setSelectedPaymentType] = useState<
        { label: string; value: string } | undefined
    >()

    function numberOfPages(countNum: number) {
        return Math.ceil(countNum / 10)
    }

    function changeTabValue(type: ExpenseStatus) {
        setTabValue(type)
    }

    function expenseAmount(expense: ExpensesType) {
        if (expense.type === 'MILEAGE') {
            return expense.info.amountDue || 0
        }
        if (
            expense.info.currency?.code &&
            expense.info.currency?.code !== 'BRL'
        ) {
            return expense.info.amount || 0
        }
        if (expense.info.amountInBRL) {
            return expense.info.amountInBRL
        }
        return expense.info.amount || 0
    }

    let paymentOptions = [
        {
            label: expensesPaymentLabel(ExpensePaymentMethod.CASH),
            value: ExpensePaymentMethod.CASH,
        },
        {
            label: expensesPaymentLabel(ExpensePaymentMethod.NON_REFUNDABLE),
            value: ExpensePaymentMethod.NON_REFUNDABLE,
        },
        {
            label: expensesPaymentLabel(ExpensePaymentMethod.ADVANCE_MONEY),
            value: ExpensePaymentMethod.ADVANCE_MONEY,
        },
    ]

    if (tabValue === ExpenseStatus.CONFIRMED_CREDIT_CARD) {
        paymentOptions = paymentOptions.filter(
            (option) => option.value !== ExpensePaymentMethod.CASH
        )
    }

    function paymentmethodsFilter() {
        if (tabValue === ExpenseStatus.CONFIRMED) {
            return [ExpensePaymentMethod.CASH]
        }
        if (tabValue === ExpenseStatus.CONFIRMED_CREDIT_CARD) {
            if (expensesPaymentType.length === 0) {
                return [
                    ExpensePaymentMethod.NON_REFUNDABLE,
                    ExpensePaymentMethod.ADVANCE_MONEY,
                ]
            }
            return expensesPaymentType
        }
        return expensesPaymentType
    }

    function paymentLabel(payment: PaymentMethod) {
        if (payment.creditCard) {
            return `${payment.creditCard.cardBrand} - ${payment.label} ...${payment.creditCard.lastFourDigits}`
        }
        return 'Faturado'
    }

    useEffect(() => {
        if (company) {
            ;(async () => {
                await getMembers(company.id)
                await getPaymentMethods()
            })()
        }
    }, [tabValue])

    useEffect(() => {
        if (company) {
            ;(async () => {
                setBusy(true)
                await getExpenses(
                    tabValue === ExpenseStatus.CONFIRMED_CREDIT_CARD
                        ? ExpenseStatus.CONFIRMED
                        : tabValue,
                    creatorId,
                    numberIdentifier,
                    paymentmethodsFilter(),
                    (pageSelected - 1) * 10,
                    10,
                    company.id,
                    selectedPaymentType?.value === 'ALL'
                        ? undefined
                        : selectedPaymentType?.value
                )
                setBusy(false)
            })()
        }
    }, [
        tabValue,
        pageSelected,
        creatorId,
        expensesPaymentType,
        numberIdentifier,
        expensesDateFilter,
        selectedPaymentType,
    ])

    useEffect(() => {
        setPageSelected(1)
    }, [tabValue])

    useAutosave({
        data: numberIdentifier,
        onSave: async (_value) => {
            if (company) {
                setPageSelected(1)
                setBusy(true)
                const data = await getExpenses(
                    tabValue === ExpenseStatus.CONFIRMED_CREDIT_CARD
                        ? ExpenseStatus.CONFIRMED
                        : tabValue,
                    creatorId,
                    numberIdentifier,
                    paymentmethodsFilter(),
                    (pageSelected - 1) * 10,
                    10,
                    company.id
                )
                setBusy(false)
            }
        },
        interval: 500,
    })

    function hasBatchLogic() {
        if (tabValue === ExpenseStatus.CONFIRMED && creatorId) {
            return true
        }
        return false
    }

    return (
        <ExpensesContainer>
            <div className="title">
                <h2 className="f22-700-dark">Despesas</h2>
                <p className="f16-500-gray">
                    Concilie e confira os reembolsos de despesas realizadas
                    pelos seus viajantes.
                </p>
            </div>
            <div className="tabs">
                <SubTabs
                    direction="HORIZONTAL"
                    changeTab={changeTabValue}
                    value={tabValue}
                    tabs={[
                        {
                            label: 'Aguardando reembolso',
                            id: ExpenseStatus.CONFIRMED,
                        },
                        {
                            label: 'Aguardando conciliação',
                            id: ExpenseStatus.CONFIRMED_CREDIT_CARD,
                        },
                        {
                            label: 'Concluidas',
                            id: ExpenseStatus.COMPLETED,
                        },
                    ]}
                />
                <SubTabs
                    direction="HORIZONTAL"
                    changeTab={changeTabValue}
                    value={tabValue}
                    tabs={[
                        {
                            label: 'Aguardando aprovação',
                            id: ExpenseStatus.PENDING_APPROVAL,
                        },
                        {
                            label: 'Ainda não enviadas',
                            id: ExpenseStatus.CREATED,
                        },
                    ]}
                />
            </div>
            <div className="first-filter">
                <div className="filters">
                    <ExpensesDateFilter />
                    <FilterInputBtn
                        label="Criador do item"
                        normalLabel={false}
                        placeHolder="Criador do item"
                        value={
                            members.find((user) => user.id === creatorId)
                                ? `${
                                      members.find(
                                          (user) => user.id === creatorId
                                      )?.firstName
                                  } ${
                                      members.find(
                                          (user) => user.id === creatorId
                                      )?.lastName
                                  }`
                                : undefined
                        }
                        callback={() => {
                            setOpenCreatorModal(true)
                        }}
                    />
                    <FilterInput
                        label="Número identificador..."
                        name="search"
                        icon={MdSearch}
                        value={numberIdentifier}
                        onChange={(e) => setNumberIdentifier(e.target.value)}
                    />
                </div>
                <div className="filters">
                    {tabValue !== ExpenseStatus.CONFIRMED && (
                        <div>
                            <DynamicDropdownFilter
                                name="Formas de pagamento"
                                handleSelectItems={(items) =>
                                    setExpensesPaymentType(
                                        items as ExpensePaymentMethod[]
                                    )
                                }
                                items={paymentOptions}
                                selectedItems={expensesPaymentType}
                            />
                        </div>
                    )}
                    {tabValue !== ExpenseStatus.CONFIRMED && (
                        <SelectInput
                            className="payment-method"
                            name="payment-method-A"
                            placeholder="Pesquisar por cartão"
                            isSearchable
                            options={[
                                { label: 'Todos os cartões', value: 'ALL' },
                                ...paymentMethods.map((payment) => ({
                                    label: paymentLabel(payment),
                                    value: payment.id,
                                })),
                            ]}
                            value={selectedPaymentType}
                            onChange={(e) => {
                                if (e) {
                                    setSelectedPaymentType(
                                        e as { label: string; value: string }
                                    )
                                }
                            }}
                        />
                    )}
                </div>
            </div>
            {hasBatchLogic() && (
                <div className="select-all">
                    {selectedExpenses.length === expenses.length ? (
                        <>
                            <button
                                type="button"
                                onClick={() => setSelectedExpenses([])}
                            >
                                Desselecionar todos
                            </button>{' '}
                        </>
                    ) : (
                        <button
                            type="button"
                            onClick={() => setSelectedExpenses([...expenses])}
                        >
                            Selecionar todos
                        </button>
                    )}
                </div>
            )}
            <div className="grid">
                {busy && <ClipLoader />}
                {!busy &&
                    expenses.map((expense) => (
                        <div className="expenses-card">
                            {hasBatchLogic() && (
                                <ControlButton
                                    type="CHECKBOX"
                                    id={expense.id}
                                    callback={() =>
                                        setSelectedExpenses((prev) => {
                                            const index = prev.findIndex(
                                                (exp) => exp.id === expense.id
                                            )
                                            if (index === -1) {
                                                return [...prev, expense]
                                            }
                                            return prev.filter(
                                                (exp) => exp.id !== expense.id
                                            )
                                        })
                                    }
                                    isPressed={
                                        !!selectedExpenses.find(
                                            (exp) => exp.id === expense.id
                                        )
                                    }
                                />
                            )}
                            <ExpenseCard
                                tabValue={tabValue}
                                expense={expense}
                                key={expense.id}
                                hasBatchLogic={hasBatchLogic()}
                            />
                        </div>
                    ))}
                {expenses.length === 0 && (
                    <p className="f16-500-gray">Sem despesas cadastradas</p>
                )}
            </div>
            {hasBatchLogic() ? (
                <>
                    <div className="divider" />
                    <div className="selected-expenses">
                        <h2 className="f16-700-dark">{`${
                            selectedExpenses.length === 1
                                ? '1 despesa selecionada'
                                : `${selectedExpenses.length} despesas selecionadas`
                        }`}</h2>
                        <div className="price-button">
                            <p className="f18-700-dark">{`R$${priceFormatter(
                                selectedExpenses.reduce(
                                    (a, b) => a + expenseAmount(b),
                                    0
                                ) || 0
                            )}`}</p>
                            <Button
                                color="PRIMARY"
                                text="Confirmar todas"
                                buttonType="FILLED"
                                busy={buttonBusy}
                                icon={AiFillDollarCircle}
                                onClick={async () => {
                                    setButtonBusy(true)
                                    if (selectedExpenses.length === 0) {
                                        setButtonBusy(false)
                                        return toast.warning(
                                            'Nenhuma despesa selecionada'
                                        )
                                    }
                                    const response =
                                        await completeExpensesInBatch(
                                            selectedExpenses.map(
                                                (exp) => exp.id
                                            )
                                        )
                                    if (response) setSelectedExpenses([])
                                    setButtonBusy(false)
                                }}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="pagination">
                    <PaginationContainer>
                        <Pagination
                            count={numberOfPages(count || 0)}
                            color="primary"
                            variant="outlined"
                            page={pageSelected}
                            shape="rounded"
                            size="medium"
                            onChange={(
                                event: any,
                                page: React.SetStateAction<number>
                            ) => {
                                setPageSelected(page)
                            }}
                        />
                    </PaginationContainer>
                </div>
            )}
            {!!openModal && (
                <ExpensesModal
                    isOpen={!!openModal}
                    expense={openModal}
                    onRequestClose={() => openModalHandler(undefined)}
                />
            )}
            {!!isHistoryModalOpen && (
                <HistoryModal
                    isOpen={!!isHistoryModalOpen}
                    onRequestClose={() => openHistoryModal(undefined)}
                    history={isHistoryModalOpen}
                />
            )}
            {!!isQrCodeDataModalOpen && (
                <QrCodeDataModal
                    isOpen={!!isQrCodeDataModalOpen}
                    onRequestClose={() => openQrCodeDataModal(undefined)}
                    qrCodeData={isQrCodeDataModalOpen}
                />
            )}
            {!!isOcrDataModalOpen && (
                <OcrDataModal
                    isOpen={!!isOcrDataModalOpen}
                    onRequestClose={() => openOcrDataModal(undefined)}
                    ocrData={isOcrDataModalOpen}
                />
            )}
            {!!isApprovalStagesModalOpen && (
                <ApprovalStagesModal
                    isOpen={!!isApprovalStagesModalOpen}
                    onRequestClose={() => openApprovalStagesModal(undefined)}
                    approvalStages={isApprovalStagesModalOpen}
                />
            )}
            {openCreatorModal && (
                <ChoseOnneMemberModal
                    isOpen={openCreatorModal}
                    onRequestClose={() => setOpenCreatorModal(false)}
                    users={members.map((user) => {
                        return {
                            id: user.id,
                            label: `${user.firstName} ${user.lastName}`,
                            url: user.photo?.url,
                        }
                    })}
                    selected={creatorId}
                    callback={(id) => {
                        if (id === creatorId) {
                            setCreatorId(undefined)
                            setOpenCreatorModal(false)
                            return
                        }
                        setCreatorId(id)
                        setOpenCreatorModal(false)
                    }}
                />
            )}
        </ExpensesContainer>
    )
}
