import React, { useState } from 'react'
import { SubTabs } from '../SubTabs'
import { OutOfPolicyTableContainer } from './styles'

export interface OutOfPolicyTableProps {
    topPolicyBreaches: {
        costCenter: {
            id: any
            label: any
            count: any
            countOfExpensesOutOfPolicy: any
            percentage: number
        }[]
        travelers?: {
            id: any
            firstName: any
            lastName: any
            count: any
            countOfExpensesOutOfPolicy: any
            percentage: number
        }[]
    }
}

export function ExpensesComplianceTable({
    topPolicyBreaches,
}: OutOfPolicyTableProps) {
    const [selectedTab, setSelectedTab] = useState('COST_CENTER')

    return (
        <OutOfPolicyTableContainer>
            <div className="table-title">
                <h1 className="f16-700-dark">Principais fora da política</h1>
                <SubTabs
                    direction="HORIZONTAL"
                    value={selectedTab}
                    changeTab={(id: string) => setSelectedTab(id)}
                    tabs={[
                        {
                            label: 'Centro de custo',
                            id: 'COST_CENTER',
                        },
                        {
                            label: 'Viajantes',
                            id: 'TRAVELERS',
                        },
                    ]}
                />
            </div>
            <div className="table-header">
                <h2 className="f14-500-gray">
                    {selectedTab === 'COST_CENTER'
                        ? 'Centro de custo'
                        : 'Viajantes'}
                </h2>
                <h2 className="f14-500-gray center">% Fora da política</h2>
                <h2 className="f14-500-gray center">Despesas</h2>
                <h2 className="f14-500-gray center">Total de Despesas</h2>
            </div>
            {selectedTab === 'COST_CENTER' && (
                <div className="tabe-content">
                    {topPolicyBreaches.costCenter.map((costCenter) => {
                        return (
                            <div className="table-row">
                                <h2 className="f14-500-dark">
                                    {costCenter.label}
                                </h2>
                                <h2 className="f14-500-dark center">
                                    {costCenter.percentage
                                        ? costCenter.percentage.toFixed(2)
                                        : 0}
                                    %
                                </h2>
                                <h2 className="f14-500-dark center">
                                    {costCenter.countOfExpensesOutOfPolicy}
                                </h2>
                                <h2 className="f14-500-dark center">
                                    {costCenter.count}
                                </h2>
                            </div>
                        )
                    })}
                </div>
            )}
            {selectedTab === 'TRAVELERS' && (
                <div className="tabe-content">
                    {topPolicyBreaches.travelers?.map((traveler) => {
                        return (
                            <div className="table-row">
                                <h2 className="f14-500-dark">
                                    {`${traveler.firstName} ${traveler.lastName}`}
                                </h2>
                                <h2 className="f14-500-dark center">
                                    {traveler.percentage
                                        ? traveler.percentage.toFixed(2)
                                        : 0}
                                    %
                                </h2>
                                <h2 className="f14-500-dark center">
                                    {traveler.countOfExpensesOutOfPolicy}
                                </h2>
                                <h2 className="f14-500-dark center">
                                    {traveler.count}
                                </h2>
                            </div>
                        )
                    })}
                </div>
            )}
        </OutOfPolicyTableContainer>
    )
}
