/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import { FiPlusCircle } from 'react-icons/fi'
import { MdContacts, MdPerson } from 'react-icons/md'
import { v4 } from 'uuid'
import { Button } from '../../../../components/Button'
import { InnerHeader } from '../../../../components/InnerHeader'
import { useCompany } from '../../../../hooks/use-company'
import { ContactHandler } from '../../../../modals/ContactHandler'
import { phoneFormatter } from '../../../../utils/maskFunctions'
import { Contact, ContactInfoContainer } from './styles'
import { GeneralSubPageContent } from '../styles'

export function ContactInfo() {
    // hooks
    const { company, removeContactInfo } = useCompany()

    // state
    const [openContactInfo, setOpenContactInfo] = useState(false)

    return (
        <GeneralSubPageContent>
            <ContactInfoContainer>
                <InnerHeader
                    title="Contatos"
                    callback={() => {}}
                    icon={MdContacts}
                />
                {company?.contactInfo.map((contact, index) => {
                    return (
                        <Contact key={v4()}>
                            <div className="content">
                                <div className="title">
                                    <h1>{contact.department}</h1>
                                </div>
                                <div className="description">
                                    <div className="info">
                                        <MdPerson />
                                        <div className="info-labels">
                                            <h1>{contact.name}</h1>
                                            <h2>
                                                {phoneFormatter(
                                                    contact.phoneNumber,
                                                    'FROM_API'
                                                )}
                                            </h2>
                                        </div>
                                    </div>

                                    <div className="edit-btn">
                                        <Button
                                            buttonType="TEXT"
                                            color="PRIMARY"
                                            text="Remover"
                                            onClick={async () => {
                                                await removeContactInfo(index)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Contact>
                    )
                })}
                <button
                    type="button"
                    className="add-contact"
                    onClick={() => setOpenContactInfo(true)}
                >
                    <FiPlusCircle />
                    <h2>Cadastrar outro contato</h2>
                </button>
                <ContactHandler
                    isOpen={openContactInfo}
                    onRequestClose={() => setOpenContactInfo(false)}
                />
            </ContactInfoContainer>
        </GeneralSubPageContent>
    )
}
