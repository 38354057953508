import React, { useState } from 'react'
import { OrderedCardContainer } from './styles'

interface OrderedCardProps {
    index: number
    title: string
    subTitle: string
    isCurrency?: boolean
}

export function OrderedCard({
    index,
    title,
    subTitle,
    isCurrency = true,
}: OrderedCardProps) {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <OrderedCardContainer>
            <div className="circle-number">
                <span>{index}</span>
            </div>
            <div className="description">
                {isHovered ? <p className="tooltip">{title}</p> : null}
                <h1
                    className="f16-700-dark margin-bottom"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {title}
                </h1>
                {isCurrency ? (
                    <p className="f16-500-gray">{`R$ ${
                        Number(subTitle).toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) || 0
                    }`}</p>
                ) : (
                    <p className="f16-500-gray">
                        {Math.round(Number(subTitle))}
                    </p>
                )}
            </div>
        </OrderedCardContainer>
    )
}
