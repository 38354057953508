import styled from 'styled-components'

export const InnerHeaderContainer = styled.div`
    width: 100%;
    border-bottom: 2px solid #edeff2;
    .content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4.5rem 2.5rem;
        .icon-title {
            display: flex;
            align-items: center;
            .icon {
                svg {
                    width: 2rem;
                    height: 2rem;
                }
            }
            .title {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                h1 {
                    font-size: 2.2rem;
                    font-weight: 700;
                    color: #191b1e;
                    margin-left: 2rem;
                }
            }
        }
        button {
            width: 10rem;
        }
    }
`
