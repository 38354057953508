/* eslint-disable import/no-duplicates */
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

export function expensesDateFormatter(date: string) {
    return format(new Date(date), 'dd MMM, yyyy', { locale: ptBR })
}

export function historyDateFormatter(date: Date) {
    return format(new Date(date), `dd MMM, yyyy 'às' HH'h'mm'`, {
        locale: ptBR,
    })
}
