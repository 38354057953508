import styled from 'styled-components'

export const TitleDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .title {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            position: relative;
            display: flex;
            align-items: center;
            border: none;
            background-color: inherit;
            padding: 0 1rem;
            &:hover {
                cursor: pointer;
            }
            svg {
                color: #323232;
            }
            .info {
                position: absolute;
                bottom: 3rem;
                width: 40rem;
                padding: 1rem;
                background-color: #ddd;
                border-radius: 6px;
            }
            .left {
                position: absolute;
                bottom: 3rem;
                right: 0;
            }
            .hidden {
                display: none;
            }
            .active {
                display: block;
            }
        }
    }
    .description {
        width: 35rem;
    }
`
