export enum InvoiceType {
    MEAL = 'MEAL',
    TOLL = 'TOLL',
    GROCERIES = 'GROCERIES',
    EXPENSE = 'EXPENSE',
    APP_99 = 'APP_99',
    HR_ACTION = 'HR_ACTION',
    TRAVEL_ADVANCE = 'TRAVEL_ADVANCE',
    WATER = 'WATER',
    LUNCH = 'LUNCH',
    LUNCH_WITH_CLIENT = 'LUNCH_WITH_CLIENT',
    SEAT = 'SEAT',
    LUGGAGE = 'LUGGAGE',
    GIFTS = 'GIFTS',
    BREAKFAST = 'BREAKFAST',
    FUEL = 'FUEL',
    PARKING = 'PARKING',
    HAPPY_HOUR_WITH_CLIENT = 'HAPPY_HOUR_WITH_CLIENT',
    DINNER = 'DINNER',
    DINNER_WITH_CLIENT = 'DINNER_WITH_CLIENT',
    SNACK = 'SNACK',
    LAUNDRY = 'LAUNDRY',
    OTHERS = 'OTHERS',
    AIR_TICKET = 'AIR_TICKET',
    BUS_TICKET = 'BUS_TICKET',
    ROUTE = 'ROUTE',
    MILEAGE = 'MILEAGE',
    BOARDING_FEES = 'BOARDING_FEES',
    TAXI = 'TAXI',
    UBER = 'UBER',
    TRIP = 'TRIP',
}
