/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { InitialContainerStyle, LoginContent } from './styles'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { useAuth } from '../../hooks/use-auth'
import { Button } from '../../components/Button'
import { Input } from '../../UnformInputs/Input'
import logoAda from '../../assets/adalogo.png'
import { LoginDto } from '../../dtos/login'

export function LoginPage() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    const localData = searchParams.get('localData')
    // refs
    const formRef = useRef<FormHandles>(null)

    // hooks
    const { login, loginWithToken } = useAuth()

    // state
    const [loadingBtn, setLoadingBtn] = useState(false)

    useEffect(() => {
        if (localData) {
            loginWithToken(localData)
        }
    }, [localData])

    async function handleLogin(data: LoginDto) {
        setLoadingBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                email: Yup.string().required('Insira seu e-mail '),
                password: Yup.string().required('Insira sua senha '),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            await login(data)
            setLoadingBtn(false)
        } catch (error) {
            toast.info('Verifique se você preencheu o campo')
            setLoadingBtn(false)
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <InitialContainerStyle>
            <div className="content">
                <div className="logo">
                    <img src={logoAda} alt="logo" />
                </div>
                <div className="content-form">
                    <LoginContent>
                        <Form ref={formRef} onSubmit={handleLogin} noValidate>
                            <Input
                                name="email"
                                label="E-mail"
                                autoComplete="username"
                            />
                            <Input
                                name="password"
                                label="Senha"
                                isPassword
                                autoComplete="current-password"
                            />
                            {/* <div className="password-recover">
                                <Button
                                    buttonType="TEXT"
                                    color="PRIMARY"
                                    text="Esqueci minha senha"
                                    type="button"
                                />
                            </div> */}
                            <Button
                                type="submit"
                                text="Entrar"
                                buttonType="FILLED"
                                color="PRIMARY"
                                busy={loadingBtn}
                            />
                        </Form>
                    </LoginContent>
                </div>
            </div>
            <div className="background" />
        </InitialContainerStyle>
    )
}
