import React from 'react'
import policyImg from '../../assets/policies.svg'
import { LostEconomiesContainer } from './styles'

interface Props {
    amount: string
}

export function LostEconomies({ amount }: Props) {
    return (
        <LostEconomiesContainer>
            <div className="image">
                <img src={policyImg} alt="Fora da política" />
            </div>
            <div className="info">
                <h2 className="f16-700-dark">Economias perdidas</h2>
                <h1 className="f32-700-dark margin">{amount}</h1>
                <p className="f14-500-gray">
                    Quando seus viajantes gastam mais do que o preço
                    recomendado, possíveis economias são perdidas. O valor acima
                    é o valor total que deixou de ser economizado.
                </p>
            </div>
        </LostEconomiesContainer>
    )
}
