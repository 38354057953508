import styled from 'styled-components'

export const UsersContainer = styled.div`
    margin-bottom: 6rem;
    .sub-menu-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .sub-menu {
            display: inline-block;
            margin: 4.8rem auto 3rem auto;
        }
    }
    .user-content {
        width: 111rem;
        margin: 0 auto;
        &--title {
            margin-bottom: 2rem;
            h1 {
                font-size: 2.2rem;
                font-weight: 700;
                color: #191b1e;
                margin-bottom: 0.4rem;
            }
            p {
                font-size: 1.4rem;
                font-weight: 400;
                color: #646669;
                font-style: italic;
            }
        }
    }
`

export const Filter = styled.div`
    width: 100%;
    height: 5rem;
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    button {
        width: 100%;
        white-space: nowrap;
    }
    .flex {
        display: flex;
        gap: 2rem;
    }
    .relative {
        display: relative;
    }
    .select-btn {
        padding: 1.5rem 1.5rem;
        border-radius: 0.8rem;
        font-size: 1.6rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border: 1px solid #e74b70;
        color: #e74b70;
        svg {
            height: 2rem;
            width: 2rem;
            margin-left: 1rem;
        }
    }
    .select-options {
        z-index: 100;
        position: absolute;
        width: 25rem;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 0.8rem;
        .option-btn {
            padding: 2rem 1rem;
            text-align: start;
            background-color: white;
            border: none;
            &:hover {
                cursor: pointer;
                background-color: rgba(231, 75, 112, 0.2);
            }
        }
    }

    .div-opaciy {
        position: absolute;
        opacity: 0.2;
        width: 100%;
        height: 100vh;
    }
`
