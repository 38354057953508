import styled from 'styled-components'

export const BrokenPoliciesReasonContainer = styled.div`
    .broken-title {
        margin-bottom: 2rem;
    }
    .progressive-bar-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3rem;
        .progressive-bar-label {
            display: flex;
            align-items: center;
            .progressive-bar {
                width: 30rem;
                height: 1.2rem;
                background-color: #edeff2;
                margin-right: 1.2rem;
                position: relative;
                border-radius: 1px;
                .filled {
                    position: absolute;
                    left: 0;
                    height: 1.2rem;
                    background-color: #db614e;
                    border-radius: 1px;
                }
            }
        }
    }
`
