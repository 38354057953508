/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-duplicates */
import React, { useState } from 'react'
import { AiFillCloseCircle, AiOutlineCheck } from 'react-icons/ai'
import ptLocale from 'date-fns/locale/pt-BR'
import { FaArrowDown } from 'react-icons/fa'
import {
    format,
    startOfMonth,
    startOfQuarter,
    subMonths,
    endOfMonth,
    subQuarters,
    endOfQuarter,
    startOfYear,
    subYears,
    endOfYear,
    startOfDay,
} from 'date-fns'
import { FilterContainer, PeriodDropDown, SelectButtons } from './styles'
import { useDashboard } from '../../hooks/useDashboard'
import { DatePicker } from '../DatePicker'

export function DateFilter() {
    const { handleFilterDashboard, dateFilter } = useDashboard()

    const [selectedPeriod, setSelectedPeriod] = useState(false)
    const [openSelecteCustomData, setOpenSelectCustomData] = useState(false)

    function periodLabel(
        period:
            | 'ALL_TIME'
            | 'THIS_MONTH'
            | 'THIS_TRIMESTER'
            | 'THIS_YEAR'
            | 'LAST_MONTH'
            | 'LAST_TRIMESTER'
            | 'LAST_YEAR'
            | 'SELECT_DATE'
            | 'YEAR'
            | 'CUSTOM'
            | undefined
    ) {
        if (period === 'ALL_TIME') {
            return 'Todo o tempo'
        }
        if (period === 'THIS_MONTH') {
            return 'Este mês'
        }
        if (period === 'THIS_TRIMESTER') {
            return 'Este trimestre'
        }
        if (period === 'THIS_YEAR') {
            return 'Este ano'
        }
        if (period === 'LAST_MONTH') {
            return 'Último mês'
        }
        if (period === 'LAST_TRIMESTER') {
            return 'Último trimestre'
        }
        if (period === 'LAST_YEAR') {
            return 'Último ano'
        }
        if (period === 'YEAR') {
            return 'Últimos 12 meses'
        }
        return 'Período customizado'
    }

    return (
        <FilterContainer>
            <div className="second-row">
                <PeriodDropDown>
                    <label
                        htmlFor="period"
                        className={`selector-label ${
                            dateFilter?.typeOfPeriod !== 'YEAR'
                                ? 'bordered'
                                : ''
                        }`}
                    >
                        <input
                            type="checkbox"
                            name="period"
                            checked={selectedPeriod}
                            id="period"
                            className="selector-input"
                            onChange={(e) =>
                                setSelectedPeriod(e.target.checked)
                            }
                        />
                        {dateFilter?.typeOfPeriod !== 'YEAR' ? (
                            <h4>{periodLabel(dateFilter?.typeOfPeriod)}</h4>
                        ) : (
                            <p>Últimos 12 meses</p>
                        )}
                        {dateFilter?.typeOfPeriod !== 'YEAR' ? (
                            <AiFillCloseCircle
                                style={{ height: '1.6rem', width: '1.6rem' }}
                                onClick={() => {
                                    handleFilterDashboard({
                                        ...dateFilter,
                                        period: {
                                            startDate: startOfDay(
                                                subYears(new Date(), 1)
                                            ),
                                            endDate: new Date(),
                                        },
                                        typeOfPeriod: 'YEAR',
                                    })
                                }}
                            />
                        ) : (
                            <FaArrowDown />
                        )}
                        <div className="selectors">
                            <div className="title">
                                <h1>Selecione o periodo</h1>
                                <div className="btns">
                                    <button
                                        className="clear-all"
                                        type="button"
                                        onClick={() => {
                                            handleFilterDashboard({
                                                ...dateFilter,
                                                period: {
                                                    startDate: startOfDay(
                                                        subYears(new Date(), 1)
                                                    ),
                                                    endDate: new Date(),
                                                },
                                                typeOfPeriod: 'YEAR',
                                            })
                                        }}
                                    >
                                        Limpar
                                    </button>
                                </div>
                            </div>
                            <SelectButtons>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterDashboard({
                                            ...dateFilter,
                                            typeOfPeriod: 'THIS_MONTH',
                                            period: {
                                                startDate: startOfMonth(
                                                    new Date()
                                                ),
                                                endDate: new Date(),
                                            },
                                        })
                                    }}
                                    className={`${
                                        dateFilter?.typeOfPeriod ===
                                        'THIS_MONTH'
                                            ? 'selected'
                                            : ''
                                    } `}
                                >
                                    Este mês
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterDashboard({
                                            ...dateFilter,
                                            typeOfPeriod: 'THIS_TRIMESTER',
                                            period: {
                                                startDate: startOfQuarter(
                                                    new Date()
                                                ),
                                                endDate: new Date(),
                                            },
                                        })
                                    }}
                                    className={`${
                                        dateFilter?.typeOfPeriod ===
                                        'THIS_TRIMESTER'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Este trimestre
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterDashboard({
                                            ...dateFilter,
                                            typeOfPeriod: 'THIS_YEAR',
                                            period: {
                                                startDate: startOfYear(
                                                    new Date()
                                                ),
                                                endDate: new Date(),
                                            },
                                        })
                                    }}
                                    className={`${
                                        dateFilter?.typeOfPeriod === 'THIS_YEAR'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Este ano
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterDashboard({
                                            ...dateFilter,
                                            period: {
                                                startDate: startOfDay(
                                                    subYears(new Date(), 1)
                                                ),
                                                endDate: new Date(),
                                            },
                                            typeOfPeriod: 'YEAR',
                                        })
                                    }}
                                    className={`${
                                        dateFilter?.typeOfPeriod === 'YEAR'
                                            ? 'selected'
                                            : ''
                                    } `}
                                >
                                    Últimos 12 meses
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterDashboard({
                                            ...dateFilter,
                                            typeOfPeriod: 'LAST_MONTH',
                                            period: {
                                                startDate: startOfMonth(
                                                    subMonths(new Date(), 1)
                                                ),
                                                endDate: endOfMonth(
                                                    subMonths(new Date(), 1)
                                                ),
                                            },
                                        })
                                    }}
                                    className={`${
                                        dateFilter?.typeOfPeriod ===
                                        'LAST_MONTH'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Último mês
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterDashboard({
                                            ...dateFilter,
                                            typeOfPeriod: 'LAST_TRIMESTER',
                                            period: {
                                                startDate: startOfQuarter(
                                                    subQuarters(new Date(), 1)
                                                ),
                                                endDate: endOfQuarter(
                                                    subQuarters(new Date(), 1)
                                                ),
                                            },
                                        })
                                    }}
                                    className={`${
                                        dateFilter?.typeOfPeriod ===
                                        'LAST_TRIMESTER'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Último trimestre
                                    <AiOutlineCheck />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleFilterDashboard({
                                            ...dateFilter,
                                            typeOfPeriod: 'LAST_YEAR',
                                            period: {
                                                startDate: startOfYear(
                                                    subYears(new Date(), 1)
                                                ),
                                                endDate: endOfYear(
                                                    subYears(new Date(), 1)
                                                ),
                                            },
                                        })
                                    }}
                                    className={`${
                                        dateFilter?.typeOfPeriod === 'LAST_YEAR'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Último ano
                                    <AiOutlineCheck />
                                </button>
                                {/* <button
                                    type="button"
                                    onClick={() => {
                                        setOpenSelectCustomData(true)
                                        setSelectedPeriod(false)
                                    }}
                                    className={`${
                                        dateFilter?.typeOfPeriod === 'CUSTOM'
                                            ? 'selected'
                                            : ''
                                    }`}
                                >
                                    Período customizado
                                    <AiOutlineCheck />
                                </button> */}
                            </SelectButtons>
                        </div>
                    </label>
                </PeriodDropDown>
                {dateFilter?.period && (
                    <p className="exibition">{`Exibindo resultados de viagens emitidas de ${format(
                        new Date(dateFilter?.period?.startDate || 0),
                        `dd'/'MM'/'yyyy`,
                        {
                            locale: ptLocale,
                        }
                    )} - ${format(
                        new Date(dateFilter?.period?.endDate || 0),
                        `dd'/'MM'/'yyyy`,
                        {
                            locale: ptLocale,
                        }
                    )}`}</p>
                )}
                {!dateFilter?.period && (
                    <>
                        {!dateFilter?.typeOfPeriod ? (
                            <p>{`Exibindo resultados de viagens emitidas de ${format(
                                subYears(new Date(), 1),
                                `dd'/'MM'/'Y`,
                                {
                                    locale: ptLocale,
                                }
                            )} à ${format(new Date(), `dd'/'MM'/'Y`, {
                                locale: ptLocale,
                            })}`}</p>
                        ) : (
                            <p>Todos os bilhetes</p>
                        )}
                    </>
                )}
            </div>
            {selectedPeriod && (
                <div
                    className="disabled"
                    onClick={() => {
                        setSelectedPeriod(false)
                    }}
                />
            )}
            {openSelecteCustomData && (
                <DatePicker
                    hasRange
                    closeDatePicker={() => setOpenSelectCustomData(false)}
                />
            )}
        </FilterContainer>
    )
}
