import styled from 'styled-components'

export const ExpensesContainer = styled.div`
    .title {
        margin-bottom: 5rem;
    }
    .tabs {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #c8cacd;
        margin-bottom: 5rem;
    }
    .grid {
        display: grid;
        gap: 1.2rem;
        margin-bottom: 3rem;
    }
    .expenses-card {
        display: flex;
        align-items: center;
        gap: 2rem;
    }
    .first-filter {
        .exibition {
            margin-bottom: 1.5rem;
            color: #646669;
            font-weight: 500;
            font-size: 1.4rem;
            font-style: italic;
        }
    }
    .select-all {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 1rem;
        button {
            background-color: inherit;
            border: none;
            color: var(--color-primary);
        }
    }
    .filters {
        display: flex;
        align-items: center;
        gap: 2rem;
        margin-bottom: 2rem;
        .payment-method {
            width: 25rem;
        }
        .normal-label {
            margin-bottom: 1rem;
        }
    }
    .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .selected-expenses {
        margin-bottom: 4rem;
        width: 100%;
        padding: 1.5rem 1.5rem 1.5rem 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 0.8rem;
        .price-button {
            display: flex;
            align-items: center;
            gap: 4rem;
        }
    }
    .divider {
        height: 1px;
        width: 100%;
        background-color: #ccc;
        margin-bottom: 3rem;
    }
`
