import React from 'react'
import Modal from 'react-modal'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { ToastContainer } from 'react-toastify'
import { AppProvider } from './hooks'
import { Routes } from './routes'
import GlobalStyle from './styles/global'
import 'react-toastify/dist/ReactToastify.min.css'
import { MyFallbackComponent } from './error/ErrorBoundary'
import { Intercom } from './components/Intercom'

export function App() {
    Modal.setAppElement('#root')

    Sentry.init({
        dsn: 'https://0850c56cbd304719c6ac3e92dd441f9e@o947259.ingest.us.sentry.io/4507419265007616',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/yourserver\.io\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: process.env.SENTRY_ENV || 'staging',
    })

    return (
        <AppProvider>
            <Router>
                <ErrorBoundary
                    FallbackComponent={MyFallbackComponent}
                    onReset={() => {
                        // reset the state of your app here
                    }}
                    resetKeys={['someKey']}
                >
                    <Intercom>
                        <Routes />
                        <ToastContainer newestOnTop autoClose={2000} />
                    </Intercom>
                </ErrorBoundary>
            </Router>
            <GlobalStyle />
        </AppProvider>
    )
}
