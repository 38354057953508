import styled from 'styled-components'

export const DropDownPoliticsContainer = styled.div`
    padding: 2.5rem 2rem;
    border-bottom: 2px solid #edeff2;
    .dropdown-header {
        width: 100%;
        border: none;
        background-color: inherit;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1rem;
        cursor: pointer;
        .title-description {
            margin-right: 2rem;
            .title {
                display: flex;
                align-items: center;
                svg {
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-right: 2rem;
                    color: #191b1e;
                }
                h1 {
                    font-size: 2.2rem;
                    font-weight: 700;
                    color: #191b1e;
                }
            }
            p {
                text-align: start;
                font-size: 1.6rem;
                font-weight: 500;
                color: #646669;
                margin-top: 1rem;
            }
        }
        .arrow-btn {
            background-color: inherit;
            border: none;
            padding: 1rem 2rem;
            svg {
                width: 2rem;
                height: 2rem;
            }
        }
        .btn-action {
            width: 10rem;
        }
    }
`
