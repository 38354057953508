/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import { MdContacts, MdGavel, MdPerson } from 'react-icons/md'
import { v4 } from 'uuid'
import { Button } from '../../../../components/Button'
import { InnerHeader } from '../../../../components/InnerHeader'
import { useCompany } from '../../../../hooks/use-company'
import { phoneFormatter } from '../../../../utils/maskFunctions'
import { Contact, ContactInfoContainer } from './styles'
import { SinglePolicieContainer } from '../../../Politics/styles'
import { TitleDescription } from '../../../../components/TitleDescription'
import { SelectInput } from '../../../../components/SelectInput'
import { GeneralSubPageContent } from '../styles'

export function ApprovalsInfo() {
    // hooks
    const { company, editMaxApprovalLevel } = useCompany()
    const [maxApprovalLevel, setMaxApprovalLevel] = useState<number>()

    useEffect(() => {
        if (company) {
            setMaxApprovalLevel(company.maxApprovalLevel)
        }
    }, [])

    return (
        <GeneralSubPageContent>
            <ContactInfoContainer>
                <InnerHeader
                    btnLabel="Salvar"
                    title="Aprovações"
                    callback={() => {
                        editMaxApprovalLevel(Number(maxApprovalLevel))
                    }}
                    icon={MdGavel}
                />

                <Contact key={v4()}>
                    <div className="content">
                        <SinglePolicieContainer>
                            <TitleDescription
                                title="Quantidade máxima de níveis de aprovação"
                                description="Número máximo de níveis de aprovação que poderão ser configurados nas políticas de viagens e nos fluxos de aprovação."
                            />
                            <div className="inputs">
                                <SelectInput
                                    name="maxLevel"
                                    options={[
                                        { value: 1, label: '1' },
                                        { value: 2, label: '2' },
                                        { value: 3, label: '3' },
                                        { value: 4, label: '4' },
                                        { value: 5, label: '5' },
                                    ]}
                                    value={{
                                        value: maxApprovalLevel,
                                        label: maxApprovalLevel?.toString(),
                                    }}
                                    onChange={async (e) => {
                                        setMaxApprovalLevel(Number(e!.value))
                                    }}
                                />
                            </div>
                        </SinglePolicieContainer>
                    </div>
                </Contact>
            </ContactInfoContainer>
        </GeneralSubPageContent>
    )
}
