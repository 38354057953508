/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import ModalContainer from 'react-modal'
import { ExpensesModalContainer } from './styles'
import { Expenses } from '../../interfaces/expenses'
import { priceFormatter } from '../../utils/expensnes/priceFormatter'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    qrCodeData: Expenses['qrCodeData']
}

export default function QrCodeDataModal({
    isOpen,
    onRequestClose,
    qrCodeData,
}: Props) {
    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-history"
        >
            <ExpensesModalContainer>
                <div className="header">
                    <h1 className="f18-700-dark">Dados do QRCode</h1>
                </div>
                <div className="content-block">
                    <div className="container">
                        <p className="f16-500-gray italic">Emitente</p>
                        <div className="label-value">
                            <p className="f16-500-gray">Razão social</p>
                            <p className="f16-500-gray">
                                {qrCodeData.emitente.razao_social || '-'}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">CNPJ</p>
                            <p className="f16-500-gray">
                                {qrCodeData.emitente.cnpj || '-'}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">UF</p>
                            <p className="f16-500-gray">
                                {qrCodeData.emitente.uf || '-'}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Cidade</p>
                            <p className="f16-500-gray">
                                {qrCodeData.emitente.cidade || '-'}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Bairro</p>
                            <p className="f16-500-gray">
                                {qrCodeData.emitente.bairro || '-'}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="content-block">
                    <div className="container">
                        <p className="f16-500-gray italic">Produtos</p>
                        {qrCodeData.produtos.map((product, index) => (
                            <div key={index} className="label-value">
                                <p className="f16-500-gray name">
                                    {product.nome}
                                </p>
                                <p className="f16-500-gray">
                                    {product.quantidade}UN
                                </p>
                                <p className="f16-500-gray">
                                    R${priceFormatter(Number(product.total))}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="content-block">
                    <div className="container">
                        <p className="f16-500-gray italic">Pagamento</p>
                        <div className="label-value">
                            <p className="f16-500-gray">Forma de pagamento</p>
                            <p className="f16-500-gray">
                                {qrCodeData.forma_pagamento[0]}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Data de emissão</p>
                            <p className="f16-500-gray">
                                {qrCodeData.data_emissao}
                            </p>
                        </div>
                        <div className="label-value">
                            <p className="f16-500-gray">Valor total</p>
                            <p className="f16-500-gray">
                                R${priceFormatter(Number(qrCodeData.total))}
                            </p>
                        </div>
                    </div>
                </div>
            </ExpensesModalContainer>
        </ModalContainer>
    )
}
