import React, { ReactNode } from 'react'
import { AuthProvider } from './use-auth'
import { CompanyProvider } from './use-company'
import { PaymentProvider } from './use-payment'
import { UserProvider } from './use-user'
import { DashboardProvider } from './useDashboard'
import { ExpensesProvider } from './use-expenses'

interface AppProvideProps {
    children: ReactNode
}

export function AppProvider({ children }: AppProvideProps) {
    return (
        <AuthProvider>
            <UserProvider>
                <CompanyProvider>
                    <PaymentProvider>
                        <DashboardProvider>
                            <ExpensesProvider>{children}</ExpensesProvider>
                        </DashboardProvider>
                    </PaymentProvider>
                </CompanyProvider>
            </UserProvider>
        </AuthProvider>
    )
}
