import styled from 'styled-components'

export const DatePickerContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 900000;
    transform: translate(-50%, -50%);
    width: 35rem;
    height: 39rem;
    border-radius: 2rem;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    .date-picker-exit {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .exit {
            border: none;
            background-color: inherit;
            margin-bottom: 1rem;
            outline: none;
            transition: all 0.3s;
            &:hover {
                transform: rotate(180deg) scale(1.2);
            }
        }
    }
    .date-picker-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .left-btn,
        .right-btn {
            border: none;
            background-color: inherit;
            svg {
                height: 2rem;
                width: 2rem;
            }
        }
    }
    .date-picker-table--header {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        justify-items: center;
        padding: 1rem;
        border-bottom: 1px solid #ccc;
        margin-top: 1.6rem;
    }
    .date-picker-table--body {
        padding: 1rem 1.5rem;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        justify-items: center;
        .unclickable-day {
            height: 4rem;
            width: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            &--label {
                color: #c8cacd;
                font-size: 1.4rem;
            }
        }
        .date-picker-day {
            margin: 0;
            border: 1px solid transparent;
            border-radius: 50%;
            background-color: inherit;
            height: 4rem;
            width: 4rem;
            transition: all 0.2s ease;
        }
        .range-date-picker-day {
            border: 1px solid transparent;
            background-color: inherit;
            height: 4rem;
            width: 4rem;
            transition: all 0.2s ease;
        }
        .active-hover {
            background-color: #646669;
            h1 {
                color: #fff;
            }
        }
        .first-active {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            background-color: #000;
            h1 {
                color: #fff;
            }
        }
        .second-active {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            background-color: #000;
            h1 {
                color: #fff;
            }
        }

        .active {
            border: 1px solid #333;
        }
        .date-picker-day:hover {
            background-color: #edf3f2;
        }
        .date-picker-day:active {
            background-color: #c8cacd;
        }
    }
`
export const OpacityContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    z-index: 90000;
    opacity: 0.2;
`
