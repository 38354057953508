import styled from 'styled-components'

export const ControlButtonContainer = styled.div`
    input {
        display: none;
    }
    input[type='checkbox']:checked + .switch-container {
        background-color: var(--color-success);
    }
    .switch-container {
        position: relative;
        width: 4rem;
        height: 2.3rem;
        border-radius: 100rem;
        transition: all 0.4s ease;
        background-color: var(--color-light-gray);
        .switch-label {
            .switch-circle {
                position: absolute;
                left: 0.2rem;
                top: 50%;
                transform: translateY(-50%);
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                background-color: var(--color-light);
                transition: all 0.4s ease;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .radio-label {
        .radio-container {
            width: 1.8rem;
            height: 1.8rem;
            background-color: inherit;
            border: 2px solid var(--color-secondary-gray);
            border-radius: 50%;
            transition: all 0.2s;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .checkbox-label {
        .checkbox-container {
            width: 1.8rem;
            height: 1.8rem;
            background-color: inherit;
            border: 2px solid var(--color-secondary-gray);
            border-radius: 0.3rem;
            transition: all 0.2s;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                cursor: pointer;
            }
        }
    }
    input[type='checkbox']:checked + .switch-container {
        .switch-label {
            .switch-circle {
                left: 1.8rem;
            }
        }
    }
    input[type='checkbox']:checked + .radio-label {
        .radio-container {
            background-color: var(--color-light);
            border: 5px solid var(--color-success);
        }
    }
    input[type='checkbox']:checked + .checkbox-label {
        .checkbox-container {
            background-color: var(--color-success);
            border: none;
            svg {
                color: var(--color-light);
            }
        }
    }
`
