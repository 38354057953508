import styled from 'styled-components'

export const ProviderCardContainer = styled.div`
    width: 23rem;
    height: 24.1rem;
    position: relative;
    .provider-card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        border: none;
        cursor: pointer;
        &:before {
            content: 'Editar';
            position: absolute;
            bottom: 2rem;
            color: var(--color-primary);
            font-weight: 700;
            transition: all 0.4s linear;
            opacity: 0;
        }
        &:hover {
            &:before {
                opacity: 1;
            }
        }
        h1 {
            font-size: 1.6rem;
            color: #000;
            font-weight: 700;
            margin-top: 1.5rem;
        }
        .supplier-switcher {
            position: absolute;
            top: 1rem;
            right: 1rem;
            left: 1rem;
            display: flex;
            justify-content: space-between;
            .checkin-btn {
                background-color: rgba(255, 255, 255, 0.3);
                color: var(--color-primary);
                border: none;
            }
        }
    }
    .supplier-switcher {
        position: absolute;
        top: 1rem;
        right: 1rem;
        left: 1rem;
        display: flex;
        justify-content: space-between;
        .checkin-btn {
            background-color: rgba(255, 255, 255, 0.3);
            color: var(--color-primary);
            border: none;
        }
    }
`
