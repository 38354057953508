import styled from 'styled-components'

export const ContactInfoContainer = styled.div`
    .add-contact {
        padding: 3rem 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        h2 {
            color: var(--color-primary);
            font-size: 1.6rem;
            font-weight: 500;
        }
        svg {
            margin-right: 1rem;
            color: var(--color-primary);
        }
        border: none;
    }
`

export const ExpensesInfoContainer = styled.div`
    margin-top: 2rem;
`

export const Contact = styled.div`
    border-bottom: 2px solid #f6f7f8;
    .content {
        padding: 3rem 2.5rem;
        .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 2.5rem;
            h1 {
                font-size: 1.6rem;
                font-weight: 700;
                margin-right: 0.7rem;
            }
            p {
                font-size: 1.4rem;
                color: #646669;
                font-style: italic;
                font-weight: 500;
            }
        }
        .description {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .info {
                display: flex;
                align-items: center;
                svg {
                    height: 2rem;
                    width: 2rem;
                    margin-right: 2rem;
                    color: #191b1e;
                }
                .info-labels {
                    h1 {
                        font-weight: 700;
                        font-style: 1.6rem;
                        color: #000;
                    }
                    h2 {
                        font-weight: 500;
                        font-style: 1.6rem;
                        color: #000;
                    }
                }
            }
            .edit-btn {
                margin-right: 2rem;
            }
        }
    }
`
