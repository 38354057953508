import { ExpenseStatus } from '../../enums/expenses-status.enum'

export function expenseStatusLabel(status: string) {
    if (status === ExpenseStatus.PENDING_APPROVAL) {
        return 'Esperando aprovação'
    }
    if (status === ExpenseStatus.CONFIRMED) {
        return 'Aprovado'
    }
    if (status === ExpenseStatus.REJECTED) {
        return 'Rejeitado'
    }

    if (status === ExpenseStatus.CREATED) {
        return 'Criado'
    }

    if (status === ExpenseStatus.COMPLETED) {
        return 'Conciliado'
    }
    return ''
}
