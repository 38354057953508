/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { MdPeople } from 'react-icons/md'
import ModalContainer from 'react-modal'
import { toast } from 'react-toastify'
import { Button } from '../../components/Button'
import { Input } from '../../UnformInputs/Input'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { InviteUserContainer } from './styles'
import { useAuth } from '../../hooks/use-auth'
import { CreateInvite } from '../../dtos/create-invite'
import { useCompany } from '../../hooks/use-company'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
}

export function InviteUser({ isOpen, onRequestClose }: Props) {
    // hooks
    const { createInvite } = useAuth()
    const { company } = useCompany()

    // ref
    const formRef = useRef<FormHandles>(null)
    // state
    const [busyBtn, setBusyBtn] = useState(false)

    async function handleInviteUser(data: { email: string; name: string }) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                email: Yup.string().required(
                    'Insira o e-mail do usuário que deseja convidar'
                ),
                name: Yup.string().required('Insira o nome do usuário'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })
            if (company) {
                const inviteData: CreateInvite = {
                    companyId: company.id,
                    email: data.email,
                    name: data.name,
                }
                const response = await createInvite(inviteData)
                if (response) {
                    toast.success(`Convite enviado para o e-mail ${data.email}`)
                    onRequestClose()
                }
                setBusyBtn(false)
            }
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <Form ref={formRef} onSubmit={handleInviteUser} noValidate>
                <div className="modal-header">
                    <div className="modal-header-title">
                        <MdPeople />
                        <h1 className="f22-700-dark">Convidar usuário</h1>
                    </div>
                    <div className="modal-header-description">
                        <div className="modal-header-description--blank" />
                        <p className="f16-500-gray">
                            O viajante receberá as informações para se cadastrar
                            na <br />
                            plataforma por e-mail e deverá realizar o onboarding
                            em <br />
                            seu celular ou na web.
                        </p>
                    </div>
                </div>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <InviteUserContainer>
                    <div className="modal-content">
                        <div className="title">
                            <h1 className="f16-500-gray-secondary">
                                Informações do viajante
                            </h1>
                        </div>

                        <div className="form">
                            <Input label="Nome do viajante" name="name" />
                            <Input label="E-mail corporativo" name="email" />
                        </div>
                    </div>
                </InviteUserContainer>
                <div className="modal-confirm-btn">
                    <Button
                        color="PRIMARY"
                        buttonType="FILLED"
                        text="Convidar usuário"
                        busy={busyBtn}
                        type="submit"
                    />
                </div>
            </Form>
        </ModalContainer>
    )
}
