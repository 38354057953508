/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useRef, useState } from 'react'
import { FormHandles, Scope } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { MdPeople } from 'react-icons/md'
import ModalContainer from 'react-modal'
import { toast } from 'react-toastify'
import crypto from 'crypto'
import { Button } from '../../components/Button'
import { Input } from '../../UnformInputs/Input'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { CreateNewUserContainer } from './styles'
import { useCompany } from '../../hooks/use-company'
import {
    cpfFormatter,
    creditCardNumberFormatter,
    formatBirthDay,
    formatExpiry,
    phoneFormatter,
} from '../../utils/maskFunctions'
import { CreateNewUserFilds } from '../../dtos/create-new-user-field'
import { SelectInput } from '../../components/SelectInput'
import { CreateNewUserDto } from '../../dtos/create-new-user'
import { useUser } from '../../hooks/use-user'
import { PaymentMethodType } from '../../enums/payment-method-type.enum'
import { DocumentType } from '../../enums/document-type.enum'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
}

export function CreateNewUser({ isOpen, onRequestClose }: Props) {
    // hooks
    const { company } = useCompany()
    const { createUser } = useUser()

    // ref
    const formRef = useRef<FormHandles>(null)
    // state
    const [busyBtn, setBusyBtn] = useState(false)
    const [number, setNumber] = useState<string>()
    const [expiry, setExpiry] = useState<string>()
    const [document, setDocument] = useState<string>()
    const [phoneFormatted, setPhoneFormatted] = useState('')
    const [cpfFormatted, setCpfFormatted] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [chosenGender, setChosenGender] = useState<{
        value: 'FEMALE' | 'MALE'
        label: string
    }>()

    function createUserDataNormalizer(data: CreateNewUserFilds) {
        if (!chosenGender) {
            return undefined
        }
        data.document.value = cpfFormatter(data.document.value, 'TO_API')
        data.phoneNumber = phoneFormatter(data.phoneNumber, 'TO_API')
        data.sex = chosenGender.value
        const array = data.dateOfBirth.split('/')
        data.dateOfBirth = `${array[2]}-${array[1]}-${array[0]}`
        return data
    }

    function paymentMethodEncoder({
        cvc,
        label,
        name,
    }: {
        cvc: string
        label: string
        name: string
    }) {
        const key = `${'-----BEGIN PUBLIC KEY-----\n'}${
            process.env.REACT_APP_PUBLIC_KEY
        }${'-----END PUBLIC KEY-----'}`
        const hash = crypto
            .publicEncrypt(
                {
                    key,
                    padding: crypto.constants.RSA_PKCS1_PADDING,
                    passphrase: '1234',
                },
                Buffer.from(
                    JSON.stringify({
                        number: number?.replaceAll(' ', ''),
                        code: cvc,
                        expiry,
                    })
                )
            )
            .toString('base64')
        return {
            type: PaymentMethodType.CREDIT_CARD,
            label,
            creditCard: {
                hash,
                holder: {
                    document: {
                        type: 'CPF',
                        value: cpfFormatter(document || '', 'TO_API'),
                    },
                    name,
                },
            },
        }
    }

    async function handleCreateNewUser(data: CreateNewUserFilds) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                firstName: Yup.string().required('Nome obrigatório'),
                lastName: Yup.string().required('Sobrenome obrigatório'),
                dateOfBirth: Yup.string()
                    .required('Data de nascimento obrigatória')
                    .matches(
                        /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
                        'Formato de data inválido'
                    ),
                phoneNumber: Yup.string().required('Telefone obrigatório'),
                password: Yup.string().required('Senha obrigatória'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })

            if (data.paymentMethod?.number === '') delete data.paymentMethod

            if (company) {
                const dataNormalized = createUserDataNormalizer(data)
                if (dataNormalized) {
                    let userData: CreateNewUserDto | undefined = {
                        companyId: company.id,
                        dateOfBirth: dataNormalized.dateOfBirth,
                        document: dataNormalized.document,
                        email: dataNormalized.email,
                        firstName: dataNormalized.firstName,
                        lastName: dataNormalized.lastName,
                        password: dataNormalized.password,
                        phoneNumber: dataNormalized.phoneNumber,
                        sex: dataNormalized.sex,
                        travelerInfo: dataNormalized.travelerInfo,
                    }
                    let paymentMethod: CreateNewUserDto['paymentMethod']
                    if (data.paymentMethod) {
                        const paymentData = paymentMethodEncoder({
                            cvc: data.paymentMethod.cvc,
                            label: data.paymentMethod.label,
                            name: data.paymentMethod.name,
                        })
                        paymentMethod = {
                            label: paymentData.label,
                            type: paymentData.type,
                            creditCard: {
                                ...paymentData.creditCard,
                                holder: {
                                    ...paymentData.creditCard.holder,
                                    document: {
                                        type: DocumentType.CPF,
                                        value: paymentData.creditCard.holder
                                            .document.value,
                                    },
                                },
                            },
                        }
                    }
                    if (paymentMethod) {
                        userData = { ...userData, paymentMethod }
                    }
                    await createUser(userData)
                    toast.success(`Usuário criado com sucesso`)
                    window.location.reload()
                }
            }
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <Form ref={formRef} onSubmit={handleCreateNewUser} noValidate>
                <div className="modal-header">
                    <div className="modal-header-title">
                        <MdPeople />
                        <h1 className="f22-700-dark">Criar novo usuário</h1>
                    </div>
                    <div className="modal-header-description">
                        <div className="modal-header-description--blank" />
                        <p className="f16-500-gray">
                            Crie um usuário vinculado à sua empresa <br />
                        </p>
                    </div>
                </div>
                <button
                    className="close"
                    type="button"
                    onClick={() => onRequestClose()}
                >
                    X
                </button>
                <CreateNewUserContainer>
                    <div className="modal-content">
                        <div className="title">
                            <h1 className="f16-500-gray-secondary">
                                Informações da conta
                            </h1>
                        </div>

                        <div className="form form--grid">
                            <Input label="Nome do usuário" name="firstName" />
                            <Input
                                label="Sobrenome do usuário"
                                name="lastName"
                            />
                        </div>
                        <div className="form--grid">
                            <Input label="E-mail" name="email" />
                            <Input label="Senha" name="password" />
                        </div>
                    </div>

                    <div className="modal-content">
                        <div className="title">
                            <h1 className="f16-500-gray-secondary">
                                Informações do viajante
                            </h1>
                        </div>
                        <Scope path="travelerInfo">
                            <div className="form form--grid">
                                <Input
                                    label="Nome do viajante"
                                    name="firstName"
                                />
                                <Input
                                    label="Sobrenome do viajante"
                                    name="lastName"
                                />
                            </div>
                        </Scope>
                        <div className="form--grid">
                            <Input
                                label="Telefone"
                                name="phoneNumber"
                                value={phoneFormatted}
                                onChange={(e) =>
                                    setPhoneFormatted(
                                        phoneFormatter(
                                            e.target.value as string,
                                            'INPUT'
                                        )
                                    )
                                }
                            />
                            <Input
                                label="Data de nascimento"
                                name="dateOfBirth"
                                onKeyPress={(e) => {
                                    if (/[a-zA-Z]/.test(e.key)) {
                                        e.preventDefault()
                                    }
                                }}
                                value={dateOfBirth}
                                onChange={(e) => {
                                    if (e.target.value.length < 11) {
                                        setDateOfBirth(
                                            formatBirthDay(e.target.value)
                                        )
                                    }
                                }}
                            />
                        </div>
                        <div className="form--grid">
                            <SelectInput
                                name="gender"
                                options={[
                                    { label: 'Masculino', value: 'MALE' },
                                    { label: 'Feminino', value: 'FEMALE' },
                                ]}
                                value={chosenGender}
                                onChange={(e) => {
                                    if (e) {
                                        setChosenGender({
                                            value: e.value as 'MALE' | 'FEMALE',
                                            label: e.label,
                                        })
                                    }
                                }}
                                placeholder="Gênero"
                            />
                            <Scope path="document">
                                <Input
                                    label="CPF"
                                    name="value"
                                    value={cpfFormatted}
                                    onChange={(e) =>
                                        setCpfFormatted(
                                            cpfFormatter(
                                                e.target.value as string,
                                                'INPUT'
                                            )
                                        )
                                    }
                                />
                                <div className="hidden-input">
                                    <Input
                                        label="type"
                                        name="type"
                                        type="hidden"
                                        value="CPF"
                                    />
                                </div>
                            </Scope>
                        </div>
                    </div>
                    <div className="modal-content">
                        <div className="title">
                            <h1 className="f16-500-gray-secondary">
                                Cartão de crédito (opcional)
                            </h1>
                        </div>
                        <Scope path="paymentMethod">
                            <div className="form">
                                <div style={{ marginBottom: '1rem' }}>
                                    <Input
                                        label="Número do cartão"
                                        name="number"
                                        onKeyPress={(e) => {
                                            if (/[a-zA-Z]/.test(e.key)) {
                                                e.preventDefault()
                                            }
                                        }}
                                        value={number}
                                        onChange={(e) => {
                                            if (
                                                ((e.target.value.toString()[0] +
                                                    e.target.value.toString()[1] ===
                                                    '37' ||
                                                    e.target.value.toString()[0] +
                                                        e.target.value.toString()[1] ===
                                                        '34') &&
                                                    e.target.value
                                                        .toString()
                                                        .replaceAll(' ', '')
                                                        .length <= 15) ||
                                                (e.target.value.toString()[0] +
                                                    e.target.value.toString()[1] !==
                                                    '37' &&
                                                    e.target.value.toString()[0] +
                                                        e.target.value.toString()[1] !==
                                                        '34' &&
                                                    e.target.value.toString()[0] +
                                                        e.target.value.toString()[1] !==
                                                        '36' &&
                                                    e.target.value
                                                        .toString()
                                                        .replaceAll(' ', '')
                                                        .length <= 19) ||
                                                (e.target.value.toString()[0] +
                                                    e.target.value.toString()[1] ===
                                                    '36' &&
                                                    e.target.value
                                                        .toString()
                                                        .replaceAll(' ', '')
                                                        .length <= 14)
                                            ) {
                                                setNumber(
                                                    creditCardNumberFormatter(
                                                        e.target.value || ''
                                                    )
                                                )
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form--grid">
                                    <Input
                                        label="Data de expiração"
                                        name="expiry"
                                        value={expiry}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 5) {
                                                setExpiry(
                                                    formatExpiry(e.target.value)
                                                )
                                            }
                                        }}
                                    />
                                    <Input
                                        label="Código de segurança"
                                        name="cvc"
                                    />
                                </div>
                            </div>
                            <div className="form">
                                <div style={{ marginBottom: '1rem' }}>
                                    <Input
                                        label="Nome do titular do cartão"
                                        name="name"
                                    />
                                </div>
                                <div className="form--grid">
                                    <Input label="Identificador" name="label" />
                                    <Input
                                        label="CPF"
                                        name="document"
                                        value={document}
                                        onChange={(e) => {
                                            if (e) {
                                                setDocument(
                                                    cpfFormatter(
                                                        e.target.value,
                                                        'INPUT'
                                                    )
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </Scope>
                    </div>
                    <div className="action-btn">
                        <Button
                            color="PRIMARY"
                            buttonType="FILLED"
                            text="Criar usuário"
                            busy={busyBtn}
                            type="submit"
                        />
                    </div>
                </CreateNewUserContainer>
            </Form>
        </ModalContainer>
    )
}
