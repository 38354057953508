import React from 'react'
import { IconType } from 'react-icons/lib'
import { v4 } from 'uuid'
import { SideMenuBtn, SideMenuContainer } from './styles'

interface Props {
    changeTab(type: string): void
    title: string
    value: string
    sideMenus: {
        label: string
        id: string
        icon?: IconType
    }[]
}

export function SideMenu({ sideMenus, value, title, changeTab }: Props) {
    return (
        <SideMenuContainer>
            <h1 className="title">{title}</h1>
            <div className="scroll">
                {sideMenus.map((menu) => {
                    return (
                        <SideMenuBtn
                            key={v4()}
                            onClick={() => changeTab(menu.id)}
                            isActive={value === menu.id}
                            className="menu"
                            type="button"
                        >
                            {menu.icon && <menu.icon />}
                            <h2>{menu.label}</h2>
                        </SideMenuBtn>
                    )
                })}
            </div>
        </SideMenuContainer>
    )
}
