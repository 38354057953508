import styled from 'styled-components'

export const PaymentMethodsAndLoyaltiesContainer = styled.div`
    width: 100%;
    .payment-methods {
        border-radius: 0.8rem;
        background-color: var(--color-light);
        margin-bottom: 1.5rem;
        &-header {
            width: 100%;
            display: grid;
            grid-template-columns: 3fr 1fr;
            padding: 2rem;
            border-bottom: 1px solid var(--color-background);
            &--title {
                width: 70rem;
            }
        }
        &-form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0 2.5rem;
            &--add-btn {
                button {
                    margin-left: 0;
                    padding-left: 0;
                }
            }
        }
    }
`

export const PaymentOrLoyalty = styled.div`
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 3fr 2fr 1fr 1fr;
    padding: 2.5rem 0;
    border-bottom: 1px solid var(--color-background);
    .payment-loyalty--label--center {
        text-align: center;
    }
    .payment-loyalty--edit,
    .payment-loyalty--remove {
        .edit-btn,
        .remove-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: inherit;
            border: none;
            svg {
                height: 1.8rem;
                width: 1.8rem;
                margin-right: 0.5rem;
            }
        }
        .edit-btn {
            svg {
                color: var(--color-dark);
            }
        }
        .remove-btn {
            svg {
                color: var(--color-warning);
            }
        }
    }
`
