/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import ModalContainer from 'react-modal'
import { IoMdSearch } from 'react-icons/io'
import { BsFillXCircleFill, BsFillPlusCircleFill } from 'react-icons/bs'
import { v4 } from 'uuid'
import { MdList } from 'react-icons/md'
import { AddTravalersInTripContainer, Traveler } from './styles'
import { Avatar } from '../../components/Avatar'
import { SearchInput } from '../../components/SearchInput'
import { Button } from '../../components/Button'
import { useCompany } from '../../hooks/use-company'
import { useEditMember } from '../EditMember/hook/useEditMember'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    action: 'MEMBER' | 'APPROVES_FOR' | 'REQUESTS_FOR'
    managementId?: string
    addedOptions:
        | {
              id: string
              label: string
          }[]
        | undefined
}

export function OptionsToAdd({
    isOpen,
    onRequestClose,
    managementId,
    addedOptions,
    action,
}: Props) {
    const { company, companyManagementInfos } = useCompany()
    const { memberOptionChanger } = useEditMember()

    const [searchOption, setSearchOption] = useState('')

    const managementInfo = companyManagementInfos.find(
        (m) => m.id === managementId
    )

    function elementsToAdd() {
        const data: { id: string; label: string }[] = []
        company?.managementInfo
            .find((m) => m.id === managementId)
            ?.options.forEach((opt) => {
                data.push({ id: opt.id, label: opt.label })
            })
        return data
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button
                className="close"
                type="button"
                onClick={() => onRequestClose()}
            >
                X
            </button>
            <AddTravalersInTripContainer>
                <div className="modal-header">
                    <div className="modal-header-title">
                        <MdList />
                        <h1 className="f22-700-dark">
                            Vincular {managementInfo?.label.toLowerCase()}
                        </h1>
                    </div>
                </div>
                <div className="modal-content">
                    <SearchInput
                        label="Pesquisar"
                        name="search-traveler"
                        icon={IoMdSearch}
                        value={searchOption}
                        onChange={(e) => setSearchOption(e.target.value)}
                    />
                    {!searchOption ? (
                        <>
                            <div className="added-travelers">
                                <h1
                                    className="f14-700-secondary-gray added-travelers--title"
                                    style={{ textTransform: 'uppercase' }}
                                >
                                    {managementInfo?.label} Vinculados
                                </h1>
                                {addedOptions && addedOptions.length > 0 ? (
                                    addedOptions?.map((element) => {
                                        return (
                                            <Traveler key={v4()}>
                                                <div className="traveler-info">
                                                    <div className="traveler-info--logo">
                                                        <Avatar
                                                            label={
                                                                element.label ||
                                                                'x'
                                                            }
                                                        />
                                                    </div>
                                                    <div className="traveler-info--name">
                                                        <p className="f16-500-gray">
                                                            {element.label}
                                                        </p>
                                                    </div>
                                                </div>
                                                <button
                                                    className="action-button remove"
                                                    type="button"
                                                    onClick={() => {
                                                        memberOptionChanger(
                                                            managementId!,
                                                            element,
                                                            'REMOVE',
                                                            action
                                                        )
                                                    }}
                                                >
                                                    <BsFillXCircleFill />
                                                </button>
                                            </Traveler>
                                        )
                                    })
                                ) : (
                                    <h1
                                        className="f14-700-secondary-gray added-travelers--title"
                                        style={{
                                            margin: '2rem 2rem 4rem 2rem',
                                        }}
                                    >
                                        Nenhum item vinculado
                                    </h1>
                                )}
                            </div>
                            <div className="add-travalers">
                                <h1
                                    className="f14-700-secondary-gray add-travelers--title"
                                    style={{ textTransform: 'uppercase' }}
                                >
                                    Vincular {managementInfo?.label}
                                </h1>
                                {elementsToAdd().map((element) => {
                                    if (
                                        addedOptions &&
                                        addedOptions.length > 0 &&
                                        addedOptions.find(
                                            (listElement) =>
                                                element.id === listElement.id
                                        )
                                    ) {
                                        return null
                                    }
                                    return (
                                        <Traveler key={v4()}>
                                            <div className="traveler-info">
                                                <div className="traveler-info--logo">
                                                    <Avatar
                                                        label={
                                                            element.label || 'x'
                                                        }
                                                    />
                                                </div>
                                                <div className="traveler-info--name">
                                                    <p className="f16-500-gray">
                                                        {element.label}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                className="action-button add"
                                                type="button"
                                                onClick={() => {
                                                    memberOptionChanger(
                                                        managementId!,
                                                        element,
                                                        'ADD',
                                                        action
                                                    )
                                                }}
                                            >
                                                <BsFillPlusCircleFill />
                                            </button>
                                        </Traveler>
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <div className="add-travalers">
                            <h1 className="f14-700-secondary-gray add-travelers--title">
                                RESULTADOS
                            </h1>
                            {elementsToAdd().map((element) => {
                                if (
                                    addedOptions?.find(
                                        (elementList) =>
                                            element.id === elementList.id
                                    )
                                ) {
                                    return null
                                }
                                if (
                                    element.label
                                        .toLowerCase()
                                        .includes(searchOption.toLowerCase())
                                ) {
                                    return (
                                        <Traveler key={v4()}>
                                            <div className="traveler-info">
                                                <div className="traveler-info--logo">
                                                    <Avatar
                                                        label={
                                                            element.label || 'X'
                                                        }
                                                    />
                                                </div>
                                                <div className="traveler-info--name">
                                                    <p className="f16-500-gray">
                                                        {element.label}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                className="action-button add"
                                                type="button"
                                                onClick={() => {
                                                    memberOptionChanger(
                                                        managementId!,
                                                        element,
                                                        'ADD',
                                                        action
                                                    )
                                                }}
                                            >
                                                <BsFillPlusCircleFill />
                                            </button>
                                        </Traveler>
                                    )
                                }
                                return null
                            })}
                        </div>
                    )}
                </div>
                <div className="save-btn">
                    <Button
                        buttonType="FILLED"
                        text="Salvar"
                        color="PRIMARY"
                        type="button"
                        onClick={() => {
                            onRequestClose()
                        }}
                    />
                </div>
            </AddTravalersInTripContainer>
        </ModalContainer>
    )
}
