/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { MdOutlineAddShoppingCart } from 'react-icons/md'
import { ControlButton } from '../../../components/ControlButton'
import { SelectInput } from '../../../components/SelectInput'
import { TitleDescription } from '../../../components/TitleDescription'
import { useCompany } from '../../../hooks/use-company'
import { ApprovalFlowSetting } from '../../../interfaces/traveler-clearance/approval-flow-setting'
import {
    ReturnApprovalFlowLabel,
    ReturnApprovalLevelLabel,
} from '../../../utils/users/functions'
import { DropDownPolitic } from '../headerPolicy'
import { SinglePolicieContainer } from './styles'
import { toolTips } from '../data/labels'
import { ExpensePolicy } from '../../../interfaces/traveler-clearance/traveler-clearance/polices/expense-policy'

interface Props {
    invoiceExpenses: ExpensePolicy['invoice']
}

export function InvoiceExpenses({ invoiceExpenses }: Props) {
    // hooks
    const { editTravelerClearance, selectTravelerClearance, company } =
        useCompany()
    const [invExpense, setInvExpense] =
        useState<ExpensePolicy['invoice']>(invoiceExpenses)

    const approvalLevelOptions: {
        label: string
        value: number
    }[] = new Array(company?.maxApprovalLevel || 1).fill(1).map((_, i) => {
        return {
            label: `Nivel ${i + 1}`,
            value: i + 1,
        }
    })

    useEffect(() => {
        setInvExpense(invoiceExpenses)
    }, [selectTravelerClearance])

    return (
        <DropDownPolitic
            title="Despesas"
            description="Despesas que fogem das regras configuradas abaixo serão consideradas como fora da política de viagens."
            icon={MdOutlineAddShoppingCart}
            callback={async () => {
                await editTravelerClearance(selectTravelerClearance!.id, {
                    expense: { invoice: invExpense },
                })
            }}
        >
            <SinglePolicieContainer>
                <TitleDescription title="Permitir fazer solicitações de despesas?" />
                <div className="inputs">
                    <ControlButton
                        id="invExpense.productEnabled"
                        type="SWITCH"
                        isPressed={invExpense.enabled}
                        callback={() => {
                            setInvExpense((prevState: any) => {
                                return {
                                    ...prevState,
                                    enabled: !prevState.enabled,
                                }
                            })
                        }}
                    />
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription
                    title="Fluxo de aprovação"
                    description="Defina quando uma despensa precisa ser aprovada e quantos níveis de aprovação devem existir."
                    info={toolTips.approvalFlow}
                />
                <div className="inputs">
                    {/* <SelectInput
                        name="invoiceExpense"
                        value={{
                            label: ReturnApprovalFlowLabel(
                                invExpense.approvalFlow.setting
                            ),
                            value: invExpense.approvalFlow.setting,
                        }}
                        options={[
                            {
                                label: 'Sempre',
                                value: ApprovalFlowSetting.ALWAYS,
                            },
                            {
                                label: 'Dentro da política',
                                value: ApprovalFlowSetting.INSIDE_POLICY,
                            },
                            {
                                label: 'Nunca',
                                value: ApprovalFlowSetting.NEVER,
                            },
                        ]}
                        onChange={(e) => {
                            if (e) {
                                setInvExpense((prevState) => {
                                    return {
                                        ...prevState,
                                        approvalFlow: {
                                            ...prevState.approvalFlow,
                                            setting: e.value,
                                        },
                                    }
                                })
                            }
                        }}
                    /> */}
                    <div className="label-input">
                        <p>Defina a quantidade de níveis</p>
                        <SelectInput
                            name="invoiceExpense2"
                            value={{
                                label: ReturnApprovalLevelLabel(
                                    invExpense.approvalFlow
                                        .requiredApprovalLevel
                                ),
                                value: invExpense.approvalFlow
                                    .requiredApprovalLevel,
                            }}
                            options={approvalLevelOptions}
                            onChange={(e) => {
                                if (e) {
                                    setInvExpense((prevState) => {
                                        return {
                                            ...prevState,
                                            approvalFlow: {
                                                ...prevState.approvalFlow,
                                                requiredApprovalLevel: e.value,
                                            },
                                        }
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
            </SinglePolicieContainer>
        </DropDownPolitic>
    )
}
