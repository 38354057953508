import styled from 'styled-components'

export const OrderedCardContainer = styled.div`
    display: flex;
    align-items: center;
    .circle-number {
        width: 3.2rem;
        height: 3.2rem;
        background-color: #191b1e;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        flex-shrink: 0;
        span {
            font-weight: 500;
            font-size: 1.4;
            color: #fff;
        }
    }
    .description {
        position: relative;
        .tooltip {
            position: absolute;
            top: -3rem;
            padding: 0.5rem;
            border-radius: 0.8rem;
            background-color: #3233;
            white-space: nowrap;
            z-index: 100;
            max-width: none;
        }
        h1 {
            &:hover {
                cursor: pointer;
            }
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 16rem;
        }
        .margin-bottom {
            margin-bottom: 0.5rem;
        }
    }
    &:not(:last-child) {
        margin-bottom: 3rem;
    }
`
