import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { FlownChartContainer } from './styles'
import { ExpensesMetrics } from '../../interfaces/expensesMetrics'

ChartJS.register(ArcElement, Tooltip, Legend)

interface FlownChartProps {
    data: ExpensesMetrics['expenseCategoryRanking']
    totalAmount: number
}

export function ExpensesFlowChart({ data, totalAmount }: FlownChartProps) {
    const colorLists = [
        '#1B4332',
        '#2D6A4F',
        '#40916C',
        '#52B788',
        '#74C69D',
        '#95D5B2',
        '#B7E4C7',
        '#D8F3DC',
        '#012A4A',
        '#013A63',
        '#01497C',
        '#014F86',
        '#2A6F97',
        '#2C7DA0',
        '#468FAF',
        '#61A5C2',
        '#89C2D9',
        '#A9D6E5',
    ]
    const colors = data.map((d, i) => colorLists[i % colorLists.length])
    const value = data.map((d) => (d.totalExpenses / totalAmount) * 100)
    const flowChartData = {
        datasets: [
            {
                data: value,
                backgroundColor: colors,
            },
        ],
    }

    return (
        <FlownChartContainer>
            <div className="flown-title">
                <h1 className="f18-700-dark margin">Categorias de despesas</h1>
                <p className="f16-500-gray">
                    Percentual de despesas realizadas em cada categoria
                </p>
            </div>
            <div className="chart-content expenses-chart-content">
                <div className="chart">
                    <Doughnut
                        data={flowChartData}
                        options={{
                            cutout: 60,
                            plugins: {
                                datalabels: {
                                    color: 'transparent',
                                },
                            },
                        }}
                    />
                </div>
                <div className="labels-container">
                    {data.map((d, i) => (
                        <div className="line">
                            <div className="label">
                                <div
                                    className="circle"
                                    style={{ backgroundColor: colors[i] }}
                                />
                                <h2 className="f16-500-dark">{d.category}</h2>
                            </div>
                            <div className="value">
                                <p className="f16-500-dark">
                                    {(
                                        (d.totalExpenses / totalAmount) *
                                        100
                                    ).toFixed(2)}
                                    %
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </FlownChartContainer>
    )
}
