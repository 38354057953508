import styled from 'styled-components'

export const ErrorBoundaryContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 10rem;
    background-color: #fff;
    .error-title {
        font-size: 8rem;
        font-weight: 700;
        color: #333;
        margin-bottom: 2rem;
    }
    .error-description {
        font-size: 2rem;
        color: #333;
    }
    .error-container {
        margin: 2rem 0;
        max-width: 50rem;
        padding: 2rem;
        background-color: #eee;
        border-radius: 2rem;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        overflow-x: auto;
    }
    .btns {
        display: flex;
        max-width: 60rem;
        gap: 2rem;
    }
    .error-image {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
`
