import styled from 'styled-components'
import background from '../../assets/config-background.png'

export const ConfigurationContainerStyle = styled.div`
    height: 100vh;
    width: 100%;
    background: url(${background});
    background-repeat: no-repeat;
    background-position: center top;
    .content {
        width: 88rem;
        margin: 0 auto;
        &-user-avatar {
            padding-top: 5.3rem;
            display: flex;
            align-items: flex-star;
            &--img {
                margin-left: 3rem;

                input {
                    display: none;
                }
                .avatar-image-shape {
                    width: 13.6rem;
                    height: 13.6rem;
                    background-color: var(--color-light);
                    border-radius: 50%;
                    transform: translateX(-3rem);
                    figcaption {
                        color: #fff;
                    }
                    .avatar-div {
                        width: 13.6rem;
                        height: 13.6rem;
                        border-radius: 50%;
                        background-color: var(--color-primary);
                        backface-visibility: hidden;
                        transition: all 0.5s ease;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        p {
                            font-size: 4.2rem;
                            font-weight: 700;
                            color: #fff;
                        }
                    }
                    img {
                        border-radius: 50%;
                        width: 13.6rem;
                        height: 13.6rem;
                        backface-visibility: hidden;
                        transition: all 0.5s ease;
                    }
                    .avatar-image-text {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, 20%);
                        color: $color-dark;
                        text-transform: uppercase;
                        font-size: 1.7rem;
                        text-align: center;
                        opacity: 0;
                        transition: all 0.5s ease;
                        backface-visibility: hidden;
                    }

                    &:hover .avatar-image-text {
                        opacity: 1;
                        transform: translate(-50%, -50%);
                        cursor: pointer;
                    }
                    &:hover img,
                    &:hover .avatar-div {
                        filter: blur(2px) brightness(80%);
                        cursor: pointer;
                    }
                }
            }

            &-info {
                &--name {
                    font-weight: 700;
                    font-size: 4.8rem;
                    color: var(--color-light);
                }
                &--company {
                    font-weight: 700;
                    font-size: 3.2rem;
                    color: var(--color-light);
                }
                &--email {
                    font-weight: 500;
                    font-size: 1.6rem;
                    color: var(--color-light);
                }
            }
        }
        &-nav {
            padding-top: 0.4rem;
            background-color: var(--color-light);
            width: 100%;
            border-radius: 0.8rem;
            margin: 3.8rem 0 1.5rem 0;
        }
    }
`
