import styled, { css } from 'styled-components'

interface SubTasContainerProps {
    direction: 'HORIZONTAL' | 'VERTICAL'
}

export const SubTabsContainer = styled.div<SubTasContainerProps>`
    display: flex;
    ${(props) => {
        if (props.direction === 'HORIZONTAL') {
            return css`
                flex-direction: row;
                align-items: center;
                justify-content: center;
            `
        }
        return css`
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        `
    }}
`

interface TabProps {
    selectedTab: boolean
    direction: 'HORIZONTAL' | 'VERTICAL'
}

export const Tab = styled.button<TabProps>`
    border: none;
    outline: none;
    background-color: inherit;
    display: flex;
    a {
        text-decoration: none;
        color: var(--color-dark);
    }
    .label {
        display: flex;
        align-items: center;
        .tab-label {
            font-size: 1.6rem;
            color: var(--color-dark);
            transition: all 0.2s;
        }
    }
    .selector {
        transition: all 0.2s;
    }
    ${(p) => {
        if (p.direction === 'HORIZONTAL') {
            return css`
                min-width: 16rem;
                flex-direction: column;
                align-items: center;
                .label {
                    padding: 1.6rem;
                    justify-content: center;
                    svg {
                        margin-right: 1rem;
                    }
                }
                .selector {
                    width: 100%;
                    height: 0.4rem;
                    margin-bottom: -0.1rem;
                    border-top-left-radius: 0.4rem;
                    border-top-right-radius: 0.4rem;
                }
            `
        }
        return css`
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;
            height: 4.8rem;
            .label {
                justify-content: start;
                .tab-label {
                    margin-left: 1.4rem;
                }
                svg {
                    margin-left: 1.2rem;
                }
            }
            .selector {
                height: 4.8rem;
                width: 0.4rem;
                border-top-right-radius: 0.4rem;
                border-bottom-right-radius: 0.4rem;
            }
        `
    }}
    ${(props) => {
        if (props.direction === 'HORIZONTAL') {
            if (props.selectedTab) {
                return css`
                    .label {
                        .tab-label {
                            font-weight: 700;
                        }
                    }
                    .selector {
                        background-color: var(--color-dark);
                    }
                `
            }
            return css`
                .selector {
                    margin-top: 0.3rem;
                    background-color: inherit;
                    height: 0.1rem;
                }
            `
        }
        if (props.selectedTab) {
            return css`
                min-width: 16rem;
                background-color: var(--color-light);
                .label {
                    .tab-label {
                        font-weight: 700;
                    }
                }
                .selector {
                    background-color: var(--color-dark);
                }
            `
        }
        return css`
            .selector {
                /* margin-top: 0.3rem; */
                background-color: var(--color-secondary-gray);
                width: 0.1rem;
            }
        `
    }}
`
