import React, { ButtonHTMLAttributes } from 'react'
import { IconType } from 'react-icons/lib'
import { Button } from '../Button'
import { InnerHeaderContainer } from './styles'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    title: string
    btnLabel?: string
    subTitle?: string
    icon?: IconType
    callback?(): void
}

export function InnerHeader({
    btnLabel,
    subTitle,
    title,
    icon: Icon,
    callback,
}: Props) {
    return (
        <InnerHeaderContainer>
            <div className="content">
                <div className="icon-title">
                    <div className="icon">{Icon && <Icon />}</div>
                    <div className="title">
                        <h1>{title}</h1>
                        <p>{subTitle}</p>
                    </div>
                </div>
                {btnLabel ? (
                    <Button
                        buttonType="FILLED"
                        color="PRIMARY"
                        text={btnLabel}
                        onClick={() => callback && callback()}
                    />
                ) : (
                    <div className="blank" />
                )}
            </div>
        </InnerHeaderContainer>
    )
}
