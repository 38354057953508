/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { MdListAlt } from 'react-icons/md'
import ModalContainer from 'react-modal'
import { toast } from 'react-toastify'
import { Button } from '../../components/Button'
import { Input } from '../../UnformInputs/Input'
import { getValidationErrors } from '../../utils/getValidationErrors'
import { InviteUserContainer } from './styles'
import { useCompany } from '../../hooks/use-company'
import { UsersLinkedContainer } from '../PaymentMethodHandler/styles'
import { ManagementInfoOption } from '../../entities/management-info-option'
import { usePayment } from '../../hooks/use-payment'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    managementId: string
    shouldRefresh: () => void
    option?: ManagementInfoOption
}

export function CostCenterHandler({
    isOpen,
    onRequestClose,
    managementId,
    shouldRefresh,
    option,
}: Props) {
    // hooks
    const {
        addManagementInfoOption,
        company,
        getMembers,
        members,
        userIdsToAddInOption,
        userIdsToAddInOptionHandler,
        editOption,
    } = useCompany()
    const { elementToAddSettingsHandler } = usePayment()

    // ref
    const formRef = useRef<FormHandles>(null)
    // state
    const [busyBtn, setBusyBtn] = useState(false)
    const [externalId, setExternalId] = useState<string>(() => {
        if (option) return option.externalId
        return ''
    })
    const [label, setLabel] = useState<string>(() => {
        if (option) return option.label
        return ''
    })

    const managementInfoLabel = company?.managementInfo.find(
        (management) => management.id === managementId
    )?.label

    useEffect(() => {
        ;(async () => {
            if (company) {
                await getMembers(company.id)
            }
        })()
    }, [])

    async function handleOption(data: { externalId: string; label: string }) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const schema = Yup.object().shape({
                externalId: Yup.string().required('Insira um código'),
                label: Yup.string().required('Insira um nome'),
            })
            await schema.validate(data, {
                abortEarly: false,
            })

            if (option && managementId) {
                const response = await editOption(managementId, option.id, {
                    externalId,
                    label,
                    userIds:
                        option.users.length > 0
                            ? option.users.map((u) => u.id)
                            : [],
                })
                if (response) {
                    toast.success(`Projeto ${option.label} editado`)
                    setBusyBtn(false)
                    shouldRefresh()
                    userIdsToAddInOptionHandler([])
                    onRequestClose()
                }
                setBusyBtn(false)
                return
            }

            const response = await addManagementInfoOption(managementId, {
                ...data,
                userIds: userIdsToAddInOption,
            })
            if (response) {
                toast.success(`${managementInfoLabel} ${data.label} criado`)
                setBusyBtn(false)
                shouldRefresh()
                userIdsToAddInOptionHandler([])
                onRequestClose()
            }
            setBusyBtn(false)
        } catch (error) {
            setBusyBtn(false)
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    function returnLinkedLabel(list: string[]) {
        if (list.length === 0) {
            return 'Não existem viajantes vinculados'
        }
        if (list.length <= 2) {
            return `${list.map((t) => t).join(', ')}`
        }
        return `${list[0]}, ${list[1]}... +${list.length - 2}`
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={() => {
                userIdsToAddInOptionHandler([])
                onRequestClose()
            }}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <Form ref={formRef} onSubmit={handleOption} noValidate>
                <div className="modal-header">
                    <div className="modal-header-title">
                        <MdListAlt />
                        <h1 className="f22-700-dark">{`${
                            option ? option.label : 'Novo Campo gerencial'
                        }`}</h1>
                    </div>
                    <div className="modal-header-description">
                        <div className="modal-header-description--blank" />
                        <p className="f16-500-gray">
                            Defina as informações do Campo gerencial.
                        </p>
                    </div>
                </div>
                <button
                    className="close"
                    type="button"
                    onClick={() => {
                        userIdsToAddInOptionHandler([])
                        onRequestClose()
                    }}
                >
                    X
                </button>
                <InviteUserContainer>
                    <div className="modal-content">
                        <div className="title">
                            <h1 className="f16-500-gray-secondary">
                                Informações Básicas
                            </h1>
                        </div>

                        <div className="form">
                            <Input
                                label="Código"
                                name="externalId"
                                value={externalId}
                                onChange={(e) => setExternalId(e.target.value)}
                            />
                            <Input
                                label="Nome"
                                name="label"
                                value={label}
                                onChange={(e) => setLabel(e.target.value)}
                            />
                        </div>
                    </div>
                </InviteUserContainer>
                {option && (
                    <UsersLinkedContainer>
                        <div className="modal-content">
                            <div className="title">
                                <h1 className="f16-500-gray-secondary">
                                    Usuários vinculados à este Campo gerencial
                                </h1>
                            </div>
                            <div className="row-button">
                                <h1 className="f16-500-gray">
                                    {returnLinkedLabel(
                                        option
                                            ? option.users.map(
                                                  (user) =>
                                                      `${
                                                          members.find(
                                                              (member) =>
                                                                  member.id ===
                                                                  user.id
                                                          )?.firstName
                                                      } ${
                                                          members.find(
                                                              (member) =>
                                                                  member.id ===
                                                                  user.id
                                                          )?.lastName || ''
                                                      }`
                                              )
                                            : userIdsToAddInOption.map(
                                                  (user) =>
                                                      `${
                                                          members.find(
                                                              (member) =>
                                                                  member.id ===
                                                                  user
                                                          )?.firstName
                                                      } ${
                                                          members.find(
                                                              (member) =>
                                                                  member.id ===
                                                                  user
                                                          )?.lastName || ''
                                                      }`
                                              )
                                    )}
                                </h1>
                                <Button
                                    color="PRIMARY"
                                    buttonType="TEXT"
                                    text="Vincular"
                                    type="button"
                                    onClick={() =>
                                        elementToAddSettingsHandler({
                                            id: option?.id || 'NO_ID',
                                            option: option || undefined,
                                            managementId,
                                            type: option
                                                ? 'ADD_USER_TO_OPTION'
                                                : 'ADD_USER_TO_CREATE_OPTION',
                                            list: option
                                                ? option.users.map((user) => {
                                                      return {
                                                          id: user.id,
                                                          label: `${
                                                              members.find(
                                                                  (member) =>
                                                                      member.id ===
                                                                      user.id
                                                              )?.firstName || ''
                                                          } ${
                                                              members.find(
                                                                  (member) =>
                                                                      member.id ===
                                                                      user.id
                                                              )?.lastName || ''
                                                          }`,
                                                          url: members.find(
                                                              (member) =>
                                                                  member.id ===
                                                                  user.id
                                                          )?.photo
                                                              ? members.find(
                                                                    (member) =>
                                                                        member.id ===
                                                                        user.id
                                                                )?.photo?.url
                                                              : undefined,
                                                      }
                                                  })
                                                : [],
                                            listToAdd: members.map((member) => {
                                                return {
                                                    id: member.id,
                                                    label: `${
                                                        member.firstName
                                                    } ${member.lastName || ''}`,
                                                    url: member.photo
                                                        ? member.photo.url
                                                        : undefined,
                                                }
                                            }),
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </UsersLinkedContainer>
                )}
                <div className="modal-confirm-btn">
                    <Button
                        color="PRIMARY"
                        buttonType="FILLED"
                        text={`${option ? `Salvar` : 'Criar Campo gerencial'}`}
                        busy={busyBtn}
                        type="submit"
                    />
                </div>
            </Form>
        </ModalContainer>
    )
}
