import React, { ReactNode } from 'react'
import { IntercomProvider } from 'react-use-intercom'
import { useUser } from '../../hooks/use-user'
import { useCompany } from '../../hooks/use-company'

interface Props {
    children: ReactNode
}

export function Intercom({ children }: Props) {
    const { user } = useUser()
    const { company } = useCompany()

    if (!user || !company) {
        return <>{children}</>
    }

    return (
        <IntercomProvider
            appId="o4cs133p"
            autoBoot
            autoBootProps={{
                name: `${user?.firstName} ${user?.lastName}`,
                email: `${user?.email}`,
                userId: user?.id,
                phone: user?.phoneNumber,
                // company: {
                //     name: company?.name,
                //     companyId: company?.id || '',
                // },
            }}
        >
            {children}
        </IntercomProvider>
    )
}
