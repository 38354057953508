/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import { FiPlusCircle } from 'react-icons/fi'
import { MdCorporateFare, MdLocationPin } from 'react-icons/md'
import { v4 } from 'uuid'
// import { Button } from '../../../../components/Button'
import { InnerHeader } from '../../../../components/InnerHeader'
import { useCompany } from '../../../../hooks/use-company'
import { OfficeHandler } from '../../../../modals/OfficeHandler'
import { Office, OfficeInfoContainer } from './styles'
import { GeneralSubPageContent } from '../styles'

export function OfficeInfo() {
    // hooks
    const { company } = useCompany()
    // state
    const [openOfficeHandler, setOpenOfficeHandler] = useState(false)

    return (
        <GeneralSubPageContent>
            <OfficeInfoContainer>
                <InnerHeader
                    title="Escritórios"
                    callback={() => {}}
                    icon={MdCorporateFare}
                />
                {company?.officeAddresses.map((office, index) => {
                    return (
                        <Office key={v4()}>
                            <div className="content">
                                <div className="title">
                                    <h1>
                                        {index === 0
                                            ? 'Principal'
                                            : `Unidade de ${office.city}`}
                                    </h1>
                                    {index === 0 && (
                                        <p>(endereço da empresa)</p>
                                    )}
                                </div>
                                <div className="description">
                                    <div className="info">
                                        <MdLocationPin />
                                        <div className="info-labels">
                                            <h1>{`${office.city}, ${office.state} - ${office.zipCode}`}</h1>
                                            <h2>{`${office.street}, ${office.number}`}</h2>
                                        </div>
                                    </div>
                                    {index === 0 ? (
                                        <div className="blank" />
                                    ) : (
                                        <div className="edit-btn">
                                            {/* <Button
                                            buttonType="TEXT"
                                            color="PRIMARY"
                                            text="Editar"
                                        /> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Office>
                    )
                })}
                <button
                    type="button"
                    className="add-officer"
                    onClick={() => setOpenOfficeHandler(true)}
                >
                    <FiPlusCircle />
                    <h2>Cadastrar outro escritório</h2>
                </button>
                <OfficeHandler
                    isOpen={openOfficeHandler}
                    onRequestClose={() => setOpenOfficeHandler(false)}
                />
            </OfficeInfoContainer>
        </GeneralSubPageContent>
    )
}
