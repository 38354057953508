import { SystemClearance } from '../../enums/system-clearance.enum'
import { ApprovalFlowSetting } from '../../interfaces/traveler-clearance/approval-flow-setting'
import { PriceType } from '../../interfaces/traveler-clearance/price-type'

export function getInitialWords(label: string) {
    if (label[0] === ' ') return ' '
    if (label) {
        const names = label.split(' ')
        if (names.length > 1) {
            const first = names[0]
            const second = names[names.length - 1]
            if (second) {
                return `${first !== undefined ? first[0].toUpperCase() : ''}${
                    second[0] !== undefined ? second[0].toUpperCase() : ''
                }`
            }
            return `${names[0][0]}`
        }

        return `${label[0].toUpperCase()}`
    }
    return ''
}

export function returnSystemClearanceLabel(type: SystemClearance) {
    switch (type) {
        case SystemClearance.ADMIN:
            return 'Administrador'
        case SystemClearance.MANAGER:
            return 'Gerente'
        default:
            return 'Viajante'
    }
}

export function ReturnApprovalFlowLabel(type: ApprovalFlowSetting) {
    if (type === ApprovalFlowSetting.ALWAYS) {
        return 'Sempre'
    }
    if (type === ApprovalFlowSetting.INSIDE_POLICY) {
        return 'Dentro da política'
    }
    if (type === ApprovalFlowSetting.NEVER) {
        return 'Nunca'
    }
    return ''
}

export function ReturnFlightFareLabel(type: string) {
    if (type === 'ECONOMIC') {
        return 'Econômica'
    }
    if (type === 'ECONOMIC_PLUS') {
        return 'Econômica Plus'
    }
    if (type === 'ECONOMIC_TOP') {
        return 'Econômica Top'
    }
    return ''
}

export function ReturnApprovalLevelLabel(type: number) {
    if (type === 1) {
        return '1 nível'
    }
    if (type === 2) {
        return '2 níveis'
    }
    if (type === 3) {
        return '3 níveis'
    }
    return '4 níveis'
}

export function PriceTypeLabel(type: PriceType) {
    if (type === PriceType.FIXED_PRICE) {
        return 'Valor máximo'
    }
    return 'Preços dinâmicos'
}
