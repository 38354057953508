'use client'

import React, { useState } from 'react'
import ModalContainer from 'react-modal'
import { IoMdSearch } from 'react-icons/io'
import { v4 } from 'uuid'
import { AddTravalersInTripContainer, Traveler } from './styles'
import { Avatar } from '../../components/Avatar'
import { SearchInput } from '../../components/SearchInput'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    label?: string
    users: {
        id: string
        label: string
        url?: string | undefined
    }[]
}

export function ListMembersViewer({
    isOpen,
    onRequestClose,
    users,
    label = 'Todos os usuários.',
}: Props) {
    const [searchTraveler, setSearchTraveler] = useState('')

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            <button
                className="close"
                type="button"
                onClick={() => onRequestClose()}
            >
                X
            </button>
            <AddTravalersInTripContainer>
                <div className="modal-content">
                    <div className="title">
                        <h1 className="f16-500-gray-secondary">{label}</h1>
                    </div>
                    <SearchInput
                        label="Pesquisar"
                        name="search-traveler"
                        icon={IoMdSearch}
                        value={searchTraveler}
                        onChange={(e) => setSearchTraveler(e.target.value)}
                    />
                    {!searchTraveler ? (
                        <>
                            <div className="added-travelers">
                                <h1 className="f14-700-secondary-gray added-travelers--title">
                                    {label || 'VIAJANTES ADICIONADOS'}
                                </h1>
                                {users.map((element) => {
                                    return (
                                        <Traveler key={v4()}>
                                            <div className="traveler-info">
                                                <div className="traveler-info--logo">
                                                    <Avatar
                                                        label={element.label}
                                                        img={
                                                            element.url
                                                                ? element.url
                                                                : undefined
                                                        }
                                                    />
                                                </div>
                                                <div className="traveler-info--name">
                                                    <p className="f16-500-gray">
                                                        {element.label}
                                                    </p>
                                                </div>
                                            </div>
                                        </Traveler>
                                    )
                                })}
                            </div>
                        </>
                    ) : (
                        <div className="add-travalers">
                            <h1 className="f14-700-secondary-gray add-travelers--title">
                                RESULTADOS
                            </h1>
                            {users.map((element) => {
                                if (
                                    element.label
                                        .toLowerCase()
                                        .includes(searchTraveler.toLowerCase())
                                ) {
                                    return (
                                        <Traveler key={v4()}>
                                            <div className="traveler-info">
                                                <div className="traveler-info--logo">
                                                    <Avatar
                                                        label={element.label}
                                                        img={
                                                            element.url
                                                                ? element.url
                                                                : undefined
                                                        }
                                                    />
                                                </div>
                                                <div className="traveler-info--name">
                                                    <p className="f16-500-gray">
                                                        {element.label}
                                                    </p>
                                                </div>
                                            </div>
                                        </Traveler>
                                    )
                                }
                                return null
                            })}
                        </div>
                    )}
                </div>
            </AddTravalersInTripContainer>
        </ModalContainer>
    )
}
