/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import { FaRegListAlt } from 'react-icons/fa'
import { MdSearch } from 'react-icons/md'
import { BsPlusCircle } from 'react-icons/bs'
import { toast } from 'react-toastify'
import { useCompany } from '../../../../hooks/use-company'
import { ContactInfoContainer, ExpensesInfoContainer } from './styles'
import { GeneralSubPageContent } from '../styles'
import { useExpenses } from '../../../../hooks/use-expenses'
import { Button } from '../../../../components/Button'
import { SearchInput } from '../../../../components/SearchInput'
import { Table } from '../../../../utils/table'
import { ControlButton } from '../../../../components/ControlButton'
import { ExpenseCategoryHandler } from '../../../../modals/ExpenseCategoryHandler'
import { ExpensesCategory } from '../../../../entities/expenses-category'

export function ExpenseCategory() {
    // hooks
    const { company } = useCompany()
    const {
        getCompanyExpenseCategories,
        expenseCategories,
        editExpenseCategories,
        openEditExpenseCategory,
        changeExpenseCategory,
    } = useExpenses()
    const [search, setSearch] = useState('')
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        if (company) {
            ;(async () => {
                await getCompanyExpenseCategories()
            })()
        }
    }, [])

    async function editActiveStatus({
        id,
        label,
        active,
    }: {
        id: string
        label?: string
        active?: boolean
    }) {
        const response = await editExpenseCategories({
            id,
            label,
            active: !active,
        })
        if (response) {
            toast.success('Status alterado com sucesso!')
        }
    }

    return (
        <ExpensesInfoContainer>
            <GeneralSubPageContent>
                <ContactInfoContainer>
                    <div className="content">
                        <div className="icon-title">
                            <div className="icon">
                                <FaRegListAlt />
                            </div>
                            <div className="title">
                                <h1>Categoria de despesas</h1>
                            </div>
                        </div>
                        <div className="flex">
                            <p className="f14-500-gray">
                                {expenseCategories.length === 1
                                    ? `1 Categoria
                                cadastrada`
                                    : `${expenseCategories.length} Categorias
                                cadastradas`}
                            </p>
                            <Button
                                buttonType="BORDERED"
                                color="PRIMARY"
                                text="Nova categoria"
                                icon={BsPlusCircle}
                                onClick={() => setOpenModal(true)}
                            />
                        </div>
                    </div>
                </ContactInfoContainer>
            </GeneralSubPageContent>
            <GeneralSubPageContent>
                <SearchInput
                    name="search"
                    label="Pesquisar"
                    icon={MdSearch}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </GeneralSubPageContent>
            <GeneralSubPageContent>
                <div className="table-head">
                    <div className="table-head-item f16-500-gray">
                        Categoria
                    </div>
                    <div className="table-head-item f16-500-gray">Ativa</div>
                    <div className="table-head-item f16-500-gray">Ação</div>
                </div>
                {expenseCategories
                    .filter((category) =>
                        category.label
                            .toLowerCase()
                            .includes(search.toLowerCase())
                    )
                    .map((expenseCategory, index) => {
                        return (
                            <div
                                className="table-body"
                                key={expenseCategory.label}
                            >
                                <div className="table-body-item f16-700-dark">
                                    {expenseCategory.label}
                                </div>
                                <div className="table-body-item">
                                    <ControlButton
                                        callback={() =>
                                            editActiveStatus({
                                                id: expenseCategory.id,
                                                active: expenseCategory.active,
                                            })
                                        }
                                        type="SWITCH"
                                        id={expenseCategory.label}
                                        isPressed={expenseCategory.active}
                                    />
                                </div>
                                <div className="table-body-item">
                                    <button
                                        type="button"
                                        className="edit-btn"
                                        onClick={() =>
                                            changeExpenseCategory(
                                                expenseCategory
                                            )
                                        }
                                    >
                                        Editar
                                    </button>
                                </div>
                            </div>
                        )
                    })}
            </GeneralSubPageContent>
            {openModal && (
                <ExpenseCategoryHandler
                    onRequestClose={() => setOpenModal(false)}
                    isOpen={openModal}
                />
            )}
            {!!editExpenseCategories && (
                <ExpenseCategoryHandler
                    onRequestClose={() => changeExpenseCategory(undefined)}
                    isOpen={!!openEditExpenseCategory}
                    expenseCategory={openEditExpenseCategory}
                />
            )}
        </ExpensesInfoContainer>
    )
}
