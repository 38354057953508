import styled from 'styled-components'

export const ExpensesModalContainer = styled.div`
    max-height: 80vh;
    overflow-y: auto;
    .history-btn {
        border: none;
        background-color: inherit;
        color: var(--color-primary);
        font-weight: 700;
        font-size: 1.4rem;
        &:hover {
            cursor: pointer;
        }
    }
    .header {
        background-color: #fff;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 3.6rem 2.1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .description {
            display: flex;
            align-items: center;
            gap: 2rem;
        }
        .history-btn {
            border: none;
            background-color: inherit;
            color: var(--color-primary);
            font-weight: 700;
            font-size: 1.4rem;
            &:hover {
                cursor: pointer;
            }
        }
        .price-date {
            display: flex;
            justify-items: center;
            align-items: center;
            gap: 1rem;
            .out-of-policy {
                color: #e8604a;
                svg {
                    color: #e8604a;
                    height: 2.2rem;
                    width: 2.2rem;
                }
            }
        }
        margin-bottom: 1.5rem;
    }
    .content {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        &-block {
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            width: 100%;
            padding: 1.5rem;
            background-color: #fff;
            .italic {
                font-style: italic;
            }
            .container {
                .label-value {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 2rem 0;
                    border-bottom: 1px solid #f4f4f4;
                }
            }
        }
        margin-bottom: 1.5rem;
    }
    .comments {
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        width: 100%;
        padding: 1.5rem;
        background-color: #fff;
        margin-top: 2rem;
        .out-of-policy {
            .out-of-policy-message {
                font-size: 1.6rem;
                color: #111;
                margin-top: 1rem;
            }
        }
        .management-info {
            min-height: 4rem;
            margin-top: 1.5rem;
            border-radius: 8px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            .label-value-m {
                padding: 1rem 0;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
            }
        }
        .description {
            min-height: 8rem;
            margin-top: 1.5rem;
            padding: 1.5rem;
            border-radius: 8px;
            border: 1px solid #c8cacd;
            background-color: #edeff2;
        }
    }
`
