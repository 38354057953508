/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react'
import { Header } from '../../components/Header'
import { PillTabs } from '../../components/PillTabs'
import { GeneralSubPage } from './Configuration-General'
import { ManagementSubPage } from './Configuration-Management'
import { PaymentMethodsSubPage } from './Configuration-PaymentMethods'
import { ProvidersSubPage } from './Configuration-Providers'
import { ConfigurationContainer } from './styles'

export function ConfigurationPage() {
    const [selectedSubMenu, setSelectedSubMenu] = useState<
        'GENERAL' | 'PAYMENT' | 'MANAGEMENT' | 'PROVIDERS'
    >('GENERAL')

    function changeSubMenu(
        type: 'GENERAL' | 'PAYMENT' | 'MANAGEMENT' | 'PROVIDERS'
    ) {
        setSelectedSubMenu(type)
    }

    return (
        <ConfigurationContainer>
            <Header header="CONFIGURATIONS" />
            <div className="sub-menu-container">
                <div className="sub-menu">
                    <PillTabs
                        changeTab={changeSubMenu}
                        value={selectedSubMenu}
                        theme="LIGHT"
                        pillTabs={[
                            { id: 'GENERAL', label: 'Visão geral' },
                            {
                                id: 'PAYMENT',
                                label: 'Formas de pagamento',
                            },
                            { id: 'MANAGEMENT', label: 'Campos gerenciais' },
                            { id: 'PROVIDERS', label: 'Fornecedores' },
                        ]}
                    />
                </div>
            </div>
            {selectedSubMenu === 'GENERAL' && <GeneralSubPage />}
            {selectedSubMenu === 'PAYMENT' && <PaymentMethodsSubPage />}
            {selectedSubMenu === 'MANAGEMENT' && <ManagementSubPage />}
            {selectedSubMenu === 'PROVIDERS' && <ProvidersSubPage />}
        </ConfigurationContainer>
    )
}
