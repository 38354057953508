/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FaArrowDown } from 'react-icons/fa'
import { v4 } from 'uuid'
import { FullManagementInfo } from '../../entities/full-management-info'
import { useDashboard } from '../../hooks/useDashboard'
import { DropdownFilterContainer, Selector } from './styles'

interface Props {
    management: FullManagementInfo
}

export function DropdownFilter({ management }: Props) {
    const { reportFilter, handleReportFilter } = useDashboard()
    const [selectedDrop, setSelectedDrop] = useState<string | undefined>(
        undefined
    )

    function optionSelected() {
        if (reportFilter?.options) {
            let isPresent = false
            management.options.forEach((opt) => {
                if (!isPresent) {
                    isPresent = reportFilter!.options!.some((o) => o === opt.id)
                }
            })
            return isPresent
        }
        return false
    }

    return (
        <DropdownFilterContainer>
            <label
                htmlFor={management.id}
                className={`selector-label ${
                    optionSelected() ? 'bordered' : ''
                }`}
            >
                <input
                    type="checkbox"
                    name={management.label}
                    checked={selectedDrop === management.id}
                    id={management.id}
                    className="selector-input"
                    onChange={(e) => setSelectedDrop(e.target.id)}
                />
                {optionSelected() ? (
                    <p>{management.label}</p>
                ) : (
                    <p>{management.label}</p>
                )}
                {optionSelected() ? (
                    <AiFillCloseCircle
                        style={{ height: '1.6rem', width: '1.6rem' }}
                        onClick={() => {
                            const allOptions = management.options.map(
                                (o) => o.id
                            )
                            handleReportFilter({
                                ...reportFilter,
                                options: reportFilter!.options!.filter(
                                    (opt) => !allOptions.includes(opt)
                                ),
                            })
                        }}
                    />
                ) : (
                    <FaArrowDown />
                )}
                <div className="selectors">
                    <div className="title-dropdown">
                        <h1>{management.label}</h1>
                        <div className="btns">
                            <button
                                className="select-all"
                                type="button"
                                onClick={() => {
                                    const existentOptions: string[] = []
                                    if (reportFilter?.options) {
                                        reportFilter.options.forEach((opt) => {
                                            existentOptions.push(opt)
                                        })
                                    }
                                    handleReportFilter({
                                        ...reportFilter,
                                        options: [
                                            ...existentOptions,
                                            ...management.options.map(
                                                (option) => option.id
                                            ),
                                        ],
                                    })
                                }}
                            >
                                Selecionar todos
                            </button>
                            <button
                                className="clear-all"
                                type="button"
                                onClick={() => {
                                    handleReportFilter({
                                        ...reportFilter,
                                        options: undefined,
                                    })
                                }}
                            >
                                Limpar
                            </button>
                        </div>
                    </div>
                    {/* <div className="filter-input">
                        <SearchInput
                            name="constCenterSearch"
                            label="Pesquisar"
                            icon={FiSearch}
                            // onChange={(e) => {
                            //     setFilterCostCenter(
                            //         e.target.value.toLowerCase()
                            //     )
                            // }}
                        />
                    </div> */}
                    {management &&
                        management.options.map((option) => {
                            return (
                                <Selector key={v4()}>
                                    <label
                                        htmlFor={`center-${option.id}`}
                                        className="select-label"
                                    >
                                        <input
                                            type="checkbox"
                                            id={`center-${option.id}`}
                                            className="center"
                                            checked={
                                                !!reportFilter?.options?.find(
                                                    (o) => o === option.id
                                                )
                                            }
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    handleReportFilter({
                                                        ...reportFilter,
                                                        options:
                                                            reportFilter?.options
                                                                ? [
                                                                      ...reportFilter.options,
                                                                      option.id,
                                                                  ]
                                                                : [option.id],
                                                    })
                                                } else if (
                                                    reportFilter?.options
                                                ) {
                                                    const temp: string[] =
                                                        reportFilter.options

                                                    const index =
                                                        temp.findIndex(
                                                            (t) =>
                                                                t === option.id
                                                        )
                                                    temp.splice(index, 1)
                                                    handleReportFilter({
                                                        ...reportFilter,
                                                        options: temp,
                                                    })
                                                }
                                            }}
                                        />
                                        <p>{option.label}</p>
                                    </label>
                                </Selector>
                            )
                        })}
                </div>
            </label>
            {selectedDrop && (
                <div
                    className="disabled"
                    onClick={() => {
                        setSelectedDrop(undefined)
                    }}
                />
            )}
        </DropdownFilterContainer>
    )
}
