/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import React, { useRef, useState } from 'react'
import { Button } from '../../../components/Button'
import { User } from '../../../entities/user'

import { Input } from '../../../UnformInputs/Input'

import { CompanyInfoContainer } from './styles'

interface Props {
    member: User
}

export function UserCompanyInfo({ member }: Props) {
    // ref
    const formRef = useRef<FormHandles>(null)
    // state
    const [busy, setBusy] = useState(false)

    return (
        <CompanyInfoContainer>
            <div className="company-info">
                <div className="company-info-header">
                    <div className="company-info-header--title">
                        <h1 className="f18-700-dark">
                            Suas informações corporativas
                        </h1>
                        <p className="f16-500-gray">
                            Somente os administradores do sistema podem alterar
                            estas informações.
                        </p>
                    </div>
                    <Button
                        type="button"
                        buttonType="TEXT"
                        color="PRIMARY"
                        text="Contato do Administrador"
                    />
                </div>
                <div className="company-info-form">
                    <Form ref={formRef} onSubmit={() => {}}>
                        <div className="company-info-form--grid">
                            <Input
                                label="Perfil de viajante"
                                normalLabel
                                name="name"
                            />
                            <Input
                                label="Tipo de Usuário"
                                normalLabel
                                name="name"
                            />
                        </div>
                        <div className="company-info-form--input">
                            <Input
                                label="Campos gerenciais vinculados"
                                normalLabel
                                name="name"
                                disabled
                            />
                        </div>
                        <div className="company-info-form--input">
                            <Input
                                label="Departamentos vinculados"
                                normalLabel
                                name="name"
                                disabled
                            />
                        </div>
                        <div className="company-info-form--input">
                            <Input
                                label="Seus aprovadores de 1° nível"
                                normalLabel
                                name="name"
                                disabled
                            />
                        </div>
                    </Form>
                </div>
            </div>
        </CompanyInfoContainer>
    )
}
