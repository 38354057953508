import styled from 'styled-components'

export const ExportReportContainer = styled.div`
    width: 88rem;
    padding: 2rem;
    .content {
        max-height: 60vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 0.5rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 0.5rem;
            background: #edeff2;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            border-radius: 0.5rem;
            background: #c8cacd;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #c8cacd;
        }
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2rem;
        border-bottom: 1px solid #edeff2;
        .header-buttons {
            display: flex;
            margin-right: 2rem;
        }
    }
    .checkboxes {
        margin-top: 2rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
        .checkbox-label {
            display: flex;
        }
    }
    .export-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 5rem;
        button {
            width: 20rem;
        }
    }
`
