import styled from 'styled-components'

export const InputContainer = styled.div``
export const InputContent = styled.div`
    width: 100%;
    font-size: 1.6rem;
    position: relative;
    height: 5.5rem;
    overflow: hidden;
    border: 1px solid var(--color-light-gray);
    border-radius: 0.8rem;
    .search-icon {
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
        width: 2.5rem;
        height: 2.5rem;
        color: var(--color-secondary-gray);
    }
    & input:focus {
        border-radius: 0.8rem;
        border: 1px solid #000;
    }
    input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        padding-left: 4rem;
    }
`
