import styled from 'styled-components'

export const TripInfoConfigContainer = styled.div`
    width: 100%;
    padding-bottom: 3rem;

    .trip-info-config {
        border-radius: 0.8rem;
        background-color: var(--color-light);
        margin-bottom: 1.5rem;
        &-header {
            width: 100%;
            display: grid;
            grid-template-columns: 3fr 1fr;
            padding: 2rem;
            border-bottom: 1px solid var(--color-background);
            &--title {
                width: 70rem;
            }
        }
        &-form {
            padding: 2.5rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1.5rem;
        }
        &-documents {
            &-row {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 2rem;
                button {
                    width: 5rem;
                }
            }
            &--doc {
                width: 13rem;
            }
            &--divider {
                height: 1px;
                width: 100%;
                background-color: var(--color-background);
            }
            &-create {
                width: 70%;
                padding: 0 2.5rem 2.5rem 2.5rem;
                form {
                    display: grid;
                    grid-template-columns: 1fr 3fr 1fr;
                    grid-gap: 2rem;
                }
            }
        }
        button {
            text-align: start;
        }
    }
`
