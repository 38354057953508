import React from 'react'
import { Button } from '../../../../components/Button'
import { ManagementInfoOption } from '../../../../entities/management-info-option'
import { useCompany } from '../../../../hooks/use-company'
import { SingleConfigurationManagementContainer } from './styles'

export interface Props {
    option: ManagementInfoOption
}

export function SingleConfigurationManagement({ option }: Props) {
    // hooks
    const { openEditOptionHandler } = useCompany()
    return (
        <SingleConfigurationManagementContainer>
            <div className="item">
                <h1 className="item-label">{option.externalId}</h1>
            </div>
            <div className="item">
                <h1 className="item-label">{option.label}</h1>
            </div>
            <div className="item">
                <h1 className="item-label">{`${
                    option.users.length === 1
                        ? `${option.users.length} membro`
                        : `${option.users.length} membros`
                }`}</h1>
            </div>
            <div className="item">
                <h1 className="item-label">-</h1>
            </div>
            <div className="item">
                <div className="btn">
                    <Button
                        buttonType="TEXT"
                        color="PRIMARY"
                        type="button"
                        text="Editar"
                        onClick={() => {
                            openEditOptionHandler(option)
                        }}
                    />
                </div>
            </div>
        </SingleConfigurationManagementContainer>
    )
}
