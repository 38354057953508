import styled from 'styled-components'

export const FinancialContainer = styled.div`
    .sub-menu-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .sub-menu {
            display: inline-block;
            margin: 4.8rem auto 5.8rem auto;
        }
    }
    .financial-content {
        max-width: 110rem;
        margin: 0 auto;
    }
`
