/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { RiArrowDownSLine } from 'react-icons/ri'
import { AiFillProfile } from 'react-icons/ai'
import { HeaderContainer } from './styles'
import logo from '../../assets/adalogo.png'
import { SubTabs } from '../SubTabs'
// import { useUsers } from '../../hooks/use-users'
// import { useCompanies } from '../../hooks/use-companies'
import { Avatar } from '../Avatar'
import { useAuth } from '../../hooks/use-auth'
import { useUser } from '../../hooks/use-user'
import { useCompany } from '../../hooks/use-company'

interface Props {
    header:
        | 'DASHBOARD'
        // | 'ANALYTICS'
        | 'REPORTS'
        // | 'SECURITY'
        | 'POLITICS'
        | 'USERS'
        | 'CONFIGURATIONS'
        | 'EXPENSES'
}

export function Header({ header }: Props) {
    // hooks
    const { user } = useUser()
    const { logout, token } = useAuth()
    const { company } = useCompany()
    const [openHeaderMenu, setOpenHeaderMenu] = useState(false)

    const url = process.env.REACT_APP_TMS_URL

    return (
        <HeaderContainer>
            <div className="header-logo">
                <img src={logo} alt="Ada Travel" />
            </div>
            <div className="header-navigation">
                <SubTabs
                    direction="HORIZONTAL"
                    value={header}
                    tabs={[
                        {
                            label: 'Dashboard',
                            id: 'DASHBOARD',
                            path: '/home',
                        },
                        // {
                        //     label: 'Análises',
                        //     id: 'ANALYTICS',
                        //     path: '/analytics',
                        // },
                        {
                            label: 'Relatórios',
                            id: 'REPORTS',
                            path: '/reports',
                        },
                        // {
                        //     label: 'Segurança',
                        //     id: 'SECURITY',
                        //     path: '/security',
                        // },
                        {
                            label: 'Políticas',
                            id: 'POLITICS',
                            path: '/politics',
                        },
                        {
                            label: 'Usuários',
                            id: 'USERS',
                            path: '/users',
                        },
                        {
                            label: 'Configurações',
                            id: 'CONFIGURATIONS',
                            path: '/configurations',
                        },
                        {
                            label: 'Despesas',
                            id: 'EXPENSES',
                            path: '/expenses',
                        },
                    ]}
                />
            </div>
            <div className="header-menu">
                <div className="menu-user">
                    <div className="user-avatar">
                        <Avatar
                            label={`${user?.firstName} ${user?.lastName}`}
                        />
                    </div>
                    <div className="user-info">
                        <h1 className="user-name-label">{`${user?.firstName} ${user?.lastName}`}</h1>
                        <h1 className="user-company-label">{company?.name}</h1>
                    </div>
                    <button
                        className="dropdown-btn"
                        type="button"
                        onClick={() => setOpenHeaderMenu(!openHeaderMenu)}
                    >
                        <RiArrowDownSLine
                            style={
                                openHeaderMenu
                                    ? { transform: 'rotate(180deg)' }
                                    : {}
                            }
                        />
                    </button>
                </div>
            </div>
            {openHeaderMenu && (
                <>
                    <div className="menu-user-nav">
                        {/* <Link to="/profile">
                            <button
                                className="menu-user-nav--btn"
                                type="button"
                            >
                                <AiFillProfile />
                                <p>Minhas informações</p>
                            </button>
                        </Link> */}
                        <button
                            className="menu-user-nav--btn"
                            type="button"
                            onClick={() =>
                                window.location.replace(
                                    `${url}?localData=${encodeURIComponent(
                                        token || ''
                                    )}`
                                )
                            }
                        >
                            <AiFillProfile />
                            <p>Portal do viajante</p>
                        </button>
                        <button
                            className="menu-user-nav--btn"
                            type="button"
                            onClick={() => logout()}
                        >
                            <AiFillProfile />
                            <p>Sair</p>
                        </button>
                    </div>
                    <div
                        className="menu-user-nav-opaciy"
                        onClick={() => setOpenHeaderMenu(false)}
                    />
                </>
            )}
        </HeaderContainer>
    )
}
