import React, { ReactNode } from 'react'
import { Header } from '../Header'
import { MainContainerStyle } from './styles'

interface Props {
    children: ReactNode
    busy?: boolean
}

export function MainUserContainer({ children, busy }: Props) {
    return (
        <MainContainerStyle>
            <Header header="USERS" />
            {busy ? <div /> : <div className="main">{children}</div>}
        </MainContainerStyle>
    )
}
