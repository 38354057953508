import styled from 'styled-components'

export const ConfigurationContainer = styled.div`
    .sub-menu-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .sub-menu {
            display: inline-block;
            margin: 4.8rem auto 3rem auto;
        }
    }
`
