import styled from 'styled-components'

export const ExportReportContainer = styled.div`
    width: 88rem;
    padding: 2rem;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid #edeff2;
        .header-buttons {
            display: flex;
            margin-right: 2rem;
        }
    }
    .content-import {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;
        button {
            width: 20rem;
            border-top-left-radius: none;
            border-bottom-left-radius: none;
        }
        .import-btn {
            width: 40rem;
            height: 4.7rem;
            label {
                .container {
                    width: 40rem;
                    height: 4.7rem;
                    border-radius: 6px;
                    border: 1px dashed #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    p {
                        color: #555;
                        font-size: 1.6rem;
                    }
                }
                input {
                    display: none;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .export-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 5rem;
        button {
            width: 20rem;
        }
    }
`
